<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-9">
                    <div class="card overflow-hidden radius-10">
                        <div class="profile-cover bg-dark position-relative mb-4">
                            <div class="user-profile-avatar shadow position-absolute top-50 start-0 translate-middle-x">
                                <img src="https://png.pngtree.com/element_our/png_detail/20181206/users-vector-icon-png_260862.jpg" alt="..."/>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="mt-5 d-flex flex-column flex-sm-row align-items-start justify-content-between gap-3 gap-sm-0">
                                <div>
                                    <h3 class="mb-2">
                                        {{ userDetails.fullName }}
                                    </h3>
                                    <p class="mb-1">
                                        <strong>Email:</strong>
                                        {{ email }}
                                    </p>
                                </div>
                                <div>
                                    <a href="javascript:void(0);" class="btn btn_primary d-block mb-2" @click="isEdit = !isEdit">
                                        <i class="bx bx-edit"></i>Edit Profile
                                    </a>
                                    <a href="javascript:void(0);" class="btn btn_primary" data-bs-toggle="modal" data-bs-target="#change_password">
                                        <i class="bx bxs-key"></i>Change Password
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isEdit" class="card radius-10">
                        <div class="card-body">
                            <form>
                                <h5 class="mb-3">Edit Profile</h5>
                                <h5 class="mb-0 mt-4">User Information</h5>
                                <hr/>
                                <div class="row g-3">
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Name:</label>
                                        <input type="text"  v-model="userDetails.fullName" class="form-control" placeholder="N/A"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Email:</label>
                                        <input type="email"  v-model="userDetails.email" class="form-control" disabled placeholder="N/A"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Phone:</label>
                                        <input type="text" v-model="userDetails.phone" class="form-control" placeholder="N/A"/>
                                    </div>
                                    <!-- <div class="col-12 col-sm-6">
                                        <label class="form-label">Address:</label>
                                        <input type="text" v-model="userDetails.address" class="form-control" placeholder="N/A"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">City:</label>
                                        <input type="text" v-model="userDetails.city" class="form-control" placeholder="N/A"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Postal Code:</label>
                                        <input type="text" v-model="userDetails.postCode" class="form-control" placeholder="N/A"/>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="form-label">Country:</label>
                                        <select v-model="country" class="form-select mb-3">
                                            <option style="display: none" selected value="">
                                                {{ userDetails.country ? userDetails.country : "Select Country" }}
                                            </option>
                                            <option v-for="(data, index) in countryList" :key="index" :value="data.name">
                                                {{ data.name }}
                                            </option>
                                        </select>
                                    </div> -->
                                </div>
                                <div class="text-start mt-3">
                                    <button @click="save" type="button" class="btn btn_primary px-4">
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ChangePassword/>
    </div>
</template>

<script src="../js/profile.js"></script>

<style scoped>
    @import "../css/profile.css";
</style>