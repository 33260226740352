import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "ReserveList",
    components: {
        Loading
    },
    data() {
        return {
            dataList: [],
            customerList: [],
            uniqueName: [],
            uniqueRoute: [],
            serviceRequesIdtList: [],
            routeList: [],
            data: {
                paymentStatus: "PENDING"
            },

            filter: {
                serviceRequestId: "",
                customerName: "",
                paymentStatus: "",
                requestStatus: "",
                routeName: "",
                serviceRequestDate: "",
                startDate: "",
                endDate: "",

            },
            sheets: [],
            updatePayment: "",
            bulkServiceList: [],
            selectedBulkService: [],
            bulkPaymentStatus: "",
            bulkPaymentStatusOncheck: "",
            confirmed: [],
            selectAll: "",
            bulkrequest: [],
            bulkAssignChecklist: [],
        }
    },
    async mounted() {
        document.title = "Land Scape - Reservation List";
        await this.getReserveList();
        this.loadJS();
    },
    methods: {
        loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        getReserveList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.reservation.reservationURL + "?paymentStatus=" + this.filter.paymentStatus + "&requestStatus=" + this.filter.requestStatus + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate + "&customerName=" + this.filter.customerName + "&serviceRequestId=" + this.filter.serviceRequestId + "&routeName=" + this.filter.routeName + "&serviceRequestDate=" + this.filter.serviceRequestDate,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    this.bulkServiceList = response.data.data.map((el) => {
                        return { value: el.id, label: el.reserveId }
                    });
                    this.uniqueName = this.dataList.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.userName === value.userName
                        ))
                    );
                    this.uniqueRoute = this.dataList.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.routeName === value.routeName
                        ))
                    )
                    if (this.customerList.length == 0) {
                        this.customerList = this.uniqueName.map(el => {
                            return { label: el.userName, value: el.userName }
                        })
                    }
                    if (this.routeList.length == 0) {
                        this.routeList = this.uniqueRoute.map(el => {
                            return { label: el.routeName, value: el.routeName }
                        })
                    }
                    if (this.serviceRequesIdtList.length == 0) {
                        this.serviceRequesIdtList = this.dataList.map(el => {
                            return { label: el.reserveId, value: el.reserveId }
                        })
                    }
                }
            }).catch(error => {
                console.log(error)
            });
        },
        deleteReservation: async function (id) {
            this.isLoading = true;
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.reservation.reservationURL + "/" + id,
                        headers: authHeader()
                    }
                    await this.$axios(config).then((response) => {
                        if (response.data.deleted == true) {
                            this.getReserveList();
                            this.$swal.fire({
                                icon: "success",
                                text: response.data.message
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                }
            })
        },
        validateFilter: async function () {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select end date!"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select start date!"
                        });
                        return;
                    }
                }
                if (this.filter.customerName == null) {
                    this.filter.customerName = "";
                }
                if (this.filter.routeName == null) {
                    this.filter.routeName = "";
                }
                if (this.filter.serviceRequestId == null) {
                    this.filter.serviceRequestId = "";
                }
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getReserveList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                serviceRequestId: "",
                customerName: "",
                paymentStatus: "",
                requestStatus: "",
                routeName: "",
                serviceRequestDate: "",
                startDate: "",
                endDate: "",

            },
                await this.getReserveList();
        },
        // updateStatus: async function (param) {
        //     try {
        //         let token = localStorage.getItem('token');
        //         let data = {
        //             status: param.status
        //         }
        //         let config = {
        //             method: "PATCH",
        //             url: this.$gatewayURL + this.$api.reserve.requestURL + '/' + param.id,
        //             data: data,
        //             headers: {
        //                 "Authorization": "Bearer " + token
        //             }
        //         };
        //         await this.$axios(config).then((response) => {
        //             if (response.data.statusCode == 200) {
        //                 this.getReserveList();
        //                 this.$swal.fire({
        //                     toast: true,
        //                     position: 'top-end',
        //                     text: "Status updated successfully.",
        //                     icon: "success",
        //                     showConfirmButton: false,
        //                     timer: 3000,
        //                     animation: false,
        //                     timerProgressBar: true,
        //                     didOpen: (toast) => {
        //                         toast.addEventListener('mouseenter', this.$swal.stopTimer)
        //                         toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        //                     }
        //                 });
        //             }
        //         }).catch(error => {
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: error.response.data.message
        //             });
        //         });
        //     } catch (error) {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong, please try agian later."
        //         });
        //     }
        // },
        updatePaymentStatus: async function (param, status) {
            try {
                let data = {
                    paymentStatus: status
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.reservation.updatePaymentStatusURL + '/' + param.id,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.getReserveList();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Payment status updated successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    'Id': el.reserveId,
                    'Customer Name': el.userName,
                    'Request Date': el.serviceRequestDate,
                    "Payment Status": el.paymentStatus,
                    "Request Status": el.reservationStatus,
                    "Total Amount": el.totalAmount,

                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'service_request_report(' + date + ').xlsx')
        },
        bulkUpdate: async function () {
            try {
                if (this.selectedBulkService.length > 0 || this.bulkrequest.length > 0) {
                    let data = {}
                    if (this.selectedBulkService.length > 0) {
                        data = {
                            ids: this.selectedBulkService,
                            status: this.bulkPaymentStatus
                        }
                    } else if (this.bulkrequest.length > 0) {
                        let requests = [...new Set(this.bulkrequest)];
                        let confirmedRequest = requests.filter(item => !(item == 0));
                        data = {
                            ids: confirmedRequest,
                            status: this.bulkPaymentStatusOncheck,
                        }
                    }
                    let config = {
                        method: "PATCH",
                        url: this.$serverURL + this.$api.reservation.bulkPaymentUpdateURL,
                        data: data,
                        headers: authHeader()
                    };
                    await this.$axios(config).then((response) => {
                        if (response.data.statusCode == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                            this.selectedBulkService = [];
                            this.bulkrequest = [];
                            this.bulkAssignChecklist = [];
                            this.selectAll = ""
                            this.bulkPaymentStatus = ""
                            this.bulkPaymentStatusOncheck="";
                            this.getReserveList();
                            document.getElementById("bulkBtn").click();
                        }
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } else {
                    this.$swal.fire({
                        icon: 'error',
                        text: "First select a request."
                    })
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        bulkHandle: function (index, status, data) {
            if (status == true) {
                this.bulkrequest[index] = data
            } else if (status == false) {
                this.bulkrequest[index] = 0
            }
        },
        handleSelectAll: function () {
            if (this.selectAll == true) {
                this.dataList.map((el, index) => {
                    this.bulkAssignChecklist[index] = true;
                    this.bulkrequest[index] = el.id;
                })
            } else if (this.selectAll == false) {
                this.dataList.map((el, index) => {
                    this.bulkAssignChecklist[index] = false;
                    this.bulkrequest = [];
                })
            }
        },
        dateTimeFormat(dateTime) {
            dateTime = new Date(dateTime);
            const date = dateTime.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
            const time = dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            return `${time}, ${date}`;
        }
    }
}
