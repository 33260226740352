<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Service Checklist
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end breadcrumb-->
      <div class="card">
        <div
          class="card-header"
          style="padding: 15px; font-size: 20px; background-color: #f4f4f4"
        >
          Check List
        </div>
        <div class="alert alert-warning" role="alert" style="font-size: 16px">
          <i class="fa-solid fa-triangle-exclamation"></i>&ensp;Please complete
          all the tasks in the checklist to proceed
        </div>
        <div class="card-body">
          <div
            class="card-inbox"
            v-for="(data, index) in dataList"
            :key="index"
          >
            <h5>
              <b>{{ data.serviceName }}:</b>
            </h5>
            <div              
              class="item"
              v-for="(row, item) in JSON.parse(data.data)"
              :key="item"
            >
              <input
                type="checkbox"
                v-model="checkList[index][item]"
                @change="verifyCheckList"
              />
              <p>{{ row.name }}</p>
            </div>
            <hr />
          </div>
        </div>
        <div class="card-footer" style="text-align: right">
          <button
            type="button"
            :class="buttonFlag ? 'btn btn-success' : 'btn btn-danger'"
            @click="updateService"
            :disabled="!buttonFlag"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/service-checklist.js"></script>


<style scoped>
@import "../css/service-checklist.css";
</style>