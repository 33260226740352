<template>
    <div>
        <div class="modal fade" id="change_password" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content radius-15 shadow-primary border-0">
                    <div class="modal-header modal-header-primary">
                        <h4 class="m-0 text-white">Change Password</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row g-3">
                            <div class="col-12">
                                <label class="form-label">Old Password:</label>
                                <input type="password" v-model="oldPassword" class="form-control" placeholder="Input your old password" required/>
                            </div>
                            <div class="col-12">
                                <label class="form-label">New Password:</label>
                                <input type="password" v-model="newPassword" class="form-control" placeholder="Input your new password" required/>
                            </div>
                            <div class="col-12">
                                <label class="form-label">Retype New Password:</label>
                                <input type="password" v-model="confirmPassword" class="form-control" placeholder="Retype new password" required/>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="updatePassword()" class="btn btn_primary pull-left" data-bs-dismiss="modal">
                            <i class="bx bx-save"></i> Save Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/change-password.js"></script>