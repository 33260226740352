import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "Admin",
    data() {
        return {
            name: '',
            details: "",
            service: "",
            dataList: [],
            serviceList: [],
            singleCheckList: {},
            updateService: "",
            sheets: []
        }
    },
    async created() {
        document.title = "Land Scape - Checklist";
        await this.getDataList();
        await this.getServiceList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getDataList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.checklist.checklistAPI,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.dataList = await response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getServiceList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.serviceList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        addChecklist: async function () {
            this.isLoading = true;
            console.log("Name: ", this.name);
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter checklist name!"
                });
            } else if (!this.service) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select service!"
                });
            } else if (!this.details) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter checklist details!"
                });
            } else {
                let data = {
                    serviceId: this.service.id,
                    serviceName: this.service.name,
                    name: this.name,
                    details: this.details
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.checklist.checklistAPI,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                    }
                    await this.getDataList();
                    this.service = '';
                    this.name = '';
                    this.endPoint = '';
                    this.details = '';
                    document.getElementById("closeButton").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        editCheckList: function (data) {
            this.singleCheckList = data;
            this.updateService = {
                id: this.singleCheckList.serviceId,
                name: this.singleCheckList.serviceName
            }
        },
        updateChecklist: async function () {
            this.isLoading = true;
            if (!this.singleCheckList.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter checklist name!"
                });
            }
            else if (!this.singleCheckList.details) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter checklist details!"
                });
            }
            else if (!this.updateService) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select service!"
                });
            }
            else {
                let data = {
                    serviceId: this.updateService ? this.updateService.id : this.singleChecklist.serviceId,
                    serviceName: this.updateService ? this.updateService.name : this.singleChecklist.serviceName,
                    name: this.singleCheckList.name,
                    details: this.singleCheckList.details
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.checklist.singleChecklist + this.singleCheckList.id,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                    }
                    await this.getDataList();
                    this.editService = "";
                    this.singleCheckList = "";
                    document.getElementById("editcloseButton").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        deleteCheckList: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.checklist.singleChecklist + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList();

                    } catch (error) {
                        console.log(error);
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later."
                        });
                    }
                }
            })
        },

        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Checklist Name": el.name,
                    "Service Name": el.serviceName,
                    "Details": el.details,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'checklist_report(' + date + ').xlsx');
            this.sheets = [];
        }

    }
}