import { authHeader } from "../../../auth";

export default {
    name: "AddService",
    data() {
        return {
            serviceName: "",
            servicePrice: "",
            //serviceStatus: "",
            serviceDetails: "",
            featuredImage: "",
            previewFeaturedImage: "",
            addFeaturesList: [{featureName: ""}],
            featureList: []
        }
    },
    async created() {
        document.title = "Land Scape - Add Service";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        addFeatures: function () {
            this.addFeaturesList.push({featureName: ""})
        },
        validate: function() {
            if(!this.serviceName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service name!"
                });
                return false;
            }
            if(!this.servicePrice) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service price!"
                });
                return false;
            }
            if(!this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload featured image!"
                });
                return false;
            }
            return true;
        },
        addService: async function () {
            if(this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("serviceName", this.serviceName);
                formData.append("servicePrice", this.servicePrice);
                formData.append("serviceDetails", this.serviceDetails);
                formData.append("serviceImage", this.featuredImage);
                formData.append("serviceFeature", this.featureList);
                //formData.append("status", this.serviceStatus);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.service.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Data created successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/service");
                    }
                    this.$refs.featuredImage.value = null;
                    this.serviceName = null;
                    this.servicePrice = null;
                    this.serviceDetails = null;
                    this.featuredImage = null;
                    this.previewFeaturedImage = null;
                    //this.serviceStatus = null;
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        removeFeature: async function(index) {
            this.addFeaturesList.splice(index, 1);
            this.featureList.splice(index, 1);
        }
    }
}