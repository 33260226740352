import { authHeader } from "../../../auth";

export default {
    name: "AddRoute",
    data() {
        return {
            routeName: '',
            startPoint: '',
            endPoint: '',
            startLatitude: '',
            endLatitude: '',
            startLongitude: '',
            endLongitude: ''
        }
    },
    async created() {
        document.title = "Land Scape - Add Route";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        setStartPoint(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.startPoint = location;
            this.startLatitude = locationData.geometry.location.lat();
            this.startLongitude = locationData.geometry.location.lng();
        },
        setEndPoint(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.endPoint = location;
            this.endLatitude = locationData.geometry.location.lat();
            this.endLongitude = locationData.geometry.location.lng();
        },
        addRoute: async function () {
            if(!this.routeName){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter route name!"
                });
            } else if(!this.startPoint){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter start point!"
                });
            } else if(!this.endPoint) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter end point!"
                });
            }
            else {
                let data = {
                    routeName: this.routeName,
                    startPoint: this.startPoint,
                    endPoint: this.endPoint,
                    startLatitude: this.startLatitude,
                    startLongitude: this.startLongitude,
                    endLatitude: this.endLatitude,
                    endLongitude: this.endLongitude
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.route.routeAPI,
                    data: data,
                    headers:  authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully stored data!"
                        });
                    }
                    this.routeName = '';
                    this.startPoint = '';
                    this.endPoint = '';
                    this.startLatitude = '';
                    this.startLongitude = '';
                    this.endLatitude = '';
                    this.endLongitude = '';
                    this.$router.push("/route");
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}