import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import adminGuard from "../admin-guard";
import supervisorGuard from "../supervisor-guard";
import adminOrSupervisorGuard from "../admin-supervisor-guard";
import crewGuard from "../crew-guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import Admin from "../components/user/template/Admin";
import Service from "../components/service/template/Service";
import AddService from "../components/service/template/AddService";
import UpdateService from "../components/service/template/UpdateService";
import Crew from "../components/user/template/Crew";
import Supervisor from "../components/user/template/Supervisor";
import Customer from "../components/user/template/Customer";
import AddCustomer from "../components/user/template/AddCustomer";
import UpdateCustomer from "../components/user/template/UpdateCustomer";
import BillingInformation from "../components/user/template/BillingInformation";
import AddBilling from "../components/user/template/AddBilling";
import UpdateBilling from "../components/user/template/UpdateBilling";
import Route from "../components/route/template/Route";
//import Shift from "../components/shift/template/Shift";
//import AssignShift from "../components/shift/template/AssignShift";
import DutiesAndResponsibilities from "../components/shift/template/DutiesAndResponsibilities";
import CheckList from "../components/checklist/template/CheckList";
import CrewChecklist from "../components/checklist/template/CrewChecklist";
import SupervisorChecklist from "../components/checklist/template/SupervisorChecklist";
import Payroll from "../components/payroll/template/Payroll";
import ReservationList from "../components/reservation/template/ReservationList";
import CreateReserve from "../components/reservation/template/CreateReserve";
import ReserveDetails from "../components/reservation/template/ReserveDetails";
import AssignService from "../components/reservation/template/AssignService";
import AssignedRequest from "../components/reservation/template/AssignedRequest";
import TermsAndConditions from "../components/policy/terms-and-conditions/template/TermsAndConditions";
import Profile from "../components/profile/template/Profile";
import CrewList from "../components/crewlist/template/CrewList";
import CrewHome from "../components/crew-home/template/CrewHome";
import SupervisorHome from "../components/supervisor-home/template/SupervisorHome";
import Expense from "../components/expense/template/Expense";
import AssignedService from "../components/AssignedService/template/AssignedService";
import ServiceChecklist from "../components/AssignedService/template/ServiceChecklist";
import RequestDetails from "../components/AssignedService/template/RequestDetails";
import ExpenseCategory from "../components/expense-category/template/ExpenseCategory";
import Error404 from "../components/global/error_404/template/Error404";
import Unauthorized from "../components/global/unauthorized/template/Unauthorized";
import ImportService from "../components/service/template/ImportService";
import CustomerBulkUpload from "../components/user/template/CustomerBulkUpload";
import CrewBulkUpload from "../components/user/template/CrewBulkUpload";
import AssignSupervisor from "../components/AssignSupervisor/template/AssignSupervisor";
import SupervisorBulkUpload from "../components/user/template/SupervisorBulkUpload";
import CrewReport from "../components/Report/template/CrewReport";
import TimeClock from "../components/Report/template/TimeClock";
import SupervisorReport from "../components/Report/template/SupervisorReport";
import SupervisorLogReport from "../components/Report/template/SupervisorLogReport";
import TodayExpense from "../components/Report/template/TodayExpense";
import ExpenseReport from "../components/Report/template/ExpenseReport";
import RouteReport from "../components/Report/template/RouteReport";
import TodayServiceRequest from "../components/Report/template/TodayServiceRequest";
import Attendance from "../components/Report/template/Attendance";
import CrewAttendance from "../components/Report/template/CrewAttendance";
import AssignedRequestReport from "../components/Report/template/AssignedRequestReport";
import AddRoute from "../components/route/template/AddRoute";
import UpdateRoute from "../components/route/template/UpdateRoute";
import Message from "../components/message/template/Message";
import EmployeeSchedule from "../components/employee-schedule/template/EmployeeSchedule";
import ScheduleList from "../components/employee-schedule/template/ScheduleList.vue";
import AddEmployeeSchedule from "../components/employee-schedule/template/AddEmployeeSchedule";
import UpdateEmployeeSchedule from "../components/employee-schedule/template/UpdateEmployeeSchedule";
import Calendar from "../components/calendar/template/Calendar";
import PaymentList from "../components/payment/template/PaymentList";
import UpdatePayment from "../components/payment/template/UpdatePayment";
import AddPayment from "../components/payment/template/AddPayment";

const routes = [
    /* Auth Routes */
    { path: "/login", name: "Login", component: Login },
    { path: "/profile", name: "Profile", component: Profile, beforeEnter: guard },


    /* Dashboard Routes */
    { path: "/", name: "Dashboard", component: Dashboard, beforeEnter: guard },
    { path: "/dashboard", name: "Dashboards", component: Dashboard, beforeEnter: guard },
    
    //Reporting
    { path: "/crew-report", name: "CrewReport", component: CrewReport, beforeEnter: adminGuard },
    { path: "/time-clock-report", name: "TimeClock", component: TimeClock, beforeEnter: adminGuard },
    { path: "/supervisor-report", name: "SupervisorReport", component: SupervisorReport, beforeEnter: adminGuard },
    { path: "/supervisor-log-report", name: "SupervisorLogReport", component: SupervisorLogReport, beforeEnter: adminGuard },
    { path: "/today-expense", name: "TodayExpense", component: TodayExpense, beforeEnter: adminGuard },
    { path: "/expense-report", name: "ExpenseReport", component: ExpenseReport, beforeEnter: adminGuard },
    { path: "/route-report", name: "RouteReport", component: RouteReport, beforeEnter: adminGuard },
    { path: "/today-service-request", name: "TodayServiceRequest", component: TodayServiceRequest, beforeEnter: adminGuard },
    { path: "/attendance-report", name: "Attendance", component: Attendance, beforeEnter: guard },
    { path: "/crew-attendance-report", name: "CrewAttendance", component: CrewAttendance, beforeEnter: guard },
    { path: "/assigned-request-report", name: "AssignedRequestReport", component: AssignedRequestReport, beforeEnter: guard },
    // admin

    { path: "/service", name: "Service", component: Service, beforeEnter: adminGuard },
    { path: "/add-service", name: "AddService", component: AddService, beforeEnter: adminGuard },
    { path: "/update-service/:id", name: "UpdateService", component: UpdateService, beforeEnter: adminGuard },
    { path: "/import-service", name: "ImportService", component: ImportService, beforeEnter: adminGuard },
    { path: "/import-customer", name: "CustomerBulkUpload", component: CustomerBulkUpload, beforeEnter: adminGuard },
    { path: "/import-crew", name: "CrewBulkUpload", component: CrewBulkUpload, beforeEnter: adminGuard },
    { path: "/import-supervisor", name: "SupervisorBulkUpload", component: SupervisorBulkUpload, beforeEnter: adminGuard },
    { path: "/assign-supervisor", name: "AssignSupervisor", component: AssignSupervisor, beforeEnter: adminGuard },
    { path: "/billing-information", name: "BillingInformation", component: BillingInformation, beforeEnter: adminGuard },
    { path: "/add-billing-information", name: "AddBilling", component: AddBilling, beforeEnter: adminGuard },
    { path: "/update-billing-information/:id", name: "BillingInformaUpdateBillingtion", component: UpdateBilling, beforeEnter: adminGuard },
    { path: "/employee-schedule", name: "EmployeeSchedule", component: EmployeeSchedule, beforeEnter: adminOrSupervisorGuard },
    { path: "/add-employee-schedule", name: "AddEmployeeSchedule", component: AddEmployeeSchedule, beforeEnter: adminGuard },
    { path: "/update-employee-schedule/:id", name: "UpdateEmployeeSchedule", component: UpdateEmployeeSchedule, beforeEnter: adminOrSupervisorGuard },
    { path: "/calendar", name: "Calendar", component: Calendar, beforeEnter: adminGuard },
    { path: "/payment-list", name: "PaymentList", component: PaymentList, beforeEnter: adminGuard },
    { path: "/update-payment/:id", name: "UpdatePayment", component: UpdatePayment, beforeEnter: adminGuard },
    { path: "/add-payment", name: "AddPayment", component: AddPayment, beforeEnter: adminGuard },


    { path: "/route", name: "Route", component: Route, beforeEnter: adminGuard },
    { path: "/add-route", name: "AddRoute", component: AddRoute, beforeEnter: adminGuard },
    { path: "/update-route/:id", name: "UpdateRoute", component: UpdateRoute, beforeEnter: adminGuard },
    //{ path: "/shift", name: "Shift", component: Shift, beforeEnter: guard },
    //{ path: "/assign-shift", name: "AssignShift", component: AssignShift, beforeEnter: guard },
    { path: "/duties-responsibilities", name: "DutiesAndResponsibilities", component: DutiesAndResponsibilities, beforeEnter: guard },
    { path: "/check-list", name: "CheckList", component: CheckList, beforeEnter: adminGuard },
    { path: "/crew-check-list", name: "CrewChecklist", component: CrewChecklist, beforeEnter: adminGuard },
    { path: "/supervisor-check-list", name: "SupervisorChecklist", component: SupervisorChecklist, beforeEnter: adminGuard },
    { path: "/payroll", name: "Payroll", component: Payroll, beforeEnter: adminGuard },
    { path: "/reservation-list", name: "ReservationList", component: ReservationList, beforeEnter: adminGuard },
    { path: "/service-request", name: "CreateReserve", component: CreateReserve, beforeEnter: adminGuard },
    { path: "/reserve-details/:id", name: "ReserveDetails", component: ReserveDetails, beforeEnter: adminGuard },
    { path: "/assign-service", name: "AssignService", component: AssignService, beforeEnter: adminGuard },
    { path: "/assigned-request", name: "AssignedRequest", component: AssignedRequest, beforeEnter: adminGuard },

    { path: "/assigned-service-checklist/:id", name: "ServiceChecklist", component: ServiceChecklist, beforeEnter: supervisorGuard },
    { path: "/request-details/:id", name: "RequestDetails", component: RequestDetails, beforeEnter: supervisorGuard },
    // expense
    { path: "/expense", name: "Expense", component: Expense, beforeEnter: adminGuard },
    { path: "/expense-category", name: "ExpenseCategory", component: ExpenseCategory, beforeEnter: adminGuard },
    /* Policy Routes */
    { path: "/terms-and-conditions", name: "TermsAndConditions", component: TermsAndConditions, beforeEnter: adminGuard },
    /* Users Routes */
    { path: "/admin-list", name: "Admin", component: Admin, beforeEnter: adminGuard },
    { path: "/supervisor-list", name: "Supervisor", component: Supervisor, beforeEnter: adminGuard },
    { path: "/crew-list", name: "Crew", component: Crew, beforeEnter: adminGuard },
    { path: "/customer-list", name: "Customer", component: Customer, beforeEnter: adminGuard },
    { path: "/add-customer", name: "AddCustomer", component: AddCustomer, beforeEnter: adminGuard },
    { path: "/update-customer/:id", name: "UpdateCustomer", component: UpdateCustomer, beforeEnter: adminGuard },
    { path: "/message", name: "Message", component: Message, beforeEnter: guard },

    //supervisor
    { path: "/supervisor-home", name: "SupervisorHome", component: SupervisorHome, beforeEnter: supervisorGuard },
    { path: "/crews", name: "CrewList", component: CrewList, beforeEnter: supervisorGuard },
    { path: "/assigned-service", name: "AssignedService", component: AssignedService, beforeEnter: supervisorGuard },

    //crew
    { path: "/crew-home", name: "CrewHome", component: CrewHome, beforeEnter: crewGuard },
    { path: "/schedule-list", name: "ScheduleList", component: ScheduleList, beforeEnter: crewGuard },

    /* Error and Unauthorized routes */
    { path: "/404-not-found", name: "404NotFound", component: Error404, beforeEnter: guard },
    { path: "/unauthorized", name: "Unauthorized", component: Unauthorized, beforeEnter: guard },
    { path: '/:pathMatch(.*)*', redirect: { name: '404NotFound' }, beforeEnter: guard },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
