<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Add Customer
                        </li>
                    </ol>
                </nav>
            </div>
            <hr />
            <form @submit.prevent="addCustomer()">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Add New Customer</h5>
                        <hr />
                        <div class="form-body mt-4">
                            <div class="row">
                                <div class="col-8">
                                    <div class="border border-3 p-4 rounded mb-3">
                                        <div class="row">
                                            <div class="col-2">
                                                <label class="form-label">Title:
                                                    <span style="color: red; font-size: 18px">*</span>
                                                </label>
                                                <input class="form-control mb-1" type="text" v-model="title" placeholder="Enter title"/>
                                            </div>
                                            <div class="col">
                                                <label class="form-label">Name:
                                                    <span style="color: red; font-size: 18px">*</span>
                                                </label>
                                                <input class="form-control mb-1" type="text" v-model="fullName" placeholder="Enter name"/>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <label class="form-label">Phone:
                                                    <span style="color: red; font-size: 18px">*</span>
                                                </label>
                                                <input class="form-control mb-1" type="tel" v-model="phone" placeholder="Enter phone"/>
                                            </div>
                                            <div class="col">
                                                <label class="form-label">Email:
                                                    <span style="color: red; font-size: 18px">*</span>
                                                </label>
                                                <input class="form-control mb-1" type="email" v-model="email" placeholder="Enter email"/>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label class="form-label">Address:
                                                <span style="color: red; font-size: 18px">*</span>
                                            </label>
                                            <GMapAutocomplete
                                                @place_changed="setAddress"
                                                class="form-control mb-1"
                                                @input="address = $event.target.value"
                                            ></GMapAutocomplete>
                                        </div>
                                        <div class="col">
                                            <label class="form-label">Second Address:</label>
                                            <GMapAutocomplete
                                                @place_changed="setSecondAddress"
                                                class="form-control mb-1"
                                                @input="secondAddress = $event.target.value"
                                            ></GMapAutocomplete>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <label class="form-label">Password:
                                                    <span style="color: red; font-size: 18px">*</span>
                                                </label>
                                                <div class="input-group" id="show_hide_password">
                                                    <input :type="inputTypePassword" v-model="password" class="form-control border-end-0" />
                                                    <a href="javascript:void(0);" @click="changePasswordType()" class="input-group-text bg-transparent">
                                                        <i :class="iconStatus ? 'bx bx-hide' : 'bx bx-show'"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <label class="form-label">Confirm Password:
                                                    <span style="color: red; font-size: 18px">*</span>
                                                </label>
                                                <div class="input-group" id="show_hide_password">
                                                    <input :type="inputTypeConfirmPassword" v-model="confirmPassword" class="form-control border-end-0" />
                                                    <a href="javascript:void(0);" @click="changeConfirmPasswordType()" class="input-group-text bg-transparent">
                                                        <i :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button type="submit" class="btn btn-primary ms-2">
                                            Add Customer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script src='../js/add-customer.js'></script>
