import { authHeader } from "../../../auth";
export default{
    data(){
        return {
            count: 5,
            checkList: [],
            dataList: [],
            buttonFlag: false,
            endButton: false,
            id: this.$route.params.id,
            shiftId: '',
            totalData: 0,
            count: 0,
            check:[]
        }
    },
    async mounted() {
        await this.getCheckList();
        this.checkList = Array.from(Array(this.dataList.length));

        for (let i = 0; i < this.dataList.length; i++){
            this.checkList[i] = [];
            let data = JSON.parse(this.dataList[i].data);
            for (let j = 0; j < data.length; j++){
                this.checkList[i].push(false);
            }
        }
        // console.log(this.checkList);
    },
    methods:{
        getCheckList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.assignService.requestURL + '/'+ this.id,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.dataList = await JSON.parse(response.data.data[0].checkList);
                    for (let key in Object.keys(this.dataList)){
                        let data = JSON.parse(this.dataList[key].data).length;
                        this.totalData = this.totalData + data;
                    }
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        checkShiftStatus: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.workShift.checkStatus + this.id,
                headers: authHeader(),
              };
      
              await this.$axios(config)
                .then((response) => {
                  if (response.status == 200) {
                    if (response.data.flag === true) {
                        this.endButton = true;
                        this.shiftId = response.data.data.id;
                    } else {
                        this.endButton = false;
                    }
                  }
                  this.isLoading = false;
                })
                .catch((error) => {
                  console.log(error);
                  this.isLoading = false;
                });
        },
        verifyCheckList: function() {
            console.log(this.checkList);
            if(this.checkList.every(function(arr){
                return arr.every(Boolean);
            })){
                this.buttonFlag = true;
                return true;
            }
            else{
                this.buttonFlag = false;
                return false;
            }
            
        },
        updateService: async function() {
            let data = {
                status: 'Completed'
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.assignService.requestURL + '/'+ this.id,
                data: data,
                headers: authHeader()
            };
            this.$swal.showLoading()
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.status == 200) {
                    this.isLoading = false;
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.$router.push('/assigned-service');
                }
            }).catch(error => {
                this.$swal.hideLoading();
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
           
        }
    }
}