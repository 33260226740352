import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Customer",
    data() {
        return {
            dataList: [],
            sheets: [],
            emailList: [],
            uniqueEmailList: [],
            filter: {
                workingDate: '',
                email: ''
            }
        }
    },
    async created() {
        document.title = "Land Scape - Crew";
        await this.getData();
        await this.getCrewList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.report.crewAttendanceReportURL + "?workingDate=" + this.filter.workingDate + "&email=" + this.filter.email,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    // if (this.uniqueEmailList.length == 0) {
                    //     this.emailList = this.dataList.filter((value, index, self) =>
                    //         index === self.findIndex((t) => (
                    //             t.email === value.email
                    //         ))
                    //     );
                    // }
                    // if (this.uniqueEmailList.length == 0) {
                    //     this.uniqueEmailList = this.emailList.map(el => {
                    //         return { label: el.email, value: el.email }
                    //     })
                    // }
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        getCrewList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.crewList,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    if (this.uniqueEmailList.length == 0) {
                        this.emailList = response.data.data.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.email === value.email
                            ))
                        );
                    }
                    if (this.uniqueEmailList.length == 0) {
                        this.uniqueEmailList = this.emailList.map(el => {
                            return { label: el.email, value: el.email }
                        })
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Crew Name": el.name,
                    "Email": el.email,
                    "Date": el.date,
                    "Start Time": el.startTime,
                    "End Time": el.endTime,
                    "Total Hour": el.totalHour,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'crew_attendance_report(' + date + ').xlsx')
            this.sheets = [];
        },
        validateFilter: async function () {
            try {
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getData();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                workingDate: '',
                email: ''
            }
            await this.getData();
        },
    }
}