<template>
    <div class="wrapper">
        <!-- Start Sidebar -->
        <div class="sidebar-wrapper" data-simplebar="true">
            <div class="sidebar-header">
                <div>
                    <a href="/" @click="pageSideURL($event, '/')">
                        <img src="/core/assets/images/landScapelogo.png" class="logo-icon" alt="logo icon" />
                    </a>
                </div>
                <div class="toggle-icon ms-auto">
                    <i class="bx bx-arrow-to-left"></i>
                </div>
            </div>
            <ul class="metismenu" id="menu">
                <!-- <li v-if="role == 2 || role == 5">
                    <a href="/dashboard" @click="$router.push('/dashboard')">
                        <div class="parent-icon"><i class="lni lni-dashboard"></i></div>
                        <div class="menu-title">Dashboard</div>
                    </a>
                </li> -->
                <li v-if="role == 2 || role == 5">
                    <a href="/dashboard" @click="pageSideURL($event, '/dashboard')">
                        <div class="parent-icon"><i class="lni lni-dashboard"></i></div>
                        <div class="menu-title">Dashboard</div>
                    </a>
                </li>
                <li v-if="role == 2">
                    <a href="#" class="has-arrow">
                        <div class="parent-icon"><i class="bx bxs-user"></i></div>
                        <div class="menu-title">User</div>
                    </a>
                    <ul>
                        <li>
                            <a href="/admin-list" @click="pageSideURL($event, '/admin-list')"><i
                                    class="bx bx-right-arrow-alt"></i>Admin</a>
                        </li>
                        <li>
                            <a href="/supervisor-list" @click="pageSideURL($event, '/supervisor-list')"><i
                                    class="bx bx-right-arrow-alt"></i>Supervisor</a>
                        </li>
                        <li>
                            <a href="/crew-list" @click="pageSideURL($event, '/crew-list')"><i
                                    class="bx bx-right-arrow-alt"></i>Crew</a>
                        </li>
                        <li>
                            <a href="/customer-list" @click="pageSideURL($event, '/customer-list')"><i
                                    class="bx bx-right-arrow-alt"></i>Customer</a>
                        </li>
                        <li>
                            <a href="/assign-supervisor" @click="pageSideURL($event, '/assign-supervisor')"><i
                                    class="bx bx-right-arrow-alt"></i>Assign Supervisor</a>
                        </li>
                        <li>
                            <a href="/billing-information" @click="pageSideURL($event, '/billing-information')"><i
                                    class="bx bx-right-arrow-alt"></i>Customer Billing Information</a>
                        </li>
                    </ul>
                </li>
                <li v-if="role == 4">
                    <a href="/crew-home" @click="pageSideURL($event, '/crew-home')">
                        <div class="parent-icon"><i class="bx bx-group"></i></div>
                        <div class="menu-title">Crew Home</div>
                    </a>
                </li>
                <li v-if="role == 5">
                    <a href="/supervisor-home" @click="pageSideURL($event, '/supervisor-home')">
                        <div class="parent-icon"><i class="bx bx-group"></i></div>
                        <div class="menu-title">Supervisor Home</div>
                    </a>
                </li>

                <li v-if="role == 2">
                    <a href="#" class="has-arrow">
                        <div class="parent-icon">
                            <i class="fa-solid fa-money-bill-trend-up"></i>
                        </div>
                        <div class="menu-title">Expense</div>
                    </a>
                    <ul>
                        <li>
                            <a href="/expense" @click="pageSideURL($event, '/expense')"><i
                                    class="bx bx-right-arrow-alt"></i>Expense List</a>
                        </li>
                        <li>
                            <a href="/expense-category" @click="pageSideURL($event, '/expense-category')"><i
                                    class="bx bx-right-arrow-alt"></i>Expense Category</a>
                        </li>
                    </ul>
                </li>

                <!-- <li>
                    <a href="javascript:void(0)" @click="pageSideURL($event, '/expense')">
                        <div class="parent-icon">
                        <i class="fa-solid fa-money-bill-trend-up"></i>
                        </div>
                        <div class="menu-title">Expense</div>
                    </a>
                </li> -->

                <li v-if="role == 5">
                    <a href="/crews" @click="pageSideURL($event, '/crews')">
                        <div class="parent-icon"><i class="bx bx-group"></i></div>
                        <div class="menu-title">Crew List</div>
                    </a>
                </li>
                <li v-if="role == 2">
                    <a href="/service" @click="pageSideURL($event, '/service')">
                        <div class="parent-icon"><i class="fa-solid fa-list-ol"></i></div>
                        <div class="menu-title">Add Service Type</div>
                    </a>
                </li>
                <li v-if="role == 5">
                    <a href="/assigned-service" @click="pageSideURL($event, '/assigned-service')">
                        <div class="parent-icon"><i class="fa-solid fa-list-ol"></i></div>
                        <div class="menu-title">Assigned Service</div>
                    </a>
                </li>
                <li v-if="role == 2">
                    <a href="/duties-responsibilities" @click="pageSideURL($event, '/duties-responsibilities')">
                        <div class="parent-icon"><i class="fa-solid fa-list"></i></div>
                        <div class="menu-title">Duties & Responsibilities</div>
                    </a>
                </li>
                <li v-if="role == 2">
                    <a href="/employee-schedule" @click="pageSideURL($event, '/employee-schedule')">
                        <div class="parent-icon"><i class="fa-solid fa-clipboard-list"></i></div>
                        <div class="menu-title">Create Employee Schedule</div>
                    </a>
                </li>
                <li v-if="role == 2">
                    <a href="#" class="has-arrow">
                        <div class="parent-icon"><i class="bx bx-check-shield"></i></div>
                        <div class="menu-title">Service Request</div>
                    </a>
                    <ul>
                        <li>
                            <a href="/service-request" @click="pageSideURL($event, '/service-request')"><i
                                    class="bx bx-right-arrow-alt"></i>Create Service Request</a>
                        </li>
                        <li>
                            <a href="/reservation-list" @click="pageSideURL($event, '/reservation-list')"><i
                                    class="bx bx-right-arrow-alt"></i>Service Request List</a>
                        </li>
                        <li>
                            <a href="/assign-service" @click="pageSideURL($event, '/assign-service')"><i
                                    class="bx bx-right-arrow-alt"></i>Assign Service Request</a>
                        </li>
                        <li>
                            <a href="/assigned-request" @click="pageSideURL($event, '/assigned-request')"><i
                                    class="bx bx-right-arrow-alt"></i>Assigned Service
                                Request</a>
                        </li>
                    </ul>
                </li>
                <!-- <li v-if="role == 2">
                    <a href="javascript:void(0)" @click="pageSideURL($event, '/route')">
                        <div class="parent-icon"><i class="fa-solid fa-route"></i></div>
                        <div class="menu-title">Route</div>
                    </a>
                </li> -->

                <li v-if="role == 2">
                    <a href="#" class="has-arrow">
                        <div class="parent-icon"><i class="fa-solid fa-route"></i></div>
                        <div class="menu-title">Route</div>
                    </a>
                    <ul>
                        <li>
                            <a href="/route" @click="pageSideURL($event, '/route')"><i class="bx bx-right-arrow-alt"></i>Route List</a>
                        </li>
                        <li>
                            <a href="/add-route" @click="pageSideURL($event, '/add-route')"><i class="bx bx-right-arrow-alt"></i>Add Route</a>
                        </li>
                    </ul>
                </li>

                <li v-if="role == 2">
                    <a href="/check-list" @click="pageSideURL($event, '/check-list')">
                        <div class="parent-icon">
                            <i class="fa-solid fa-list-check"></i>
                        </div>
                        <div class="menu-title">Service Checklist</div>
                    </a>
                </li>
                <li v-if="role == 2">
                    <a href="#" class="has-arrow">
                        <div class="parent-icon">
                            <i class="fa-solid fa-list-check"></i>
                        </div>
                        <div class="menu-title">User Checklist</div>
                    </a>
                    <ul>
                        <li>
                            <a href="/crew-check-list" @click="pageSideURL($event, '/crew-check-list')"><i
                                    class="bx bx-right-arrow-alt"></i>Crew Checklist</a>
                        </li>
                        <li>
                            <a href="/supervisor-check-list" @click="pageSideURL($event, '/supervisor-check-list')"><i
                                    class="bx bx-right-arrow-alt"></i>Supervisor Checklist</a>
                        </li>
                    </ul>
                </li>
                <li v-if="role == 2">
                    <a href="#" class="has-arrow">
                        <div class="parent-icon">
                            <i class="fa-sharp fa-solid fa-gear"></i>
                        </div>
                        <div class="menu-title">Report</div>
                    </a>
                    <ul>
                        <li>
                            <a href="/time-clock-report" @click="pageSideURL($event, '/time-clock-report')"><i
                                    class="bx bx-right-arrow-alt"></i>Time Clock Report</a>
                        </li>
                        <li>
                            <a href="/crew-report" @click="pageSideURL($event, '/crew-report')"><i
                                    class="bx bx-right-arrow-alt"></i>Crew Report</a>
                        </li>
                        <li>
                            <a href="/supervisor-report" @click="pageSideURL($event, '/supervisor-report')"><i
                                    class="bx bx-right-arrow-alt"></i>Supervisor Report</a>
                        </li>
                        <li>
                            <a href="/supervisor-log-report" @click="pageSideURL($event, '/supervisor-log-report')"><i
                                    class="bx bx-right-arrow-alt"></i>Supervisor Log Report</a>
                        </li>
                        <li>
                            <a href="/expense-report" @click="pageSideURL($event, '/expense-report')"><i
                                    class="bx bx-right-arrow-alt"></i>Expense Report</a>
                        </li>
                        <li>
                            <a href="/route-report" @click="pageSideURL($event, '/route-report')"><i
                                    class="bx bx-right-arrow-alt"></i>Route Report</a>
                        </li>
                        <li>
                            <a href="/assigned-request-report" @click="pageSideURL($event, '/assigned-request-report')"><i
                                    class="bx bx-right-arrow-alt"></i>Assigned Request Report</a>
                        </li>
                        <li>
                            <a href="/today-service-request" @click="pageSideURL($event, '/today-service-request')"><i
                                    class="bx bx-right-arrow-alt"></i>Today Service Request</a>
                        </li>
                        <li>
                            <a href="/today-expense" @click="pageSideURL($event, '/today-expense')"><i
                                    class="bx bx-right-arrow-alt"></i>Today Expense</a>
                        </li>
                    </ul>
                </li>                
                <li v-if="role == 4 || role == 5">
                    <a href="/attendance-report" @click="pageSideURL($event, '/attendance-report')">
                        <div class="parent-icon">
                            <i class="fa-solid fa-clipboard-user"></i>
                        </div>
                        <div class="menu-title">Attendance</div>
                    </a>
                </li>
                <li v-if="role == 5">
                    <a href="/crew-attendance-report" @click="pageSideURL($event, '/crew-attendance-report')">
                        <div class="parent-icon">
                            <i class="fa-solid fa-list"></i>
                        </div>
                        <div class="menu-title">Crew Attendance</div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="has-arrow">
                        <div class="parent-icon">
                            <i class="fa-sharp fa-solid fa-gear"></i>
                        </div>
                        <div class="menu-title">Control Panel</div>
                    </a>
                    <ul>
                        <li>
                            <a href="/message" @click="pageSideURL($event, '/message')">
                                &nbsp;<i class="fa-solid fa-comments"></i>Message
                            </a>
                        </li>
                    </ul>
                </li>
                <li v-if="role == 2">
                    <a href="/calendar" @click="pageSideURL($event, '/calendar')">
                        <div class="parent-icon">
                            <i class="fa fa-calendar"></i>
                        </div>
                        <div class="menu-title">Calendar</div>
                    </a>
                </li>
                <li v-if="role == 2">
                    <a href="/payment-list" @click="pageSideURL($event, '/payment-list')">
                        <div class="parent-icon">
                            <i class="fa fa-money"></i>
                        </div>
                        <div class="menu-title">Payment List</div>
                    </a>
                </li>
                <li v-if="role == 4">
                    <a href="javascript:void(0)" @click="$router.push('/schedule-list')">
                        <div class="parent-icon">
                            <i class="fa-solid fa-clipboard-list"></i>
                        </div>
                        <div class="menu-title">My Schedule</div>
                    </a>
                </li>
                <!-- <li v-if="role == 2">
                    <a href="#" class="has-arrow">
                        <div class="parent-icon"><i class="bx bx-check-shield"></i></div>
                        <div class="menu-title">Policy</div>
                    </a>
                    <ul>
                        <li>
                        <a
                            href="/terms-and-conditions"
                            @click="pageSideURL($event, '/terms-and-conditions')"
                            ><i class="bx bx-right-arrow-alt"></i>Terms & Conditions</a
                        >
                        </li>
                    </ul>
                </li> -->
            </ul>
        </div>
        <!-- End Sidebar -->
        <!-- Start Header -->
        <header>
            <div class="topbar d-flex align-items-center">
                <nav class="navbar navbar-expand">
                    <div class="mobile-toggle-menu"><i class="bx bx-menu"></i></div>
                    <div class="search-bar flex-grow-1">
                        <!-- <div class="position-relative search-bar-box">
							<input type="text" class="form-control search-control" placeholder="Type to search..."> <span class="position-absolute top-50 search-show translate-middle-y"><i class='bx bx-search'></i></span>
							<span class="position-absolute top-50 search-close translate-middle-y"><i class='bx bx-x'></i></span>
						</div> -->
                    </div>
                    <div class="top-menu ms-auto">
                        <ul class="navbar-nav align-items-center">
                            <li class="nav-item mobile-search-icon">
                                <a class="nav-link" href="#"><i class="bx bx-search"></i></a>
                            </li>
                            <li class="nav-item dropdown dropdown-large">
                                <!-- <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">1</span>
									<i class='bx bx-bell'></i>
								</a> -->
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a href="javascript:;">
                                        <div class="msg-header">
                                            <p class="msg-header-title">Notifications</p>
                                            <p class="msg-header-clear ms-auto">Marks all as read</p>
                                        </div>
                                    </a>
                                    <div class="header-notifications-list">
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-primary text-primary">
                                                    <i class="bx bx-group"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">
                                                        New Customers<span class="msg-time float-end">14 sec ago</span>
                                                    </h6>
                                                    <p class="msg-info">5 new user registered</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <a href="#">
                                        <div class="text-center msg-footer">
                                            View All Notifications
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li class="nav-item dropdown dropdown-large">
                                <!-- <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">1</span>
									<i class='bx bx-comment'></i>
								</a> -->
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a href="#">
                                        <div class="msg-header">
                                            <p class="msg-header-title">Messages</p>
                                            <p class="msg-header-clear ms-auto">Marks all as read</p>
                                        </div>
                                    </a>
                                    <div class="header-message-list">
                                        <a class="dropdown-item" href="#">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="../../../../public/core/assets/images/avatars/avatar-1.png"
                                                        class="msg-avatar" alt="user avatar" />
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">
                                                        Daisy Anderson
                                                        <span class="msg-time float-end">5 sec ago</span>
                                                    </h6>
                                                    <p class="msg-info">The standard chunk of lorem</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <a href="#">
                                        <div class="text-center msg-footer">View All Messages</div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="user-box dropdown">
                        <a class="
                d-flex
                align-items-center
                nav-link
                dropdown-toggle dropdown-toggle-nocaret
              " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../../../../public/core/assets/images/avatars/avatar-1.png" class="user-img"
                                alt="user avatar" />
                            <div class="user-info ps-3">
                                <p class="user-name mb-0">{{ name }}</p>
                                <p class="designattion mb-0">{{ email }}</p>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <router-link to="/profile" class="dropdown-item"><i
                                        class="bx bx-user"></i><span>Profile</span></router-link>
                            </li>
                            <li>
                                <a href="/" @click="pageSideURL($event, '/')" class="dropdown-item"><i
                                        class="bx bx-home-circle"></i><span>Dashboard</span></a>
                            </li>
                            <li>
                                <a @click="logout" class="dropdown-item" href="#"><i
                                        class="bx bx-log-out-circle"></i><span>Logout</span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
        <!-- End Header -->
        <!-- Start Footer -->
        <footer class="page-footer">
            <p class="mb-0">Copyright © 2021. All right reserved.</p>
        </footer>
        <!-- End Footer -->
    </div>
</template>

<script src="../js/home.js"></script>