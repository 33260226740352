import { watch } from "vue";
import { authHeader } from "../../../auth";
export default {
    data() {
        return {
            count: 5,
            checkList: [],
            dataList: [],
            buttonFlag: false,
            endButton: false,
            id: localStorage.getItem('id'),
            shiftId: '',
            startProcess: false,
            shiftFlag: false,
            disableButton: false,
            checkDutiesFlag: false,
            showDuties: false,
            duties: ''
        }
    },
    async mounted() {
        document.title = "Land Scape - Crew Home";
        await this.checkShiftStatus();
        await this.getCheckList();
        await this.getDuties();
        for (let i = 0; i < this.count; i++) {
            this.checkList.push(false);
        };
    },
    methods: {
        getCheckList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.checklist.crewChecklist,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.dataList = await response.data.data;
                    this.count = this.dataList.length;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        checkShiftStatus: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.workShift.checkStatus + this.id,
                headers: authHeader(),
            };

            await this.$axios(config)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.flag === true) {
                            this.endButton = true;
                            this.startProcess = true;
                            this.shiftId = response.data.data.id;
                            this.shiftFlag = response.data.isCompleted;
                        } else {
                            this.endButton = false;
                            this.startProcess = false;
                            this.shiftFlag = response.data.isCompleted;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        verifyCheckList: function () {
            if (this.checkList.length == this.dataList.length) {
                if (this.checkList.every(element => element == true)) {
                    this.buttonFlag = true;
                    return true;
                }
                else {
                    this.buttonFlag = false;
                    return false;
                }
            }
            else {
                this.buttonFlag = false;
                return false
            }

        },
        checkProcessAndStart: async function(){
            await this.checkShiftStatus();
            if(this.startProcess == false){
                this.startProcess = true;
            }
        },
        startShift: async function () {
            if (this.verifyCheckList()) {
                let data = {
                    startTime: new Date().toLocaleTimeString('en-GB'),
                    date: new Date()
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.workShift.shiftURL,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.endButton = true
                        this.shiftId = response.data.data.id;
                        for (let i = 0; i < this.count; i++) {
                            this.checkList[i] = false;
                        };
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
            else {
                alert('Please check')
            }
        },
        endShift: async function () {
            let data = {
                endTime: new Date().toLocaleTimeString('en-GB')
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.workShift.shiftURL + '/' + this.shiftId,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then(async(response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: response.data.message
                    });
                    for (let i = 0; i < this.count; i++) {
                        this.checkList[i] = false;
                    };
                    this.endButton = false;
                    this.buttonFlag = false;
                    this.startProcess = false;
                    await this.checkShiftStatus();
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async getDuties() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.crew.duties
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.duties = response.data.data.length ? response.data.data[0].DutiesAndResponsibilities : "";
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
    },

    watch:{
        shiftFlag: function(){
            if(this.shiftFlag == true){
                this.disableButton = true;
            }else{
                this.disableButton = false;
            }
        }
    }
}