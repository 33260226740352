import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "ReserveList",
    components: {
        Loading
    },
    data() {
        return {
            dataList: [],
            filter: {
                status: '',
                date: ''
            },
            sheets: [],
            userList: [],
            assignee: [],
            route: '',
            userAssign: '',
            request: [],
            routeList: [],
            requestList: [],
            bulkAssign: [],
        }
    },
    async mounted() {
        await this.getReserveList();
        await this.getUserList();
        this.loadJS();
    },
    methods: {
        loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        getReserveList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.assignService.requestURL + "?status=" + this.filter.status + "&date=" + this.filter.date,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    this.assignee = response.data.data.map(el => {
                        return { value: { id: el.assigneeId, fullName: el.assigneeName }, label: el.assigneeName }
                    })
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        getUserList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.crew.supervisorURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.userList = response.data.data.map((el) => {
                        return { value: { id: el.id, fullName: el.fullName }, label: el.fullName };
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        getRouteList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.assignService.routeList,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.routeList = response.data.data.map((el) => {
                        return { value: el, label: el.routeName };
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        getServiceRequest: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.assignService.routeList + '/' + this.route.value.routeId,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.requestList = response.data.data.map((el) => {
                        return { value: el, label: el.reserveId };
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },


        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    'Id': el.serviceRequestId,
                    'Assigned To': el.assigneeName,
                    "Route Name": el.routeName,
                    "Status": el.status

                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'assigned_request_report(' + date + ').xlsx')
        },

        validateFilter: async function () {
            try {
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getReserveList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },

        clearFilter: async function () {
            this.filter = {
                status: '',
                date: ''
            }
            await this.getReserveList();
        },
    }
}
