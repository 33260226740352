<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Crew Check List
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add Check List
            </button>
            <button type="button" class="btn btn-secondary ms-2" @click="exportData">
                <i class="bx bxs-download"></i>Export
            </button>
          </div>
          <!-- Add Modal -->
          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form>
              <div class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Add Check List
                    </h5>
                    <button
                      type="button"
                      id="closeButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body row">
                    <div class="col-md-12">
                        <label class="form-label"
                        >Check List Name:<span style="color: red; font-size: 18px"
                            >*</span
                        ></label
                        >
                        <input
                        class="form-control mb-1"
                        type="text"
                        v-model="name"
                        placeholder="Enter Name"
                        />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      @click="addChecklist"
                      class="btn btn-primary"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
          <!-- Edit Modal -->
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateChecklist">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Update Check List
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body row">
                    <div class="col-md-8">
                        <label class="form-label"
                        >Check List Name:<span style="color: red; font-size: 18px"
                            >*</span
                        ></label
                        >
                        <input
                        class="form-control mb-1"
                        type="text"
                        v-model="singleCheckList.name"
                        placeholder="Enter Name"
                        />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Crew Check List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Check List Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <button
                        type="button"
                        class="btn btn-outline-success btn-sm m-1"
                        @click="editCheckList(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                      >
                        <i class="fa-solid fa-pen-to-square me-0"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm m-1"
                        @click="deleteCheckList(data.id)"
                      >
                        <i class="fa-solid fa-trash-can me-0"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Check List Name</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/crew-checklist.js'></script>

<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>