<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-6">
                    <h6 class="mb-0 text-uppercase">Service Request List</h6>
                </div>
                <div class="col-6 float-right">
                    <!-- <a
            role="button"
            class="btn btn-warning btn-sm m-1 mb-md-0"
            style="float: right"
            @click="exportData()"
          >
            <i class="fa-solid fa-file-export"></i>
            Export
          </a> -->
                    <a role="button" data-bs-toggle="modal" data-bs-target="#filterModal" id="filterTable"
                        class="btn btn-success btn-sm m-1 mb-md-0" style="float: right">
                        <i class="bx bx-filter text-white float-left"></i>Bulk Assign
                    </a>
                </div>
            </div>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Service Request Id</th>
                                    <th>Customer Name</th>
                                    <th>Route Name</th>
                                    <th>Service Request Status</th>
                                    <th>Assign To</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{data.reserveId}}</td>
                                    <td>{{data.userName}}</td>
                                    <td>{{data.routeName}}</td>
                                    <td>{{data.reservationStatus}}</td>
                                    <td>
                                        <v-select :options="userList"
                                            @option:selected="assignUser(assignee[index], data)"
                                            v-model="assignee[index]"></v-select>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Service Request Id</th>
                                    <th>Customer Name</th>
                                    <th>Route Name</th>
                                    <th>Service Request Status</th>
                                    <th>Assign To</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="filterModalLabel">Assigning</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form class="filter_form" @submit.prevent="validateFilter">
                                <div class="row mb-3">
                                    <div class="col-4 col-sm-4">
                                        <label class="form-label">Route:</label>
                                        <v-select :options="routeList" @option:selected="getServiceRequest" v-model="route"></v-select>
                                    </div>
                                    <div class="col-4 col-sm-4">
                                        <label class="form-label">Service Request:</label>
                                        <Multiselect v-model="requestList[index]" :options="requestList"  mode="tags" :close-on-select="false" :create-option="true" :searchable="true" />
                                    </div>
                                    <div class="col-4 col-sm-4">
                                        <label class="form-label">Assign to:</label>
                                        <v-select :options="userList" v-model="userAssign"></v-select>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                                            data-bs-dismiss="modal" @click="bulkAssignUser">
                                            Assign
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/assign-service.js'></script>

<style scoped>
    @import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

    .edit-button {
        background-color: #17a00e;
    }

    .delete-button {
        background-color: #f41127;
    }
</style>