import { authHeader } from "../../../auth";

export default {
    name: "UpdateService",
    data() {
        return {
            featuredImage: "",
            previewFeaturedImage: "",
            featureList: [],
            serviceName: "",
            servicePrice: "",
            serviceImage: "",
            //status: "",
            serviceDetails: "",
            id: this.$route.params.id,
            serviceFeature: [],
            addFeaturesList: [],
            data: {}
        }
    },
    async created() {
        document.title = "Land Scape - Update Service";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    mounted() {
        if (this.$route.params.id != undefined) {
            this.fetchEditData(this.$route.params.id);
        }
    },
    methods: {
        async fetchEditData() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.requestURL + '/' + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.data = response.data.data[0];
                    this.serviceName = this.data.serviceName;
                    this.servicePrice = this.data.servicePrice;
                    this.serviceImage = this.data.serviceImage;
                    this.serviceDetails = this.data.serviceDetails;
                    this.serviceFeature = this.data.serviceFeature.split(',');
                    for(let i=0; i<this.serviceFeature.length; i++){
                        this.addFeaturesList.push({featureName: ""});
                    }
                }
            }).catch(error => {
                console.log(error);
            });
            // let jsonData = JSON.parse(e);
            // this.id = jsonData['id'];
            // this.serviceName = jsonData['serviceName'];
            // this.servicePrice = jsonData['servicePrice'];
            // this.serviceImage = jsonData['serviceImage'];
            //this.status = jsonData['status'];
            // this.serviceDetails = jsonData['serviceDetails'];
            // this.serviceFeature = jsonData['serviceFeature'].split(',');
            // for(let i=0; i<this.serviceFeature.length; i++){
            //     this.addFeaturesList.push({featureName: ""});
            // }
        },
        uploadFeaturedImage: function (event) {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        addFeatures: function () {
            this.addFeaturesList.push({ featureName: "" });
        },
        removeFeature: async function (index) {
            this.addFeaturesList.splice(index, 1);
            this.serviceFeature.splice(index, 1);
        },
        updateService: async function () {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("serviceName", this.serviceName);
            formData.append("servicePrice", this.servicePrice);
            formData.append("serviceDetails", this.serviceDetails);
            formData.append("serviceImage", this.featuredImage ? this.featuredImage : this.serviceImage);
            formData.append("serviceFeature", this.serviceFeature);
            //formData.append("status", this.status);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.service.requestURL + '/' + this.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: "Data updated successfully.",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.$router.push("/service");
                }
                this.$refs.featuredImage.value = null;
                this.featuredImage = null;
                this.previewFeaturedImage = null;
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}