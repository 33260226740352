<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Crew List
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-secondary ms-2"
              @click="exportData"
            >
              <i class="bx bxs-download"></i>Export
            </button>
          </div>
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateCrew">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">View Crew</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="modal-body">
                        <label class="form-label">Name:</label>
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="singleCrew.fullName"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="modal-body">
                        <label class="form-label">Phone:</label>
                        <input
                          class="form-control mb-1"
                          type="tel"
                          v-model="singleCrew.phone"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="modal-body">
                        <label class="form-label"
                          >Supervisor<span style="color: red; font-size: 18px"
                            >*</span
                          >:</label
                        >
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="singleCrew.supervisorName"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="modal-body">
                        <label class="form-label"
                          >Position:
                          <span style="color: red; font-size: 18px">*</span
                          >:</label
                        >
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="singleCrew.position"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Role Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Crew List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>User Type</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.fullName }}</td>
                  <td>{{ data.email }}</td>
                  <td>{{ data.phone }}</td>
                  <td>{{ data.userType }}</td>
                  <td>{{ $filters.formatDateTime(data.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <button
                        type="button"
                        class="btn btn-outline-success btn-sm m-1"
                        v-on:click="editCrew(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                      >
                        <i class="fa-solid fa-eye me-0"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>User Type</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/crew-list.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>