<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Today Service Request Report
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-warning text-dark"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <i class="bx bx-filter text-dark float-left"></i>Filter
            </button>
            <button
              type="button"
              class="btn btn-primary ms-2"
              @click="exportData"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button>
          </div>
          <!-- Edit Role Modal -->
          <!-- filter modal -->
          <div
            class="modal fade"
            id="filterModal"
            tabindex="-1"
            aria-labelledby="filterModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="filterClose"
                  ></button>
                </div>
                <div class="modal-body">
                  <form class="filter_form" @submit.prevent="validateFilter">
                    <div class="row mb-3">
                      <div class="col-12 col-sm-12">
                        <label class="form-label">Service Request Status</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="filter.requestStatus"
                        >
                          <option style="display: none" selected value="">
                            Select request status
                          </option>
                          <option value="">All</option>
                          <option value="Pending">Pending</option>
                          <option value="Processing">Processing</option>
                          <option value="Working">Working</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="text-center">
                        <button
                          type="submit"
                          @click="clearFilter()"
                          class="
                            btn btn-sm btn-warning
                            rounded-pill
                            ps-3
                            pe-3
                            m-1
                          "
                          data-bs-dismiss="modal"
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- filter modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Today Service Request Report</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Service Request Id</th>
                  <th>Customer Name</th>
                  <th>Route</th>
                  <th>Request Date</th>
                  <th>Payment Status</th>
                  <th>Service Request Status</th>
                  <th>Paid Amount</th>
                  <th>Due Amount</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <!-- <td>{{bulkStatusUpdate}}</td> -->
                  <td>{{ data.reserveId }}</td>
                  <td>{{ data.userName }}</td>
                  <td>{{ data.routeName }}</td>
                  <td>{{ data.serviceRequestDate }}</td>
                  <td>{{ data.paymentStatus }}</td>
                  <td>{{ data.reservationStatus }}</td>
                  <td>{{ data.paidAmount }}</td>
                  <td>{{ data.dueAmount }}</td>
                  <td>{{ data.totalAmount }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Service Request Id</th>
                  <th>Customer Name</th>
                  <th>Route</th>
                  <th>Request Date</th>
                  <th>Payment Status</th>
                  <th>Service Request Status</th>
                  <th>Paid Amount</th>
                  <th>Due Amount</th>
                  <th>Total Amount</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script src='../js/today-service-request.js'></script>
  
  <style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>