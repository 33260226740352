import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Customer",
    data() {
        return {
            userType: 'crew',
            singleCrew: {},
            dataList: [],
            supervisor: "",
            supervisorList: [],
            updateSupervisor: "",
            data: {
                supervisor: ""
            },
            bulkAssignChecklist: [],
            bulkCrew: [],
            bulkSupervisor: "",
            selectAll: "",
        }
    },
    async created() {
        document.title = "Land Scape - Crew";
        await this.getCrewList();
        await this.getSupervisorList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getCrewList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getUnassignedCrewURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        getSupervisorList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.crew.supervisorURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.supervisorList = response.data.data.map((el) => {
                    return { value: el, label: el.fullName };
                })
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        updateCrew: async function (id, param) {
            let data = {
                supervisorId: param.id,
                supervisorName: param.fullName,
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.user.assignSupervisorURL + id,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    let closeBtn = document.getElementsByClassName("multiselect-clear-icon");
                    for (var i = 0; i < closeBtn.length; i++) {
                        closeBtn[i].click();
                    }
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.data = {
                        supervisor: ""
                    }
                    await this.getCrewList();
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });

        },
        deleteCrew: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.user.singleUser + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getCrewList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        bulkHandle: function (index, status, data) {
            if (status == true) {
                this.bulkCrew[index] = data
            } else if (status == false) {
                this.bulkCrew[index] = 0
            }
        },
        handleSelectAll: function () {
            if (this.selectAll == true) {
                this.dataList.map((el, index) => {
                    this.bulkAssignChecklist[index] = true;
                    this.bulkCrew[index] = el.id;
                })
            } else if (this.selectAll == false) {
                this.dataList.map((el, index) => {
                    this.bulkAssignChecklist[index] = false;
                    this.bulkCrew = [];
                })
            }
        },
        handleBulkSubmit: async function () {
            try {
                if (this.bulkCrew.length > 0) {
                    let crew = [...new Set(this.bulkCrew)];
                    let confirmedCrew = crew.filter(item => !(item == 0));
                    let data = {
                        crewId: confirmedCrew,
                        supervisorId: this.bulkSupervisor.id,
                        supervisorName: this.bulkSupervisor.fullName
                    }
                    let config = {
                        method: "PATCH",
                        url: this.$serverURL + this.$api.user.assignBulkCrewUnderSupervisorURL,
                        data: data,
                        headers: authHeader()
                    };
                    await this.$axios(config).then((response) => {
                        if (response.data.statusCode == 200) {
                            this.bulkAssignChecklist = [],
                                this.bulkCrew = [],
                                this.bulkSupervisor = "";
                            this.selectAll = ""
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                            this.getCrewList();
                        }
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please select a crew first"
                    })
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        }
    }
}