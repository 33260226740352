<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Service List
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button @click="$router.push('/add-service')" type="button" class="btn btn-primary">
                            <i class="bx bxs-plus-square"></i>Add Service
                        </button>
                        <button @click="$router.push('/import-service')" type="button" class="btn btn-success ms-2">
                            <i class="bx bxs-plus-square"></i>Import Service
                        </button>
                        <button type="button" class="btn btn-secondary ms-2" @click="exportData">
                            <i class="bx bxs-download"></i>Export
                        </button>
                    </div>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Service List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index+1 }}</td>
                                    <td>{{ data.serviceName }}</td>
                                    <td>{{ data.servicePrice }}</td>
                                    <td>
                                        <img v-if="data.serviceImage" :src="$imageURL+ 'service-image/' + data.serviceImage" alt="" height="60" width="80"/>
                                        <img v-else src="/core/assets/images/400.png" alt="" height="60" width="80"/>
                                    </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <button type="button" class="btn btn-outline-success btn-sm m-1" v-on:click="$router.push({ name: 'UpdateService', params: { id: data.id }})"><i class="fa-solid fa-pen-to-square me-0"></i></button>
                                            <button type="button" class="btn btn-outline-danger btn-sm m-1" v-on:click="deleteService(data.id)"><i class="fa-solid fa-trash-can me-0"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/service.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>
