
import customer from '../js/customer';

<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Add Billing Information
                        </li>
                    </ol>
                </nav>
            </div>
            <h6 class="mb-0 text-uppercase">Add Billing Information</h6>
            <hr />
            <form @submit.prevent="addBilling()">
                <div class="row">
                    <div class="col-sm">
                        <div class="card">
                            <div class="card-body">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <label class="form-label">Customer<span
                                                    style="color: red; font-size: 18px">*</span></label>
                                                    <Multiselect v-model="customer" id="inputVendor"
                                                    :options="customerList" :searchable="true" />
                                        </div>
                                        <div class="modal-body">
                                            <label class="form-label">Billing Name<span
                                                    style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-1" type="text" placeholder="Enter name"
                                                v-model="name" />
                                        </div>
                                        <div class="modal-body">
                                            <label class="form-label">Billing Email<span
                                                    style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-1" type="email" placeholder="Enter email"
                                                v-model="email" />
                                        </div>
                                        <div class="modal-body">
                                            <label class="form-label">Contact No</label>
                                            <input class="form-control mb-1" type="text" placeholder="Enter mobile"
                                                v-model="mobile" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="card">
                            <div class="card-body">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-body row">
                                            <div class="col">
                                                <label class="form-label">State Code</label>
                                                <input class="form-control mb-1" placeholder="Enter state code"
                                                    type="text" v-model="stateCode" />
                                            </div>
                                            <div class="col">
                                                <label class="form-label">Post Code</label>
                                                <input class="form-control mb-1" placeholder="Enter postal code"
                                                    type="text" v-model="postCode" />
                                            </div>
                                        </div>
                                        <div class="modal-body row">
                                            <div class="col">
                                                <label class="form-label">Country</label>
                                                <Multiselect v-model="billingCountry" id="inputVendor"
                                                    :options="countryList" :searchable="true" />
                                            </div>
                                            <div class="col">
                                                <label class="form-label">City</label>
                                                <input class="form-control mb-1" placeholder="Enter city" type="text"
                                                    v-model="city" />
                                            </div>
                                        </div>
                                        <div class="modal-body row">
                                            <div class="col">
                                                <label for="inputProductTitle" class="form-label">Billing Address
                                                    1</label>
                                                <textarea class="form-control" id="floatingTextarea"
                                                    v-model="addressOne"
                                                    placeholder="Enter street address1"></textarea>
                                            </div>
                                            <div class="col">
                                                <label for="inputProductTitle" class="form-label">Billing Address 2
                                                    (Optional)</label>
                                                <textarea class="form-control" id="floatingTextarea"
                                                    v-model="addressTwo"
                                                    placeholder="Enter street address2"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-grid">
                                <button type="submit" class="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script src='../js/add-billing.js'></script>

<style scoped>
    @import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

    .close-btn {
        font-size: 22px;
        position: relative;
        display: flex;
        bottom: 55px;
        left: 10px;
        float: right;
    }

    .add-btn {
        float: right;
        display: flex;
        position: relative;
        left: 21px;
    }
</style>