<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Admin List
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add Admin
            </button>
            <button
              type="button"
              class="btn btn-secondary ms-2"
              @click="exportUsers"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button>
          </div>
          <!-- Add Modal -->
          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form>
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Add Admin</h5>
                    <button
                      type="button"
                      id="closeButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body row">
                    <div class="col">
                        <label class="form-label"
                        >Name:<span style="color: red; font-size: 18px"
                            >*</span
                        ></label
                        >
                        <input
                        class="form-control mb-1"
                        type="text"
                        v-model="name"
                        />
                    </div>
                    <div class="col">
                        <label class="form-label"
                          >Email:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="email"
                          v-model="email"
                        />
                    </div>
                  </div>

                    <div class="modal-body row">
                    <div class="col">
                        <label class="form-label"
                      >User Type:<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      class="form-control mb-1"
                      type="tel"
                      v-model="userType"
                      disabled
                    />
                    </div>
                    <div class="col">
                        <label class="form-label"
                          >Phone:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="tel"
                          v-model="phone"
                        />
                    </div>
                  </div>


                  <div class="modal-body row">
                    <div class="col">
                       <label class="form-label"
                      >Password:<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <div class="input-group" id="show_hide_password">
                      <input
                        :type="inputTypePassword"
                        v-model="password"
                        class="form-control border-end-0"
                      />
                      <a
                        href="javascript:void(0);"
                        @click="changePasswordType()"
                        class="input-group-text bg-transparent"
                        ><i
                          :class="iconStatus ? 'bx bx-hide' : 'bx bx-show'"
                        ></i
                      ></a>
                    </div>
                    </div>
                    <div class="col">
                        <label class="form-label"
                      >Confirm Password:<span
                        style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <div class="input-group" id="show_hide_password">
                      <input
                        :type="inputTypeConfirmPassword"
                        v-model="confirmPassword"
                        class="form-control border-end-0"
                      />
                      <a
                        href="javascript:void(0);"
                        @click="changeConfirmPasswordType()"
                        class="input-group-text bg-transparent"
                        ><i :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"></i
                      ></a>
                    </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      @click="addAdmin"
                      class="btn btn-primary"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
          <!-- Edit Modal -->
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateAdmin">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Update Admin</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body row">
                    <div class="col">
                        <label class="form-label"
                            >Name:<span style="color: red; font-size: 18px"
                                >*</span
                            ></label
                        >
                        <input
                        class="form-control mb-1"
                        type="text"
                        v-model="singleAdmin.fullName"
                        />
                    </div>
                    <div class="col">
                        <label class="form-label"
                            >Phone:<span style="color: red; font-size: 18px"
                                >*</span
                            ></label
                        >
                        <input
                        class="form-control mb-1"
                        type="tel"
                        v-model="singleAdmin.phone"
                        />
                    </div>
                    
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Role Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Admin List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>User Type</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.fullName }}</td>
                  <td>{{ data.email }}</td>
                  <td>{{ data.phone }}</td>
                  <td>{{ data.userType }}</td>
                  <td>{{ $filters.formatDateTime(data.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <button
                        type="button"
                        class="btn btn-outline-success btn-sm m-1"
                        v-on:click="editAdmin(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                      >
                      <i class="fa-solid fa-pen-to-square me-0"></i>
                      </button>

                      <button
                        type="button"
                        :disabled="data.id == id"
                        class="btn btn-outline-danger btn-sm m-1"
                        v-on:click="deleteAdmin(data.id)"
                      >
                        <i class="fa-solid fa-trash-can me-0"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>User Type</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/admin.js'></script>

<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>