import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "Customer",
    data() {
        return {
            fullName: '',
            email: '',
            phone: '',
            position: '',
            userType: 'crew',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            singleCrew: {},
            dataList: [],
            sheets: [],
            supervisor: "",
            supervisorList: [],
            updateSupervisor: "",
            createdDate: ''
        }
    },
    async created() {
        document.title = "Land Scape - Crew";
        await this.getCrewList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getCrewList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.crewList,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        editCrew: function(data) {
            this.singleCrew = data;
        },
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Crew Name": el.fullName,
                    "Phone": el.phone,
                    "Email": el.email,
                    "Position": el.position,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'crew_report(' + date + ').xlsx')
            this.sheets = [];
        },
        filterCustomer: async function () {
            document.getElementById("closeFilter").click();
            if (this.createdDate) {
                window.$('#example').DataTable().destroy();
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.user.getUsersListURL + "?userType=" + this.userType + "&createdDate=" + this.createdDate,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.dataList = response.data.data;
                        this.createdDate = '';
                    }
                    this.isLoading = false;
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
                window.$('#example').DataTable();
            }
            else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide joining date!"
                });
            }
        }
    }
}