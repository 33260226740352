<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Messages
                        </li>
                    </ol>
                </nav>
            </div>
            <hr />
            <div class="card">
                <div class="chat-container">
                    <div class="chat-messages">
                        <div v-for="(data, index) in messages" class="message" :key="index">
                            <p class="meta">
                                {{ data.userName }}&nbsp;
                                <span>({{roles[data.role]}})</span>&nbsp;
                                <span>{{ dateTimeFormat(data.createdAt) }}</span>
                            </p>
                            <p class="text">
                                {{ data.text }}
                            </p>
                        </div>
                    </div>
                    <div class="chat-form-container">
                        <form @submit.prevent="sendMessage">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="messageText" placeholder="Enter Message" required autocomplete="off" />
                                <button class="btn" type="submit">
                                    <i class="fas fa-paper-plane"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

ul {
	list-style: none;
}

a {
	text-decoration: none;
}

p {
    margin: 0;
}

.btn {
	cursor: pointer;
	padding: 5px 15px;
	background: #e6e9ff;
	color: #0d6efd;
	border: 0;
	font-size: 17px;
    margin-left: 1rem;
}

.chat-container {
	background: #fff;
	margin: 1.5rem;
	overflow: hidden;
}

.chat-messages {
	padding: 30px;
	height: 60vh;
	overflow-y: scroll;
}

.chat-messages .message {
	padding: 10px;
    margin-bottom: 0.5rem;
	background-color: #e6e9ff;
	border-radius: 5px;
    width: 50vw;
}

.chat-messages .message .meta {
	font-size: 15px;
	font-weight: bold;
	color: #0d6efd;
	opacity: 0.7;
	margin-bottom: 7px;
    width: auto;
}

.chat-messages .message .text {	
    width: auto;
}

.chat-messages .message .meta span {
	color: #777;
}

.chat-form-container {
	padding: 20px 30px;
}

.chat-form-container form {
	display: flex;
}

.chat-form-container input[type='text'] {
	font-size: 16px;
	padding: 5px;
	height: 40px;
	flex: 1;
}

@media (max-width: 700px) {
	.chat-container {
        margin: auto;
    }

    .chat-messages .message {
        width: auto;
    }
}
</style>

<script src='../js/message.js'></script>