import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Customer",
    data() {
        return {
            fullName: '',
            email: '',
            phone: '',
            position: '',
            userType: 'crew',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            singleCrew: {},
            supervisorList: [],
            sheets: [],
            supervisor: "",
            supervisorList: [],
            createdDate: '',
            emailList: [],
            filter: {
                joiningDate: '',
                email: ''
            }
        }
    },
    async created() {
        document.title = "Land Scape - Supervisor";
        await this.getSupervisorList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getSupervisorList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.report.supervisorReport + "?joiningDate=" + this.filter.joiningDate + "&email=" + this.filter.email,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.supervisorList = response.data.data
                if (this.emailList.length == 0) {
                    this.emailList = this.supervisorList.map(el => {
                        return { label: el.email, value: el.email }
                    })
                }
            }).catch(error => {
                console.log(error);
            });
        },
        exportUsers: function () {
            this.supervisorList.map((el) => {
                this.sheets.push({
                    "Supervisor Name": el.name,
                    "Email": el.email,
                    "Total Assigned Service Request": el.totalAssignedService,
                    "Total Completed Service Request": el.totalCompletedService,
                    "Total Route Worked": el.totalNumberRoute
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'supervisor_report(' + date + ').xlsx')
            this.sheets = [];
        },
        validateFilter: async function () {
            try {
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getSupervisorList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                joiningDate: '',
                email: ''
            }
            await this.getSupervisorList();
        },
    }
}