import { authHeader } from "../../../auth";
import { reactive } from 'vue';
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      options: reactive({
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        eventClick: this.handleDateClick,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay,listDay'
        },
        editable: true,
        selectable: true,
        select: this.handleDateSelect,
        weekends: true,
        dayMaxEvents: true,
        longPressDelay: 0.1,
        events: [{ title: '', date: ''}],
        eventColor: '#7e0000',        
        eventsSet: this.handleEvents
      }),
      currentEvents: [],
      isAppointmentList: false,
      page: 1,
      limit: 10,
      totalPage: 0,
      orderList: [],
      eventTitle: "",
      selectedDate: "",
      eventTime: "",
      eventModal: '',
      eventId: '',
      reserveList: [],
      calenderList: []
    }
  },
  async mounted() {
    await this.getCalenderData();
    await this.getReserveList();
  },
  methods: {
    getCalenderData: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.calender.calenderURL,
        headers: authHeader()
      };
      await this.$axios(config).then((response) => {
        if (response.status === 200) {
          const events = response.data.data.map(el => {
            return {
              title: el.label,
              date: this.dateFormat(el.date),
              start: el.date,
              id: el.id
            }
          });

          this.calenderList = [...events];
          this.options.events = [...this.reserveList, ...events];
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getReserveList: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.reservation.reservationURL,
        headers: authHeader()
      };
      await this.$axios(config).then((response) => {
        if (response.status == 200) {
          const data = response.data.data;
          const events = data.map(el => {
            return {
              title: el.reserveId,
              date: this.dateFormat(el.serviceRequestDate),
              start: el.serviceRequestDate
            }
          });

          this.reserveList = [...events];
          this.options.events = [...events, ...this.calenderList];
        }
      }).catch(error => {
        console.log(error)
      });
    },
    handleDateSelect(selectInfo) {
      this.selectedDate = selectInfo.startStr;
      this.eventModal = "Add";
      const eventModal = this.$refs.addEventModal;
      eventModal.style.display = "block";
    },
    handleAddEventAndMeeting: function() {
      const eventModal = this.$refs.addInitailEventModal;
      eventModal.style.display = "block";
    },
    closeModal() {
      const eventModal = this.$refs.addEventModal;
      const eventModalOne = this.$refs.addInitailEventModal;
      eventModal.style.display = "none";
      eventModalOne.style.display = "none";
      this.eventTitle = "";
      this.eventTime = "";
      this.selectedDate = "";
    },    
    closeEventOptionModal() {
      const editOrDeleteModal = this.$refs.editOrDeleteModal;
      editOrDeleteModal.style.display = "none";
    },    
    handleDateClick: function (arg) {
      if (!arg.event.id) {
        this.$swal.fire({
          icon: "info",
          title: `Title: ${arg.event.title}
          Time: ${this.timeFormat(arg.event.startStr)}`
        })
      } else {
        this.eventModal = "Edit";
        this.eventTitle = arg.event.title;
        this.selectedDate = this.dateFormat(arg.event.startStr);
        const time = arg.event.startStr.split('T')[1].split(":");
        this.eventTime = { hours: +time[0], minutes: +time[1], seconds: 0 };
        this.eventId = arg.event.id;

        const eventModal = this.$refs.editOrDeleteModal;
        eventModal.style.display = "block";
      }
    },
    editEventModalOpen: function () {
      const eventAddDeleteModal = this.$refs.editOrDeleteModal;
      eventAddDeleteModal.style.display = "none";
      const eventModal = this.$refs.addEventModal;
      eventModal.style.display = "block";
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    dateFormat(dateTime) {
      dateTime = new Date(dateTime);
      return dateTime.toISOString().split('T')[0];
    },
    timeFormat(dateTime) {
      dateTime = new Date(dateTime);
      const time = dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      return `${time}`;
    },
    createEvent: async function () {
      if (!this.eventTitle) {
        this.$swal.fire({
          icon: "error",
          text: "Please insert event title."
        })
      } else if (!this.eventTime) {
        this.$swal.fire({
          icon: "error",
          text: "Please select event time."
        })
      }  else {
        try {

          const newDate = new Date(this.selectedDate);
          newDate.setHours(this.eventTime.hours);
          newDate.setMinutes(this.eventTime.minutes);
          newDate.setSeconds(this.eventTime.seconds);

          this.$swal.showLoading();
          let data = {
            label: this.eventTitle,
            date: newDate,
          }

          let config = {
            method: "POST",
            url: this.$serverURL + this.$api.calender.calenderURL,
            data: data,
            headers: authHeader()
          };
          await this.$axios(config).then((response) => {
            this.$swal.hideLoading();
            if (response.data.statusCode == 200 || response.data.statusCode == 201) {
              this.$swal.fire({
                icon: 'success',
                text: response.data.message
              })
            }

          }).catch(error => {
            this.$swal.hideLoading();
            console.log(error);
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message
            });
          });

          this.eventTitle = "";
          this.eventTime = "";

          await this.getCalenderData();

          const eventModal = this.$refs.addEventModal;
          eventModal.style.display = "none";


        } catch (error) {
          this.$swal.hideLoading();
          console.log(error)
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again."
          })
        }
      }
    },

    createEventAndMeeting: async function () {
      if (!this.eventTitle) {
        this.$swal.fire({
          icon: "error",
          text: "Please insert event title."
        })
      } else if (!this.selectedDate) {
        this.$swal.fire({
          icon: "error",
          text: "Please select event date."
        })
      } else {
        try {

          const newDate = new Date(this.selectedDate);

          this.$swal.showLoading();
          let data = {
            label: this.eventTitle,
            date: newDate,
          }

          let config = {
            method: "POST",
            url: this.$serverURL + this.$api.calender.calenderURL,
            data: data,
            headers: authHeader()
          };
          await this.$axios(config).then((response) => {
            this.$swal.hideLoading();
            if (response.data.statusCode == 200) {
              this.$swal.fire({
                icon: 'success',
                text: response.data.message
              })
            }

          }).catch(error => {
            this.$swal.hideLoading();
            console.log(error);
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message
            });
          });

          this.eventTitle = "";
          this.eventTime = "";

          await this.getCalenderData();

          const eventModal = this.$refs.addInitailEventModal;
          eventModal.style.display = "none";


        } catch (error) {
          this.$swal.hideLoading();
          console.log(error)
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again."
          })
        }
      }
    },

    editEvent: async function () {
      if (!this.eventTitle) {
        this.$swal.fire({
          icon: "error",
          text: "Please insert event title."
        })
      } else if (!this.eventTime) {
        this.$swal.fire({
          icon: "error",
          text: "Please select event time."
        })
      }  else {
        try {

          const newDate = new Date(this.selectedDate);
          newDate.setHours(this.eventTime.hours);
          newDate.setMinutes(this.eventTime.minutes);
          newDate.setSeconds(this.eventTime.seconds);

          this.$swal.showLoading();
          let data = {
            label: this.eventTitle,
            date: newDate,
          }

          let config = {
            method: "PATCH",
            url: this.$serverURL + this.$api.calender.calenderURL + this.eventId,
            data: data,
            headers: authHeader()
          };
          await this.$axios(config).then((response) => {
            this.$swal.hideLoading();
            if (response.data.statusCode == 200) {
              this.$swal.fire({
                icon: 'success',
                text: response.data.message
              })
            }

          }).catch(error => {
            this.$swal.hideLoading();
            console.log(error);
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message
            });
          });

          this.eventTitle = "";
          this.eventTime = "";

          await this.getCalenderData();

          const eventModal = this.$refs.addEventModal;
          eventModal.style.display = "none";


        } catch (error) {
          this.$swal.hideLoading();
          console.log(error)
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again."
          })
        }
      }
    },
    deleteEvent: async function () {
      if (this.eventId) {
        try {

          this.$swal.showLoading();

          let config = {
            method: "DELETE",
            url: this.$serverURL + this.$api.calender.calenderURL + this.eventId,
            headers: authHeader()
          };
          await this.$axios(config).then((response) => {
            this.$swal.hideLoading();
            if (response.data.statusCode == 200) {
              this.$swal.fire({
                icon: 'success',
                text: response.data.message
              })
            }

          }).catch(error => {
            this.$swal.hideLoading();
            console.log(error);
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message
            });
          });

          await this.getCalenderData();

          const eventModal = this.$refs.editOrDeleteModal;
          eventModal.style.display = "none";

        } catch (error) {
          this.$swal.hideLoading();
          console.log(error)
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again."
          })
        }
      }
    },
  }
}
