<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Add Route
                        </li>
                    </ol>
                </nav>
            </div>
            <h6 class="mb-0 text-uppercase">Add Route</h6>
            <hr/>
            <div class="row">
                <div class="col-sm">
                    <div class="card">
                        <div class="card-body">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <label class="form-label">Route Name:<span style="color: red; font-size: 18px">*</span></label>
                                        <input class="form-control mb-1" type="text" v-model="routeName"/>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Start Point:<span style="color: red; font-size: 18px">*</span></label>
                                        <GMapAutocomplete @place_changed="setStartPoint" :class="'form-control'"></GMapAutocomplete>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">End Point:<span style="color: red; font-size: 18px">*</span></label>
                                        <GMapAutocomplete @place_changed="setEndPoint" :class="'form-control'"></GMapAutocomplete>
                                    </div>
                                    <div class="modal-body">
                                        <button type="button" @click="addRoute()" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/add-route.js'></script>

<style scoped>
    @import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    @import "../css/add-route.css";
</style>