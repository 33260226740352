import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "AddRoute",
    data() {
        return {
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                height: '800px',
            },
            setUrl: this.$serverURL + this.$api.crew.duties
        }
    },
    async created() {
        document.title = "Land Scape - Duties & Responsibilities";
        await this.getDuties();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        async getDuties() {
            let config = {
                method: "GET",
                url: this.setUrl
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editorData = response.data.data.length ? response.data.data[0].DutiesAndResponsibilities : "";
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        saveDuties: async function () {
            if(!this.editorData){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter duties & responsibilities."
                });
            }
            else {
                let data = {
                    DutiesAndResponsibilities: this.editorData,
                };
                let config = {
                    method: "POST",
                    url: this.setUrl,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201 || response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.message
                        });
                    }
                    this.editorData = '';
                    this.getDuties();
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}