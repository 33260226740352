<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Crew Home
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end breadcrumb-->
      <!-- <div v-if="shiftFlag == false" class="alert alert-primary" role="alert">
        You have already completed your shift.
      </div>
      <center v-if="startProcess == false">
        <div class="devs" id="dev">
          <button
            href="javascript:void(0)"
            class="des1"
            @click="checkShiftStatus"
            :disabled="disableButton"
          >
            Start Process
          </button>
        </div>
      </center> -->

      <center v-if="startProcess == false">
        <div class="card" style="width: 80%">
          <div class="card-header">
            <p>
              {{ !shiftFlag && showDuties ? 'Duties and Responsibilities' : 'SHIFT CONTROL' }}
            </p>
          </div>
          <div class="card-body">
            <div
              v-if="shiftFlag == true"
              class="alert alert-primary"
              role="alert"
            >
              You have already completed your shift for today.
            </div>
            <div v-else-if="!showDuties" class="devs">
              <button                
                class="des1"
                @click="showDuties = true"
                :disabled="disableButton"
              >
                Start Shift
              </button>
            </div>
            <div v-else>
              <div v-html="duties"></div>
              <div class="d-flex p-2 mt-4">
                <input
                  class="me-3"
                  type="checkbox"
                  @change="checkDutiesFlag = !checkDutiesFlag"
                />
                <p class="m-0"><strong>I Understand</strong></p>
              </div>
            </div>
          </div>
          <div v-if="showDuties" class="card-footer" style="text-align: right">
            <button
            :class="disableButton || !checkDutiesFlag ? 'btn btn-danger' : 'btn btn-success'"
              :disabled="disableButton || !checkDutiesFlag"
              @click="checkProcessAndStart"
            >
              Start Shift
            </button>
          </div>
        </div>
      </center>

      <div v-if="startProcess && !endButton">
        <br />
        <div class="card">
          <div
            class="card-header"
            style="padding: 15px; font-size: 20px; background-color: #f4f4f4"
          >
            Check List
          </div>
          <div class="alert alert-warning" role="alert" style="font-size: 16px">
            <i class="fa-solid fa-triangle-exclamation"></i>&ensp;Please
            complete all the tasks in the checklist to proceed
          </div>
          <div class="card-body">
            <div class="card-inbox">
              <div class="item" v-for="(data, index) in dataList" :key="index">
                <input
                  type="checkbox"
                  v-model="checkList[index]"
                  @change="verifyCheckList"
                />
                <p>{{ data.name }}</p>
              </div>
            </div>
          </div>
          <div class="card-footer" style="text-align: right">
            <button
              type="button"
              :class="buttonFlag ? 'btn btn-success' : 'btn btn-danger'"
              @click="startShift"
              :disabled="!buttonFlag"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>

      <center v-if="endButton">
        <div class="card" style="width: 80%">
          <div class="card-header">
            <p>
              SHIFT CONTROL
            </p>
          </div>
          <div class="card-body">
            <div class="alert alert-primary" role="alert">End your shift</div>
            <div class="devs" id="dev">
              <button href="javascript:void(0)" class="des1" @click="endShift">
                End Shift
              </button>
            </div>
          </div>
          <div class="card-footer text-muted"></div>
        </div>
      </center>
      <!-- <div class="card" style="height: 500px" v-if="endButton">
        <div
          class="card-header"
          style="padding: 15px; font-size: 20px; background-color: #f4f4f4"
        >
          End your shift
        </div>
        <div class="card-body" style="margin: 0 auto; float: none">
          <div class="card-inbox">
            <a href="javascript:void(0)" @click="endShift" class="end-shift-btn"
              ><strong style="font-size: 20px">End Shift</strong></a
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script src="../js/crew-home.js"></script>


<style scoped>
@import "../css/crew-home.css";
</style>