import { io } from "socket.io-client";
export default {
    name: "Message",
    data() {
        return {
            socket: io(this.$serverURL),
            messages: [],
            messageText: "",
            userName: localStorage?.getItem('fullName'),
            userId: localStorage?.getItem('id'),
            role: localStorage?.getItem('role'),
            timeout: 0,
            roles: {
                2: 'admin',
                4: 'crew',
                5: 'supervisor'
            }
        }
    },
    created() {
        this.getAllMessage();
        this.getMessage();
    },
    methods: {
        getAllMessage() {
            this.socket.emit('findAllMessage', {}, (res) => {
                this.messages = res;
            });
        },
        getMessage() {
            this.socket.on('message', (message) => {
                this.messages.push(message);
            })
        },
        sendMessage() {
            this.socket.emit(
                'createMessage',
                { userId: this.userId, userName: this.userName, text: this.messageText, role: this.role },
                () => { this.messageText = ""; }
            )
        },
        dateTimeFormat(dateTime) {
            dateTime = new Date(dateTime);
            const date = dateTime.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
            const time = dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            return `${date} ${time}`;
        }
        // emitTyping() {
        //     this.socket.emit('typing', {isTyping: true});
        //     this.timeout = setTimeout(() => {
        //         this.socket.emit('typing', {isTyping: false});
        //     }, 2000);
        // }
    }
}