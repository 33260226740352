<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Add Service
                        </li>
                    </ol>
                </nav>
            </div>
            <h6 class="mb-0 text-uppercase">Add Service</h6>
            <hr/>
            <form @submit.prevent="addService()">
                <div class="row">
                    <div class="col-sm">
                        <div class="card">
                            <div class="card-body">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <label class="form-label">Name:<span style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-1" type="text" v-model="serviceName"/>
                                        </div>
                                        <div class="modal-body">
                                            <label class="form-label">Featured Image:<span style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-3" type="file" ref="featuredImage" @change="uploadFeaturedImage($event)"/>
                                            <img v-if="previewFeaturedImage" :src="previewFeaturedImage" height="113" width="200"/>
                                        </div>
                                        <div class="modal-body">
                                            <label class="form-label">Features:</label>
                                            <div v-for="(data, index) in addFeaturesList" :key="index">
                                                <input v-model="featureList[index]" class="form-control mb-2" type="text"/>
                                                <a v-if="addFeaturesList && addFeaturesList.length > 1" href="javascript:void(0)" class="close-btn" @click="removeFeature(index)">
                                                    <i class="fa-solid fa-circle-xmark"></i>
                                                </a>
                                            </div>
                                            <a href="javascript:void(0)" class="btn btn-success btn-sm" @click="addFeatures()">
                                                <i class="fa-solid fa-plus" style="margin: auto"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="card">
                            <div class="card-body">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <label class="form-label">Price:<span style="color: red; font-size: 18px">*</span></label>
                                            <input class="form-control mb-1" type="number" v-model="servicePrice"/>
                                        </div>
                                        <!-- <div class="modal-body">
                                            <label class="form-label">Status:<span style="color: red; font-size: 18px">*</span></label>
                                            <select class="form-control" v-model="serviceStatus">
                                                <option style="display: none" selected value="">
                                                    Select Status
                                                </option>
                                                <option>Pending</option>
                                                <option>Paused</option>
                                                <option>Completed</option>
                                            </select>
                                        </div> -->
                                        <div class="modal-body">
                                            <label class="form-label">Details:</label>
                                            <textarea class="form-control" rows="6" v-model="serviceDetails"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-grid">
                                <button type="submit" class="btn btn-primary">Save Service</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script src='../js/add-service.js'></script>

<style scoped>
    @import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    @import "../css/add-service.css";
</style>