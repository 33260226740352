<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Calender
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="handleAddEventAndMeeting"
            >
              <i class="bx bxs-plus-square"></i>Add Event and Meeting
            </button>            
          </div>
          <!-- Add Modal -->
          <div
            class="modal fade show"
            id="addInitailEventModal"
            ref="addInitailEventModal"
            tabindex="-1"
            aria-labelledby="addModalLabelI"
            aria-hidden="true"
          >
            <form>
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabelI">Add Event and Meeting</h5>
                    <button
                      type="button"
                      id="closeButton"
                      @click="closeModal"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label"
                      >Event Title:<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="eventTitle"
                      placeholder=""
                    />
                    <div class="form-group row">
                      <span>
                        <p style="font-weight: 600">
                          Event Date:<span style="color: red; font-size: 18px">*</span>
                        </p>
                        <Datepicker
                          v-model="selectedDate"
                          placeholder="Event date"
                          :minDate="new Date()"
                          :is-24="false"
                      /></span>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                      @click="closeModal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      @click="createEventAndMeeting"
                      class="btn btn-primary"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
        </div>
      </div>
      <hr />
      <div class="card p-4">
        <div class="custom_calender">
          <FullCalendar :options="options" />
        </div>
      </div>
    </div>
  </div>
  <!-- Add/Edit Modal -->
  <div
    class="modal fade show"
    id="addEventModal"
    ref="addEventModal"
    tabindex="-1"
    aria-labelledby="addModalLabel"
    aria-hidden="true"
  >
    <form>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLabel">{{ eventModal }} Event <span class="font-14">({{ selectedDate }})</span></h5>
            <button
              type="button"
              id="closeButton"
              @click="closeModal"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <label class="form-label"
              >Event Title:<span style="color: red; font-size: 18px"
                >*</span
              ></label
            >
            <input
              class="form-control mb-1"
              type="text"
              v-model="eventTitle"
              placeholder=""
            />
          </div>
          <div class="row">
            <div class="col-12">
              <div class="modal-body">
                <div class="form-group row">
                <span>
                  <p style="font-weight: 600">
                    Event Time:<span style="color: red; font-size: 18px">*</span>
                  </p>
                  <Datepicker
                    v-model="eventTime"
                    placeholder="Start Time"
                    :is-24="false"
                    time-picker
                /></span>
              </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closeModal"
            >
              Close
            </button>
            <button
              v-if="eventModal == 'Add'"
              type="button"
              @click="createEvent"
              class="btn btn-primary"
            >
              Add
            </button>
            <button
              v-if="eventModal == 'Edit'"
              type="button"
              @click="editEvent"
              class="btn btn-primary"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- Add/Edit Modal -->
  <!-- Check Edit Or Delete Modal -->
  <div
    class="modal fade show"
    id="editOrDeleteModal"
    ref="editOrDeleteModal"
    tabindex="-1"
    aria-labelledby="addModalLabell"
    aria-hidden="true"
  >
    <form>
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLabell">Choose your option</h5>
            <button
              type="button"
              id="closeButton"
              @click="closeEventOptionModal"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body d-flex justify-content-around">
            <button
              type="button"
              @click="editEventModalOpen"
              class="btn btn-info"
            >
              Edit
            </button>
            <button
              type="button"
              @click="deleteEvent"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- Check Edit Or Delete  Modal -->
</template>
  
<script src="../js/calendar.js"></script>

<style scoped>
@import "~@fullcalendar/list/main.min.css";

.custom_calender a {
  color: #7e0000 !important;
}

@media only screen and (max-width: 575px) {
  .custom_calender a {
    font-size: 10px !important;
  }
}
</style>
  