import Loading from "vue-loading-overlay";
import {authHeader} from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "TermsAndConditions",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            title: null,
            description: null,
            subTitle: null,
            buttonText: null,
            buttonLink: null,
            iconFile: null,
            icon: null,
            previewIcon: null,
            backgroundImage: null,
            previewImage: null,
            imageFile: null,
            editor: ClassicEditor
        }
    },
    async created() {
        document.title = "Land Scape - Terms and Conditions";
        await this.getCmsData();
    },
    methods: {
        getCmsData: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.policy.termsAndConditionURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if(!response.data.data[0]) {
                        this.dataList = [];
                    }
                    else {
                        this.dataList = response.data.data[0];
                        this.title = this.dataList.title;
                        this.subTitle = this.dataList.subTitle;
                        this.buttonText = this.dataList.buttonText;
                        this.buttonLink = this.dataList.buttonLink;
                        this.icon = this.dataList.icon;
                        this.backgroundImage = this.dataList.backgroundImage;
                        this.description = this.dataList.description;
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onIconChange: function (event) {
            this.iconFile = event.target.files[0];
            let input = this.$refs.iconFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewIcon = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("title", this.title);
            formData.append("subTitle", this.subTitle);
            formData.append("buttonText", this.buttonText);
            formData.append("buttonLink", this.buttonLink);
            formData.append("icon", this.iconFile ? this.iconFile : this.icon);
            formData.append("backgroundImage", this.imageFile ? this.imageFile : this.backgroundImage);
            formData.append("description", this.description);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.policy.termsAndConditionURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                    this.$refs.iconFile.value = null;
                    this.$refs.imageFile.value = null;
                }
                this.isLoading = false;
                this.getCmsData();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!" + " " + error
                });
            });
        }
    }
}
