import { authHeader } from "../../../auth";
export default {
    name: "ImportProduct",
    data() {
        return {
            crewList: [],
            dumpID: null
        }
    },
    async created() {
        document.title = "Landscape -Import Crew";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        excelImport: async function (event) {
            let excelFile = await event.target.children[0].files ? event.target.children[0].files[0] : null;
            if (!excelFile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please choose file!"
                });
            }
            else {
                this.$swal.showLoading();
                const formData = new FormData();
                formData.append('excelFile', excelFile);
                const headers = { 'Content-Type': 'multipart/form-data' };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.user.crewImport,
                    header: headers,
                    data: formData
                };

                await this.$axios(config).then((response) => {
                    this.$swal.hideLoading();
                    if (response.data.statusCode == 200) {
                        this.crewList = response.data.crew_dump.dump;
                        this.dumpID = response.data.crew_dump.dumpID
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.$refs.resetForm.reset();
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        confirmExcelImport: async function () {
            if (!this.dumpID) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Upload file again!"
                });
            } else {
                try {
                    this.$swal.showLoading();
                    let data = {
                        dumpID: this.dumpID
                    }
                    let config = {
                        method: "POST",
                        url: this.$serverURL + this.$api.user.confirmCrewImport,
                        data: data,
                        header: authHeader()
                    };

                    await this.$axios(config).then((response) => {
                        this.$swal.hideLoading();
                        if (response.data.statusCode == 200) {
                            this.crewList = [];
                            this.dumpID = null;
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                            this.$router.push('/crew-list')
                        }
                    }).catch(error => {
                        this.$swal.hideLoading();
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message,
                        });
                    });
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message,
                    });
                }
            }
        },
    }
}
