import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Admin",
    data() {
        return {
            routeName: '',
            startPoint: '',
            endPoint: '',
            startLatitude: '',
            endLatitude: '',
            startLongitude: '',
            endLongitude: '',
            singleRoute: {},
            dataList: [],
            sheets: [],
            filter: {
                joiningDate: ''
            }
        }
    },
    async created() {
        document.title = "Land Scape - Routes";
        this.getDataList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getDataList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.report.routeReport,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        addRoute: async function () {
            this.isLoading = true;
            if(!this.routeName){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Route name!"
                });
            }else if(!this.startPoint){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter start point!"
                });
            }
            else if(!this.endPoint) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter end point!"
                });
            }
            else if(!this.startLatitude) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter start latitude!"
                });
            }
            else if(!this.startLongitude) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter start longitude!"
                });
            }
            else if(!this.endLatitude) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter end latitude!"
                });
            }
            else if(!this.endLongitude) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter end longitude!"
                });
            }
            else {
                let data = {
                    routeName: this.routeName,
                    startPoint: this.startPoint,
                    endPoint: this.endPoint,
                    startLatitude: this.startLatitude,
                    startLongitude: this.startLongitude,
                    endLatitude: this.endLatitude,
                    endLongitude: this.endLongitude
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.route.routeAPI,
                    data: data,
                    headers:  authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully stored data!"
                        });
                    }
                    this.routeName = '';
                    this.startPoint = '';
                    this.endPoint = '';
                    this.startLatitude = '';
                    this.startLongitude = '';
                    this.endLatitude = '';
                    this.endLongitude = '';
                    this.getDataList();
                    document.getElementById("closeButton").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        editRoute: function(data) {
            this.singleRoute = data;
        },
        updateRoute: async function() {
            this.isLoading = true;
            if(!this.singleRoute.routeName){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Route name!"
                });
            }
            if(!this.singleRoute.startPoint){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter start point!"
                });
            }
            else if(!this.singleRoute.endPoint) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter end point!"
                });
            }
            else if(!this.singleRoute.startLatitude) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter start latitude!"
                });
            }
            else if(!this.singleRoute.startLongitude) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter start longitude!"
                });
            }
            else if(!this.singleRoute.endLatitude) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter end latitude!"
                });
            }
            else if(!this.singleRoute.endLongitude) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter end longitude!"
                });
            }
            else {
                let data = {
                    routeName: this.singleRoute.routeName,
                    startPoint: this.singleRoute.startPoint,
                    endPoint: this.singleRoute.endPoint,
                    startLatitude: this.singleRoute.startLatitude,
                    startLongitude: this.singleRoute.startLongitude,
                    endLatitude: this.singleRoute.endLatitude,
                    endLongitude: this.singleRoute.endLongitude
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.route.singleRoute + this.singleRoute.id,
                    data: data,
                    headers:  authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                    }
                    this.startPoint = '';
                    this.endPoint = '';
                    this.startLatitude = '';
                    this.startLongitude = '';
                    this.endLatitude = '';
                    this.endLongitude = '';
                    this.getDataList();
                    document.getElementById("closeButton").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },

        deleteRoute: async function(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.route.singleRoute + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList();

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        exportRoutes: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Route Name": el.routeName,
                    "Total Service Request": el.totalService,
                    "Total Supervisor Assigned": el.totalSupervisor,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'route_report(' + date + ').xlsx');
            this.sheets = [];
        },

    }
}