import { authHeader } from "../../../auth";
import { countries } from "../../../../config/country";
export default {
    name: "UpdateBilling",
    data() {
        return {
            countries: countries,
            countryList: [],
            customerList: [],
            billingData : {},
            billingCountry: "",
            addressOne: "",
            addressTwo: "",
            userType: "customer",
            id: "",
            customer: {}
        }
    },
    async created() {
        document.title = "Land Scape - Update Billing";
        this.countryList = this.countries.map(el => {
            return {value:{ name: el.countryNameEn, code: el.countryCode }, label: el.countryNameEn}
        })
        this.id = this.$route.params.id;
        await this.getCustomerList();
        await this.getBillingData();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getCustomerList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getUsersListURL + "?userType=" + this.userType,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.customerList = response.data.data.map(el => {
                        return { value: {id:el.id, name: el.fullName}, label: el.fullName }
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        getBillingData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.billingURL + '/' + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.billingData = response.data.data;
                    this.customer = {value:{
                        id: response.data.data.userId,
                        name: response.data.data.userName
                    }, label: response.data.data.userName};
                    this.billingCountry = {value:{
                        name: response.data.data.country,
                        code: response.data.data.countryCode
                    }, label: response.data.data.country}
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        validate: function() {
            if(!this.customer.value.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select a customer!"
                });
                return false;
            }
            if(!this.billingData.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter billing name!"
                });
                return false;
            }
            if(!this.billingData.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!"
                });
                return false;
            }
            if(!this.billingData.mobile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter contact no!"
                });
                return false;
            }
            if(!this.billingData.stateCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter state code!"
                });
                return false;
            }
            if(!this.billingData.postCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter postal code!"
                });
                return false;
            }
            if(!this.billingData.city) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter city!"
                });
                return false;
            }
            if(!this.billingCountry.value.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select country!"
                });
                return false;
            }
            if(!this.billingData.addressOne) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter address1!"
                });
                return false;
            }
            return true;
        },

        updateBilling: async function () {
            if(this.validate() == true) {
                let token = localStorage.getItem('token');
                let data = {
                    name: this.billingData.name,
                    email: this.billingData.email,
                    mobile: this.billingData.mobile,
                    city: this.billingData.city,
                    stateCode: this.billingData.stateCode,
                    postCode: this.billingData.email,
                    country: this.billingCountry.value.name,
                    countryCode: this.billingCountry.value.code,
                    addressOne: this.billingData.addressOne,
                    addressTwo: this.billingData.addressTwo,
                    userId: this.customer.value.id,
                    userName: this.customer.value.name
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.user.billingURL,
                    data: data,
                    headers: {
                        "Authorization": "Bearer " + token,
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.data.statusCode == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/billing-information");
                    }
                    //this.serviceStatus = null;
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        removeFeature: async function(index) {
            this.addFeaturesList.splice(index, 1);
            this.featureList.splice(index, 1);
        }
    }
}