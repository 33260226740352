<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Update Payment
                        </li>
                    </ol>
                </nav>
            </div>
            <hr />
            <form @submit.prevent="updatePayment()">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Update Payment</h5>
                        <hr />
                        <div class="form-body mt-4">
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <div class="border border-3 p-4 rounded mb-3">
                                        <div class="col">
                                            <label class="form-label">Select Customer:
                                                <span style="color: red; font-size: 18px">*</span>
                                            </label>
                                            <v-select
                                                :options="customerList"
                                                v-model="customer"
                                                class="mb-1"
                                                placeholder="Select customer"
                                            ></v-select>
                                        </div>
                                        <div class="col">
                                            <label class="form-label">Select Service:
                                                <span style="color: red; font-size: 18px">*</span>
                                            </label>
                                            <v-select
                                                :options="serviceList"
                                                v-model="service"
                                                class="mb-1"
                                                placeholder="Select service"
                                            ></v-select>
                                        </div>
                                        <div class="col">
                                            <label class="form-label">Price:
                                                <span style="color: red; font-size: 18px">*</span>
                                            </label>
                                            <input class="form-control mb-1" type="number" v-model="price" placeholder="Enter price"/>
                                        </div>
                                        <div class="col">
                                            <label class="form-label">Select Payment Type:
                                                <span style="color: red; font-size: 18px">*</span>
                                            </label>
                                            <v-select
                                                :options="priceTypeList"
                                                v-model="priceType"
                                                class="mb-1"
                                                placeholder="Select service"
                                            ></v-select>
                                        </div>
                                        <div class="col">
                                            <label class="form-label">Date Time:
                                                <span style="color: red; font-size: 18px">*</span>
                                            </label>
                                            <Datepicker
                                                class="mb-1"
                                                v-model="date"
                                                placeholder="Select date"
                                                :is-24="false"
                                            />
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button type="submit" class="btn btn-primary ms-2">
                                            Update Payment
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script src='../js/update-payment.js'></script>
