import { authHeader } from './auth';
import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {

        }
    },
    methods: {
        deleteDataToBackend: async function (config) {
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    return response.data.statusCode;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}