import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Admin",
    data() {
        return {
            shift: {},
            user: {},
            shiftHours:"",
            singlePayroll:{},
        }
    },
    async created() {
        document.title = "Land Scape - Admin";

        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        // getDataList: async function() {
        //     this.isLoading = true;
        //     let config = {
        //         method: "GET",
        //         url: this.$gatewayURL + this.$api.user.getUsersListURL + "?userType=" + this.userType,
        //         headers: authHeader()
        //     };
        //     await this.$axios(config).then((response) => {
        //         if(response.status == 200) {
        //             this.dataList = response.data.data;
        //         }
        //         this.isLoading = false;
        //     }).catch(error => {
        //         console.log(error);
        //         this.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong. Please try again!"
        //         });
        //     });
        // },
        // addAdmin: async function () {
        //     this.isLoading = true;
        //     if (!this.firstName || !this.lastName || !this.email || !this.phone || !this.password || !this.confirmPassword) {
        //         this.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please enter first name, last name, email and password!"
        //         });
        //     }
        //     else if (this.password != this.confirmPassword) {
        //         this.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Password didn't match!"
        //         });
        //     }
        //     else {
        //         let data = {
        //             firstName: this.firstName,
        //             lastName: this.lastName,
        //             email: this.email,
        //             password: this.password,
        //             phone: this.phone,
        //             userType: this.userType
        //         };
        //         let config = {
        //             method: "POST",
        //             url: this.$gatewayURL + this.$api.user.adminRegistrationURL,
        //             data: data,
        //             headers: {
        //                 "Authorization": authHeader()
        //             }
        //         };
        //         await this.$axios(config).then((response) => {
        //             if(response.status == 201) {
        //                 this.isLoading = false;
        //                 this.$swal.fire({
        //                     icon: "success",
        //                     text: "Successfully stored data!"
        //                 });
        //             }
        //             this.firstName = '';
        //             this.lastName = '';
        //             this.email = '';
        //             this.phone = '';
        //             this.userType = 'admin';
        //             this.password = '';
        //             this.confirmPassword = '';
        //             this.inputTypePassword = "password";
        //             this.inputTypeConfirmPassword = "password";
        //             this.getDataList();
        //             document.getElementById("closeButton").click();
        //         }).catch(error => {
        //             console.log(error);
        //             this.isLoading = false;
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: error.response.data.message
        //             });
        //         });
        //     }
        // },
        editPayroll: function(data) {
            this.singleAdmin = data;
        },
        // updateAdmin: async function() {
        //     this.isLoading = true;
        //     if (!this.singleAdmin.firstName || !this.singleAdmin.lastName || !this.singleAdmin.phone) {
        //         this.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please enter first name, last name, phone!"
        //         });
        //     }
        //     else {
        //         let data = {
        //             firstName: this.singleAdmin.firstName,
        //             lastName: this.singleAdmin.lastName,
        //             phone: this.singleAdmin.phone,
        //             userType: this.singleAdmin.userType
        //         };
        //         let config = {
        //             method: "PATCH",
        //             url: this.$gatewayURL + this.$api.user.updateUserURL + this.singleAdmin.id,
        //             data: data,
        //             headers: authHeader()
        //         };
        //         await this.$axios(config).then(async (response) => {
        //             if(response.status == 200) {
        //                 this.$swal.fire({
        //                     icon: "success",
        //                     text: "Successfully updated data!"
        //                 });
        //                 this.singleAdmin = {};
        //                 this.isLoading = false;
        //                 await this.getDataList();
        //             }
        //         }).catch(error => {
        //             console.log(error);
        //             this.isLoading = false;
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Something went wrong. Please try again!"
        //             });
        //         });
        //     }
        // },

        deletePayroll: async function(id) {
            var answer = window.confirm("Are you sure to delete this data?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$gatewayURL + this.$api.user.deleteUserURL + id,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.getDataList();
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong!" + " " + error
                    });
                });
            }
        },

    }
}