<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="row">
        <div class="col-6">
          <h6 class="mb-0 text-uppercase">Service Request List</h6>
        </div>
        <div class="col-6 float-right">
          <!-- <a
            role="button"
            class="btn btn-warning btn-sm m-1 mb-md-0"
            style="float: right"
            @click="exportData()"
          >
            <i class="fa-solid fa-file-export"></i>
            Export
          </a>
          <a
            role="button"
            data-bs-toggle="modal"
            data-bs-target="#filterModal"
            id="filterTable"
            class="btn btn-success btn-sm m-1 mb-md-0"
            style="float: right"
          >
            <i class="bx bx-filter text-white float-left"></i>Filter
          </a> -->
        </div>
      </div>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Service Request Id</th>
                  <th>Route Name</th>
                  <th>Service Request Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.serviceRequestId }}</td>
                  <td>{{ data.routeName }}</td>
                  <td>{{ data.status }}</td>
                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-block px-3 mt-2 me-1"
                        v-on:click="
                          $router.push('/request-details/' + data.requestId)
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                      >
                        <i class="fa-solid fa-eye me-0"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success btn-block px-3 mt-2"
                        v-if="data.status == 'Processing'"
                        @click="updateService(data.id)"
                      >
                        Start
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-block px-3 mt-2 ms-2"
                        v-else
                        :hidden="data.status == 'Completed'"
                        @click="
                          this.$router.push(
                            '/assigned-service-checklist/' + data.id
                          )
                        "
                      >
                        {{ data.status == "Completed" ? "Finished" : "Finish" }}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Service Request Id</th>
                  <th>Route Name</th>
                  <th>Service Request Status</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="filterModal"
        tabindex="-1"
        aria-labelledby="filterModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="filterModalLabel">Assigning</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form class="filter_form" @submit.prevent="validateFilter">
                <div class="row mb-3">
                  <label class="form-label">Add Crew:</label>
                  <div class="col-6 col-sm-6">
                    <v-select
                      multiple
                      :options="userList"
                      @option:selected="selectUser(assignee[idx])"
                      @option:deselected="removeUser(assignee[idx])"
                      v-model="assignee[idx]"
                    >
                    </v-select>
                  </div>
                  <div class="col-6 col-sm-6">
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                        data-bs-dismiss="modal"
                        @click="assignUser"
                      >
                        Assign
                      </button>
                    </div>
                  </div>
                </div>
                <!-- <div class="mb-3">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                                            data-bs-dismiss="modal">
                                            Assign
                                        </button>
                                    </div>
                                </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/assigned-service.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>