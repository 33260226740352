import { authHeader } from "../../../auth";

export default {
    name: "AddPayment",
    data() {
        return {
            price: '',
            date: new Date(),
            customer: {},
            service: {},
            customerList: [],
            serviceList: [],
            priceTypeList: [{value: 'cash', label: 'Cash'}, {value: 'card', label: 'Card'}],
            priceType: "",
            setUrl: this.$serverURL + this.$api.payment.paymentURL,
            userType: "customer"
        }
    },
    async created() {
        document.title = "Land Scape - Add New Payment";
        this.getServiceList();
        this.getCustomerList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        validate: function() {
            if (!this.customer) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please select customer!"
                });
                return false;
            }
            if (!this.service) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please select service request!"
                });
                return false;
            }
            if (!this.price) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter price!"
                });
                return false;
            }
            if (!this.priceType) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please select payment type!"
                });
                return false;
            }
            if (!this.date) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please select date time!"
                });
                return false;
            }

            return true;
        },
        getCustomerList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getUsersListURL + "?userType=" + this.userType,
                headers: authHeader()
            };
            await this.$axios(config).then((res) => {
                if (res.status == 200) {
                    this.customerList = res.data.data.map((el) => {
                        return { value: el.id, label: el.fullName };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getServiceList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.reservation.reservationURL,
                headers: authHeader()
            };
            await this.$axios(config).then((res) => {
                if (res.status == 200) {
                    this.serviceList = res.data.data.map((el) => {
                        return { value: el.id, label: el.reserveId };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        addPayment: async function () {
            this.isLoading = true;
            if(this.validate()) {
                const data = {
                    customerId: this.customer?.value,
                    customerName: this.customer?.label,
                    serviceId: this.service?.value,
                    serviceRequestId: this.service?.label,
                    price: this.price,
                    priceType: this.priceType?.value,
                    date: this.date
                };
                const config = {
                    method: "POST",
                    url: this.setUrl,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                    }
                    this.customer = "",
                    this.service = "",
                    this.price = "",
                    this.paymentType = "",
                    this.date = new Date(),
                    this.$router.push("/payment-list");
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
    }
}