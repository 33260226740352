<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Duties and Responsibilities
            </li>
          </ol>
        </nav>
      </div>
      <hr />
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="card radius-10">
            <div class="card-body">
              <div class="mb-3">
                <div class="row">
                  <div class="col">
                    <label class="form-label">Duties and Responsibilities</label>
                    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-primary ms-2" @click="saveDuties">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/duties-and-responsibilities.js'></script>

<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 400px;
}
</style>