<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Payment List
            </li>
          </ol>
        </nav>
        <div class="ms-auto" v-if="role == 2">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary ms-2"
              @click="$router.push('/add-payment')"
            >
              <i class="bx bxs-plus-square"></i>Add Payment
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Payment List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Customer Name</th>
                  <th>Service Request ID</th>
                  <th>Price</th>
                  <th>Price Type</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paymentList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.customerName }}</td>
                  <td>{{ data.serviceRequestId }}</td>
                  <td>{{ data.price }}</td>
                  <td>{{ data.priceType }}</td>
                  <td>{{ dateTimeFormat(data.date) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <button
                        v-if="role == 2 || !data.employeeName || !data.serviceRequestId"
                        type="button"
                        class="btn btn-outline-success btn-sm m-1"
                        @click="editPayment(data.id)"
                      >
                        <i class="fa-solid fa-pen-to-square me-0"></i>
                      </button>
                      <button
                        v-if="role == 2"
                        type="button"
                        class="btn btn-outline-danger btn-sm m-1"
                        @click="deletePayment(data.id)"
                      >
                        <i class="fa-solid fa-trash-can me-0"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Customer Name</th>
                  <th>Service Request ID</th>
                  <th>Price</th>
                  <th>Price Type</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/payment-list'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>