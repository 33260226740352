<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Expense Report
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <!-- <a
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
              id="filterTable"
              class="btn btn-success m-1 mb-md-0"
              style="float: right"
            >
              <i class="bx bx-filter text-white float-left"></i>Filter
            </a> -->
            <button
              type="button"
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <i class="bx bx-filter text-white float-left"></i>Filter
            </button>
            <button
              type="button"
              class="btn btn-warning ms-2"
              @click="exportExpense"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button>

          </div>
          <!-- filter modal -->
          <div
            class="modal fade"
            id="filterModal"
            tabindex="-1"
            aria-labelledby="filterModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="filterClose"
                  ></button>
                </div>
                <div class="modal-body">
                  <form class="filter_form" @submit.prevent="validateFilter">
                    <div class="row mb-3">
                      <!-- <div class="col-6 col-sm-6 mb-1">
                        <label class="form-label">User Name:</label>
                        <Multiselect
                          v-model="filter.userName"
                          id="inputVendor"
                          :options="userList"
                          :searchable="true"
                        />
                      </div>
                      <div class="col-6 col-sm-6 mb-1">
                        <label class="form-label">Expense Name:</label>
                        <Multiselect
                          v-model="filter.expenseName"
                          id="inputVendor"
                          :options="expenseList"
                          :searchable="true"
                        />
                      </div> -->
                      <div class="col-12 col-sm-12 mb-1">
                        <label class="form-label">Expense Type:</label>
                        <select
                          v-model="filter.category"
                          class="form-select mb-3"
                        >
                          <option style="display: none" selected value="">
                            Select category
                          </option>
                          <option
                            v-for="(row, index) in expenseCategory"
                            :key="index"
                            :value="row.categoryName"
                          >
                            {{ row.categoryName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 col-sm-6">
                        <label class="form-label">Start Date:</label>
                        <input
                          v-model="filter.startDate"
                          type="date"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 col-sm-6">
                        <label class="form-label">End Date:</label>
                        <input
                          v-model="filter.endDate"
                          type="date"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="text-center">
                        <button
                          type="submit"
                          @click="clearFilter()"
                          class="
                            btn btn-sm btn-warning
                            rounded-pill
                            ps-3
                            pe-3
                            m-1
                          "
                          data-bs-dismiss="modal"
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Expense Report List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Expense Type</th>
                  <th>Amount</th>
                  <th>Expense By</th>
                  <th>Expense Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.expenseName }}</td>
                  <td>{{ data.categoryName }}</td>
                  <td>{{ data.totalAmount }}</td>
                  <td>{{ data.expenseBy }}</td>
                  <td>{{ $filters.formatDate(data.expenseDate) }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Amount</th>
                  <th>Expense By</th>
                  <th>Expense Date</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/expense-report.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>