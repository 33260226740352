import { authHeader } from "../../../auth";

export default {
    name: "EmployeeSchedule",
    data() {
        return {
            paymentList: [],
            role: localStorage.getItem('role'),
            setUrl: this.$serverURL + this.$api.payment.paymentURL
        }
    },
    async created() {
        document.title = "Land Scape - Payment List";
        await this.getPaymentList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        async getPaymentList() {
            let config = {
                method: "GET",
                url: this.setUrl,
                headers: authHeader()
            };
            await this.$axios(config).then((res) => {
                if (res.status == 200) {
                    this.paymentList = res.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        editPayment: function (id) {
            this.$router.push(`/update-payment/${id}`);
        },
        deletePayment: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setUrl + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getPaymentList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try again later." + error
                        });
                    }
                }
            })
        },
        dateTimeFormat(dateTime) {
            dateTime = new Date(dateTime);
            const date = dateTime.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
            const time = dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            return `${date} ${time}`;
        }
    }
}