import { authHeader } from "../../../auth";

export default {
    name: "UpdateEmployeeSchedule",
    data() {
        return {
            title: '',
            start: new Date(),
            employee: {},
            service: {},
            employeeList: [],
            serviceList: [],
            role: localStorage.getItem('role'),
            setUrl: this.$serverURL + this.$api.employeeSchedule.employeeScheduleURL,
            id: this.$route.params.id
        }
    },
    async created() {
        document.title = "Land Scape - Update Schedule";
        await this.getEmployeeList();
        await this.getServiceList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    async mounted() {
        if (this.$route.params.id != undefined) {
            await this.fetchEditData();
        }
    },
    methods: {
        async fetchEditData() {
            let config = {
                method: "GET",
                url: this.setUrl + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    const data = response.data.data;
                    this.title = data.title,
                    this.start = data.start,
                    this.employee = { value: data?.employeeId, label: data?.employeeName },
                    this.service = { value: data?.serviceId, label: data?.serviceRequestId }
                }
            }).catch(error => {
                console.log(error);
            });
        },
        validate: function() {
            if (!this.title) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.start) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter date time!"
                });
                return false;
            }

            return true;
        },
        getEmployeeList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.crew.crewURL,
                headers: authHeader()
            };
            await this.$axios(config).then((res) => {
                if (res.status == 200) {
                    this.employeeList = res.data.data.map((el) => {
                        return { value: el.id, label: el.fullName };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getServiceList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.reservation.reservationURL,
                headers: authHeader()
            };
            await this.$axios(config).then((res) => {
                if (res.status == 200) {
                    this.serviceList = res.data.data.map((el) => {
                        return { value: el.id, label: el.reserveId };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        updateSchedule: async function () {
            this.isLoading = true;
            if(this.validate()) {
                const data = {
                    title: this.title,
                    start: this.start,
                    employeeId: this.employee?.value,
                    employeeName: this.employee?.label,
                    serviceId: this.service?.value,
                    serviceRequestId: this.service?.label
                };
                const config = {
                    method: "PATCH",
                    url: this.setUrl + this.id,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                    }
                    this.title = "",
                    this.start = new Date(),
                    this.employee = "",
                    this.service = "",
                    this.$router.push("/employee-schedule");
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
    }
}