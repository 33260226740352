import { authHeader } from "../../../auth";

export default {
    name: "ScheduleList",
    data() {
        return {
            scheduleList: [],
            role: localStorage.getItem('role'),
            id: localStorage.getItem('id'),
            setUrl: this.$serverURL + this.$api.employeeSchedule.scheduleByUserIdURL
        }
    },
    async created() {
        document.title = "Land Scape - Schedule List";
        await this.getScheduleList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        async getScheduleList() {
            let config = {
                method: "GET",
                url: this.setUrl + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then((res) => {
                if (res.status == 200) {
                    this.scheduleList = res.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        dateTimeFormat(dateTime) {
            dateTime = new Date(dateTime);
            const date = dateTime.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
            const time = dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            return `${date} ${time}`;
        }
    }
}