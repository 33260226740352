<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Create Service Request
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end breadcrumb-->

      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Create Service Request</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-sm-6">
                <div class="card radius-10">
                  <div class="card-body">
                    <label for="inputProductTitle" class="form-label"
                      ><strong>Customer Information</strong></label
                    ><br />
                    <label
                      for="inputProductTitle"
                      class="form-label"
                      style="margin-right: 5px"
                      ><strong>Customer Type:</strong></label
                    ><br />
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        v-model="isExistingUser"
                        checked
                        :value="false"
                      />
                      <label class="form-check-label" for="exampleRadios1"
                        >New</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="inlineCheckbox2"
                        v-model="isExistingUser"
                        :value="true"
                      />
                      <label class="form-check-label" for="inlineCheckbox2"
                        >Existing</label
                      >
                    </div>

                    <div class="mb-3" v-if="isExistingUser">
                      <label for="inputProductTitle" class="form-label"
                        >Select Customer</label
                      >
                      <Multiselect
                        v-model="selectedCustomer"
                        id="inputVendor"
                        @select="
                          () => {
                            setCustomerData(selectedCustomer);
                          }
                        "
                        :options="customerList"
                        :searchable="true"
                      />
                    </div>

                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label"
                        >Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductTitle"
                        v-model="customerName"
                        placeholder="Enter name"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label"
                        >Email</label
                      >
                      <input
                        type="email"
                        class="form-control"
                        id="inputProductTitle"
                        v-model="customerEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label"
                        >Contact No</label
                      >
                      <input
                        type="tel"
                        class="form-control"
                        id="inputProductTitle"
                        v-model="customerMobile"
                        placeholder="Enter phone no"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card radius-10">
                  <div class="card-body">
                    <label for="inputProductTitle" class="form-label"><strong>Billing Information</strong></label>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label">Name</label>
                          <input type="text" class="form-control" id="inputProductTitle"
                            v-model="billingData.name" placeholder="Enter name" />
                        </div>
                        <div class="col">
                          <label for="inputProductTitle" class="form-label">Email</label>
                          <input type="email" class="form-control" id="inputProductTitle"
                            v-model="billingData.email" placeholder="Enter email" />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">Contact No</label>
                          <input type="tel" class="form-control" id="inputProductTitle"
                            v-model="billingData.mobile" placeholder="Enter phone no" />
                        </div>
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">City</label>
                          <input type="text" class="form-control" id="inputProductTitle"
                            v-model="billingData.city" placeholder="Enter city" />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">State Code</label>
                          <input type="text" class="form-control" id="inputProductTitle"
                            v-model="billingData.stateCode" placeholder="Enter state code" />
                        </div>
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">Post Code</label>
                          <input type="text" class="form-control" id="inputProductTitle"
                            v-model="billingData.postCode" placeholder="Enter post code" />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label">Country</label>
                          <v-select :options="countryList" v-model="billingCountry"></v-select>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label">Billing Address 1</label>
                          <textarea class="form-control" id="floatingTextarea" v-model="billingData.addressOne"
                            placeholder="Enter street address1"></textarea>
                        </div>
                        <div class="col">
                          <label for="inputProductTitle" class="form-label">Billing Address 2 (Optional)</label>
                          <textarea class="form-control" id="floatingTextarea" v-model="billingData.addressTwo"
                            placeholder="Enter street address2"></textarea>
                        </div>
                      </div>
                    </div>
                    <!-- <div id="chart2"></div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 mb-3">
                <div class="border border-3 p-4 rounded">
                  <div class="row g-3">
                    <div class="invoice-body">
                      <!-- Row start -->
                      <div class="row mb-3">
                        <div class="row">
                          <div class="col-sm">
                            <div class="form-group row">
                              <span>
                                <p style="font-weight: 600">
                                  Select Service Request Date:
                                </p>
                                <Datepicker
                                  v-model="reserveDate"
                                  placeholder="Request date"
                                  :minDate="new Date()"
                                  :is-24="false"
                              /></span>
                            </div>
                          </div>
                          <div class="col-sm">
                            <div class="form-group row">
                              <span>
                                <p style="font-weight: 600">Select Route:</p>
                                <v-select
                                  :options="routeList"
                                  v-model="route"
                                ></v-select>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Row start -->
                      <div class="row mb-3">
                        <div class="row">
                          
                          <div class="col-sm">
                            <div class="form-group row">
                              <span>
                                <p style="font-weight: 600">Service Type:</p>
                                <select class="form-select" v-model="serviceType" @change="getServiceType" >
                                  <option value="reoccurring">Reoccurring</option>
                                  <option value="One time Only">One time Only</option>
                                </select>
                              </span>
                            </div>
                          </div>
                          <div class="col-sm" v-if="isServiceDays">
                            <div class="form-group row">
                              <span>
                                <p style="font-weight: 600">Select which day(s) :</p>
                                <Multiselect mode="tags"
                                  v-model="serviceDays"
                                  :options="serviceDaysList"
                                  :searchable="true"
                                />
                              </span>
                            </div>
                          </div>
                          <div class="col-sm">
                            <div class="form-group row">
                              <span>
                                <p style="font-weight: 600">
                                  Service Start Time:
                                </p>
                                <Datepicker
                                  v-model="serviceStartTime"
                                  placeholder="Start Time"
                                  :is-24="false"
                                  time-picker
                              /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row gutters">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="table-responsive">
                            <table class="table custom-table m-0">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Service</th>
                                  <th scope="col">Service Price</th>
                                  <th scope="col">Service Features</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(data, index) in addMoreList"
                                  :key="index"
                                  :id="index"
                                >
                                  <td scope="row">{{ index + 1 }}</td>
                                  <td scope="row" style="width: 30%">
                                    <v-select
                                      :options="serviceList"
                                      @option:selected="calculateTotal"
                                      v-model="selectedService[index]"
                                    ></v-select>
                                    <!-- <Multiselect
                                      v-model="selectedService[index]"
                                      :options="serviceList"
                                      :searchable="true"
                                      @select="
                                        () => {
                                          calculateTotal();
                                        }
                                      "
                                    /> -->
                                  </td>
                                  <td scope="row" style="width: 30%">
                                    <input
                                      v-if="selectedService[index]"
                                      type="text"
                                      class="form-control"
                                      v-model="
                                        selectedService[index].value
                                          .servicePrice
                                      "
                                      v-on:keyup="calculateTotal"
                                      @change="calculateTotal"
                                    />
                                  </td>
                                  <td scope="row" v-if="selectedService[index]">
                                    {{selectedService[index].value.serviceFeature}}
                                  </td>
                                  <td scope="row">
                                    <button
                                      :disabled="addMoreList.length < 2"
                                      type="button"
                                      class="btn btn-outline-danger btn-sm"
                                      @click="removeFromList(index)"
                                    >
                                      <i
                                        class="fa-solid fa-trash-can"
                                        style="margin: auto"
                                      ></i>
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="3"></td>
                                  <td colspan="1">
                                    <button
                                      type="button"
                                      class="btn btn-outline-primary btn-sm"
                                      style="float: right"
                                      @click="addMore"
                                    >
                                      Add more
                                    </button>
                                  </td>
                                </tr>
                                <tr style="text-align: right">
                                  <td colspan="2"></td>
                                  <td>
                                    <p style="position: relative; top: 8px">
                                      Due Amount<br />
                                    </p>
                                    <p style="position: relative; top: 33px">
                                      Discount<br />
                                    </p>
                                    <p style="position: relative; top: 55px">
                                      Paid Amount<br />
                                    </p>
                                    <h5 class="text-success">
                                      <strong
                                        style="position: relative; top: 60px"
                                        >Grand Total</strong
                                      >
                                    </h5>
                                  </td>
                                  <td>
                                    <span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="$"
                                        v-model="dueAmount"
                                        disabled
                                      /><br />
                                    </span>
                                    <span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="discount"
                                        v-on:keyup="addDiscount"
                                        @change="addDiscount"
                                        placeholder="ex: 10%, 20% or 10, 20"
                                      /><br />
                                    </span>
                                    <span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="$"
                                        v-model="paidAmount"
                                      /><br />
                                    </span>
                                    <h5 class="text-success">
                                      <strong>${{ total }}</strong>
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <button
                            class="btn btn-success"
                            @click="createReservation()"
                          >
                            Confirm Service Request
                          </button>
                        </div>
                      </div>
                      <!-- Row end -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end row-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/create-reserve.js"></script>
