import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Customer",
    data() {
        return {
            fullName: '',
            email: '',
            phone: '',
            position: '',
            userType: 'crew',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            singleCrew: {},
            dataList: [],
            sheets: [],
            supervisor: "",
            supervisorList: [],
            updateSupervisor: "",
            createdDate: '',
            emailList: [],
            uniqueEmailList:[],
            filter: {
                workingDate: '',
                email: ''
            }
        }
    },
    async created() {
        document.title = "Land Scape - Time Clock";
        await this.getTimeClockList();
        window.$('#example').DataTable().destroy();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getTimeClockList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.report.timeClockReport + "?workingDate=" + this.filter.workingDate + "&email=" + this.filter.email,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    if (this.uniqueEmailList.length == 0) {
                        this.emailList = this.dataList.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.email === value.email
                            ))
                        );
                    }
                    if (this.uniqueEmailList.length == 0) {
                        this.uniqueEmailList = this.emailList.map(el => {
                            return { label: el.email, value: el.email }
                        })
                    }

                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        exportUsers: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Crew Name": el.name,
                    "Email": el.email,
                    "Working Date": el.date,
                    "Start Time": el.startTime,
                    "End Time": el.endTime,
                    "Working Hours": el.totalHour,
                    "User Type": el.userType,
                    "Current Status": el.status,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'tile_clock_report(' + date + ').xlsx')
            this.sheets = [];
        },
        // filterCustomer: async function () {
        //     document.getElementById("closeFilter").click();
        //     if (this.createdDate) {
        //         window.$('#example').DataTable().destroy();
        //         let config = {
        //             method: "GET",
        //             url: this.$serverURL + this.$api.user.getUsersListURL + "?userType=" + this.userType + "&createdDate=" + this.createdDate,
        //             headers: authHeader()
        //         };
        //         await this.$axios(config).then((response) => {
        //             if (response.status == 200) {
        //                 this.dataList = response.data.data;
        //                 this.createdDate = '';
        //             }
        //             this.isLoading = false;
        //         }).catch(error => {
        //             console.log(error);
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Something went wrong. Please try again!"
        //             });
        //         });
        //         window.$('#example').DataTable();
        //     }
        //     else {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please provide joining date!"
        //         });
        //     }
        // }
        validateFilter: async function () {
            try {
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getTimeClockList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                workingDate: '',
                email: ''
            }
            await this.getTimeClockList();
        },
    }
}