import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Admin",
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            userType: 'admin',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            singleAdmin: {},
            dataList: [],
            sheets: [],
            id: localStorage.getItem('id')
        }
    },
    async created() {
        document.title = "Land Scape - Admin";
        await this.getDataList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getDataList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.admin.adminURL + '?userType=' + this.userType,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        addAdmin: async function () {
            this.isLoading = true;
            if (!this.name) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide name"
                });
            } else if (!this.email) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide email."
                });
            } else if (!this.phone) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide phone number."
                });
            } else if (!this.password) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Provide password"
                });
            } else if (!this.confirmPassword) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Provide Confirm Password"
                });
            } else if (this.password != this.confirmPassword) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Password didn't match!"
                });
            }
            else {
                let data = {
                    fullName: this.name,
                    email: this.email,
                    password: this.password,
                    phone: this.phone,
                    userType: this.userType
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.admin.adminURL,
                    data: data,
                    headers: authHeader(),
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                    }
                    this.name = '';
                    this.email = '';
                    this.phone = '';
                    this.password = '';
                    this.confirmPassword = '';
                    this.inputTypePassword = "password";
                    this.inputTypeConfirmPassword = "password";
                    this.getDataList();
                    document.getElementById("closeButton").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        editAdmin: function (data) {
            this.singleAdmin = data;
        },
        updateAdmin: async function () {
            this.isLoading = true;
            if (!this.singleAdmin.fullName) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide name."
                });
            } else if (!this.singleAdmin.phone) {

            } else {
                let data = {
                    fullName: this.singleAdmin.fullName,
                    phone: this.singleAdmin.phone,
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.admin.adminURL + '/' + this.singleAdmin.id,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.singleAdmin = {};
                        this.isLoading = false;
                        await this.getDataList();
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
        changePasswordType: function () {
            if (this.inputTypePassword == "password") {
                this.inputTypePassword = "text";
                this.iconStatus = false;
            }
            else if (this.inputTypePassword == "text") {
                this.inputTypePassword = "password";
                this.iconStatus = true;
            }
        },
        changeConfirmPasswordType: function () {
            if (this.inputTypeConfirmPassword == "password") {
                this.inputTypeConfirmPassword = "text";
                this.iconFlag = false;
            }
            else if (this.inputTypeConfirmPassword == "text") {
                this.inputTypeConfirmPassword = "password";
                this.iconFlag = true;
            }
        },
        deleteAdmin: async function (id) {

            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: "DELETE",
                            url: this.$serverURL + this.$api.admin.adminURL + '/' + id,
                            headers: authHeader()
                        };
                        await this.$axios(config).then((response) => {
                            if (response.status == 200) {
                                this.isLoading = false;
                                this.$swal.fire({
                                    icon: "success",
                                    text: response.data.message
                                });
                            }
                            this.getDataList();
                        }).catch(error => {
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later."
                        });
                    }
                }
            })
        },
        exportUsers: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Name": el.fullName,
                    "Phone": el.phone,
                    "Email": el.email,
                    "User Type": el.userType, 
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'admin_report(' + date + ').xlsx')
            this.sheets = [];
        },
    }
}