import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Customer",
    data() {
        return {
            fullName: '',
            email: '',
            phone: '',
            position: '',
            userType: 'crew',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            singleCrew: {},
            dataList: [],
            sheets: [],
            supervisor: "",
            supervisorList: [],
            updateSupervisor: "",
            createdDate: '',
            emailList: [],
            phoneList: [],
            filter: {
                email: "",
                phone: "",
                joiningDate: "",
            }
        }
    },
    async created() {
        document.title = "Land Scape - Crew";
        await this.getCrewList();
        await this.getSupervisorList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getCrewList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.crew.crewURL + "?joiningDate=" + this.filter.joiningDate + "&email=" + this.filter.email + "&phone=" + this.filter.phone,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    if (this.emailList.length == 0) {
                        this.emailList = response.data.data.map(el => ({ value: el.email, label: el.email }));
                    }
                    if (this.phoneList.length == 0) {
                        this.phoneList = response.data.data.map(el => ({ value: el.phone, label: el.phone }));
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        getSupervisorList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.crew.supervisorURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.supervisorList = response.data.data
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        crewValidation: async function () {
            if (!this.fullName) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
            }
            else if (!this.email) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!"
                });
            }
            else if (!this.phone) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter phone number!"
                });
            }
            else if (!this.supervisor) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please select supervisor!"
                });
            }
            else if (!this.position) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter position!"
                });
            }
            else if (!this.password) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter password!"
                });
            }
            else if (!this.confirmPassword) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter confirm password!"
                });
            }
            else if (this.password != this.confirmPassword) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Password didn't match!"
                });
            } else {
                await this.submitData();
                document.getElementById("closeButton").click();
            }
        },

        submitData: async function () {
            let data = {
                fullName: this.fullName,
                email: this.email,
                password: this.password,
                phone: this.phone,
                position: this.position,
                supervisorId: this.supervisor ? this.supervisor.id : '0',
                supervisorName: this.supervisor.name,
                userType: this.userType
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.crew.register,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "success",
                        text: response.data.message
                    });
                }
                this.fullName = '';
                this.email = '';
                this.phone = '';
                this.userType = 'crew';
                this.password = '';
                this.confirmPassword = '';
                this.position = '';
                this.supervisor = '';
                this.inputTypePassword = "password";
                this.inputTypeConfirmPassword = "password";
                this.getCrewList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });

        },
        editCrew: function (data) {
            this.singleCrew = data;
        },
        updateCrew: async function () {
            this.isLoading = true;
            if (!this.singleCrew.fullName) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
            }
            else if (!this.singleCrew.phone) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter phone number!"
                });
            }
            else if (!this.singleCrew.position) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter position!"
                });
            }
            else {
                let data = {
                    fullName: this.singleCrew.fullName,
                    phone: this.singleCrew.phone,
                    position: this.singleCrew.position,
                    supervisorId: this.updateSupervisor ? this.updateSupervisor.id : this.singleCrew.supervisorId,
                    supervisorName: this.updateSupervisor ? this.updateSupervisor.name : this.singleCrew.supervisorName,
                    userType: this.singleCrew.userType
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.user.singleUser + this.singleCrew.id,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.singleCrew = {};
                        this.updateSupervisor = ""
                        await this.getCrewList();
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
        updatePassword: async function () {
            this.isLoading = true;
            if (!this.password) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter password!"
                });
                return false;
            }
            else if (!this.confirmPassword) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter confirm password!"
                });
                return false;
            }
            else if (this.password != this.confirmPassword) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Password didn't match!"
                });
                return false;
            }
            else {
                let data = {
                    ...this.singleCrew,
                    password: this.password 
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.user.singleUser + this.singleCrew.id,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.singleCrew = {};
                        this.isLoading = false;
                        this.password = "";
                        this.confirmPassword = "";
                        await this.getCrewList();
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
        changePasswordType: function () {
            if (this.inputTypePassword == "password") {
                this.inputTypePassword = "text";
                this.iconStatus = false;
            }
            else if (this.inputTypePassword == "text") {
                this.inputTypePassword = "password";
                this.iconStatus = true;
            }
        },
        changeConfirmPasswordType: function () {
            if (this.inputTypeConfirmPassword == "password") {
                this.inputTypeConfirmPassword = "text";
                this.iconFlag = false;
            }
            else if (this.inputTypeConfirmPassword == "text") {
                this.inputTypeConfirmPassword = "password";
                this.iconFlag = true;
            }
        },
        deleteCrew: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.user.singleUser + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getCrewList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        exportUsers: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Crew Name": el.fullName,
                    "Phone": el.phone,
                    "Email": el.email,
                    "Position": el.position,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'crew_report(' + date + ').xlsx')
            this.sheets = [];
        },
        // filterCustomer: async function () {
        //     document.getElementById("closeFilter").click();
        //     if (this.createdDate) {
        //         window.$('#example').DataTable().destroy();
        //         let config = {
        //             method: "GET",
        //             url: this.$serverURL + this.$api.user.getUsersListURL + "?userType=" + this.userType + "&createdDate=" + this.createdDate,
        //             headers: authHeader()
        //         };
        //         await this.$axios(config).then((response) => {
        //             if (response.status == 200) {
        //                 this.dataList = response.data.data;
        //                 this.createdDate = '';
        //             }
        //             this.isLoading = false;
        //         }).catch(error => {
        //             console.log(error);
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Something went wrong. Please try again!"
        //             });
        //         });
        //         window.$('#example').DataTable();
        //     }
        //     else {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please provide joining date!"
        //         });
        //     }
        // }
        validateFilter: async function () {
            try {
                if(this.filter.email == null){
                    this.filter.email = '';
                }
                if(this.filter.phone == null){
                    this.filter.phone = '';
                }
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getCrewList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                email: "",
                phone: "",
                joiningDate: "",
            }
            await this.getCrewList();
        },
    }
}