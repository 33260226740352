import { createApp } from 'vue';
import App from './App.vue';
import router from './routes/routes';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import moment from 'moment';
import mixins from './mixin'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueGoogleMaps from '@fawmi/vue-google-maps';

const server = require('../config/server.json');

const app = createApp(App);
app.use(VueAxios, axios);
app.component("v-select", vSelect);
app.config.globalProperties.$axios = axios;
app.use(VueSweetalert2);
app.config.globalProperties.$filters = {
    formatDateTime(date) {
        return moment(String(date)).format('MM/DD/YYYY hh:mm:ss A');
    },
    formatDate(date) {
        return moment(String(date)).format('MM-DD-YYYY');
    },
    workingTime(time) {
        if(!time) return '';

        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            time = time.slice (1);
            time[5] = +time[0] < 12 ? ' AM' : ' PM';
            time[0] = +time[0] % 12 || 12;
        }
        return time.join ('');
    },
    workingDate(date) {
        return moment(String(date)).format('D MMM, YYYY');
    }
};
import Toaster from "@meforma/vue-toaster";

app.use(Toaster, {
    position: "top",
});
app.component('Multiselect', Multiselect);
import CKEditor from '@ckeditor/ckeditor5-vue';

app.use(CKEditor);

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAbD0RqZMPCo6ZKHhFcBAnfAroNQFLFF-o',
        libraries: "places"
    }
});

app.component('Datepicker', Datepicker);
app.config.globalProperties.$api = require('../config/api.json');
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    app.config.globalProperties.$serverURL = server.server_staging.SERVER_HOST;
    app.config.globalProperties.$imageURL = server.server_staging.IMAGE_URL;
} else {
    app.config.globalProperties.$serverURL = server.server.SERVER_HOST;
    app.config.globalProperties.$imageURL = server.server.IMAGE_URL;
}
app.mixin(mixins);
app.use(router).mount('#app')
