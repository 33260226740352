<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Expense List
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <!-- <a
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
              id="filterTable"
              class="btn btn-success m-1 mb-md-0"
              style="float: right"
            >
              <i class="bx bx-filter text-white float-left"></i>Filter
            </a> -->
            <button
              type="button"
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <i class="bx bx-filter text-white float-left"></i>Filter
            </button>
            <button
              type="button"
              class="btn btn-primary ms-2"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add Expense
            </button>
            <button
              type="button"
              class="btn btn-warning ms-2"
              @click="exportExpense"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button>

            <!-- <button
              type="button"
              class="btn btn-success ms-2"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <i class="bx bxs-plus-square"></i>Filter
            </button> -->
          </div>
          <!-- Add Modal -->
          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addExpense">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title"
                      id="addModalLabel"
                      style="font-weight: 600"
                    >
                      Add Expense
                    </h5>
                    <button
                      type="button"
                      id="closeButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Name:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="expenseName"
                          placeholder="Enter expense name*"
                        />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Total Amount:<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="number"
                          v-model="expenseAmount"
                          placeholder="Enter expense price*"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Expense Type:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="selectedCategory"
                          class="form-select mb-3"
                        >
                          <option style="display: none" selected value="">
                            Select category
                          </option>
                          <option
                            v-for="(row, index) in expenseCategory"
                            :key="index"
                            :value="{ id: row.id, name: row.categoryName }"
                          >
                            {{ row.categoryName }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Expense Date:<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <Datepicker
                          v-model="expenseDate"
                          placeholder="Expense date"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Expense By:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="selectedAdmin"
                          class="form-select mb-3"
                        >
                          <option style="display: none" selected value="">
                            Select User
                          </option>
                          <option
                            v-for="(row, index) in adminList"
                            :key="index"
                            :value="{ name: row.fullName }"
                          >
                            {{ row.fullName }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Details:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Expense details*"
                          v-model="expenseDetails"
                        ></textarea>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
          <!-- Edit Modal -->
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateExpense">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Update Expense
                    </h5>
                    <button
                      type="button"
                      id="closeUpdateButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Name:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="singleExpense.expenseName"
                        />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Total Amount:<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="number"
                          v-model="singleExpense.totalAmount"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Expense Type::<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="updateCategory"
                          class="form-select mb-3"
                        >
                          <option
                            v-for="(row, index) in expenseCategory"
                            :key="index"
                            :value="{ id: row.id, name: row.categoryName }"
                          >
                            {{ row.categoryName }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Expense Date:<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <Datepicker
                          v-model="singleExpense.expenseDate"
                          :minDate="new Date()"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Expense By:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select v-model="updateAdmin" class="form-select mb-3">
                          <option
                            v-for="(row, index) in adminList"
                            :key="index"
                            :value="{ name: row.fullName }"
                          >
                            {{ row.fullName }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label" style="font-weight: 600"
                          >Details:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          v-model="singleExpense.expenseDetails"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- filter modal -->
          <div
            class="modal fade"
            id="filterModal"
            tabindex="-1"
            aria-labelledby="filterModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="filterClose"
                  ></button>
                </div>
                <div class="modal-body">
                  <form class="filter_form" @submit.prevent="validateFilter">
                    <div class="row mb-3">
                      <div class="col-6 col-sm-6 mb-1">
                        <label class="form-label">User Name:</label>
                        <Multiselect
                          v-model="filter.userName"
                          id="inputVendor"
                          :options="userList"
                          :searchable="true"
                        />
                      </div>
                      <div class="col-6 col-sm-6 mb-1">
                        <label class="form-label">Expense Name:</label>
                        <Multiselect
                          v-model="filter.expenseName"
                          id="inputVendor"
                          :options="expenseList"
                          :searchable="true"
                        />
                      </div>
                      <div class="col-6 col-sm-6 mb-1">
                        <label class="form-label">Category:</label>
                        <select
                          v-model="filter.category"
                          class="form-select mb-3"
                        >
                          <option style="display: none" selected value="">
                            Select category
                          </option>
                          <option
                            v-for="(row, index) in expenseCategory"
                            :key="index"
                            :value="row.categoryName"
                          >
                            {{ row.categoryName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 col-sm-6">
                        <label class="form-label">Start Date:</label>
                        <input
                          v-model="filter.startDate"
                          type="date"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 col-sm-6">
                        <label class="form-label">End Date:</label>
                        <input
                          v-model="filter.endDate"
                          type="date"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="text-center">
                        <button
                          type="submit"
                          @click="clearFilter()"
                          class="
                            btn btn-sm btn-warning
                            rounded-pill
                            ps-3
                            pe-3
                            m-1
                          "
                          data-bs-dismiss="modal"
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Expense List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Expense Type</th>
                  <th>Amount</th>
                  <th>Expense By</th>
                  <th>Expense Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.expenseName }}</td>
                  <td>{{ data.categoryName }}</td>
                  <td>{{ data.totalAmount }}</td>
                  <td>{{ data.expenseBy }}</td>
                  <td>{{ $filters.formatDate(data.expenseDate) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <button
                        type="button"
                        class="btn btn-outline-success btn-sm m-1"
                        v-on:click="editExpense(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                      >
                        <i class="fa-solid fa-pen-to-square me-0"></i>
                      </button>

                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm m-1"
                        v-on:click="deleteExpense(data.id)"
                      >
                        <i class="fa-solid fa-trash-can me-0"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Amount</th>
                  <th>Expense By</th>
                  <th>Expense Date</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/expense.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>