import { authHeader } from "../../../auth";
import html2pdf from 'html2pdf.js'
authHeader
export default {
    data() {
        return {
            id: this.$route.params.id,
            reservationDetails: {},
            serviceList: [],
            orderDetails: {},
            userInfo: {},
            filter: {
                createdAt: "",
            },
            reserveLogList: [],
            billingData: {}
        }
    },
    async created() {
        document.title = "Land Scape - Reservation Details";
        this.loadJs();
        await this.getReserveDetails();
        this.getRequestLog();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getReserveDetails() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.reservation.reservationURL + '/' + this.id,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.reservationDetails = response.data.data;
                        this.serviceList = this.reservationDetails.reserveDetails;
                        this.billingData = JSON.parse(response.data.data.billingAddress);
                        this.userInfo = this.reservationDetails.userInfo;
                        console.log(this.reservationDetails, this.serviceList);
                    }

                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error);
            }
        },

        async getRequestLog() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.report.serviceRequestLog + '/' + this.reservationDetails.reserveId + "?createdAt=" + this.filter.createdAt,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        console.log(response);
                        if (response.data.data.length > 0) {
                            this.reserveLogList = response.data.data;
                        } else {
                            this.reserveLogList = [];
                        }
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error)
            }
        },

        generatePDF: function () {
            const doc = document.getElementById('doc');
            // var opt = {
            //     filename: this.orderDetails.orderId + '.pdf',
            //   };
            var opt = {
                filename: this.reservationDetails.reserveId + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
                pagebreak: { mode: 'avoid-all', before: '#page2el' }
            };
            html2pdf().from(doc).set(opt).save();
        },

        validateFilter: async function () {
            try {
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getRequestLog();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },

        clearFilter: async function () {
            this.filter = {
                createdAt: "",
            },
                await this.getRequestLog();
        },
    }
}