<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="container">
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="invoice-container" id="doc">
                  <div class="invoice-header">
                    <!-- Row start -->
                    <!-- <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div class="custom-actions-btns mb-5">
                            <a href="#" class="btn btn-primary">
                              <i class="icon-download"></i> Download
                            </a>
                            <a href="#" class="btn btn-secondary">
                              <i class="icon-printer"></i> Print
                            </a>
                          </div>
                        </div>
                      </div> -->
                    <!-- Row end -->
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <a href="index.html" class="invoice-logo">
                          Service Request Details
                        </a>
                      </div>
                    </div>
                    <!-- Row end -->
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Customer Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="fw-bold"> Name: </span
                              >{{
                                userInfo.fullName
                                  ? userInfo?.fullName
                                  : "Not available"
                              }}
                            </li>
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="fw-bold"> Contact: </span
                              >{{
                                userInfo?.phone
                                  ? userInfo?.phone
                                  : "Not available"
                              }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!-- <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Shipping Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <div v-if="orderDetails">
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span class="fw-bold"> Address: </span>
                                {{
                                  orderDetails
                                    ? orderDetails
                                    : ""
                                }}
                              </div>
                              <div v-else>
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span> Address: </span>
                                Not available
                              </div>
                            </li>
                            <li class="text-muted">
                              <div v-if="orderDetails.contactNo">
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span class="fw-bold"> Contact No: </span>
                                {{
                                  orderDetails?.contactNo
                                    ? orderDetails?.contactNo
                                    : ""
                                }}
                              </div>
                              <div v-else>
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span> Contact No:</span>
                                Not available
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div> -->
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted"><strong>Invoice</strong></p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="fw-bold"> ID: </span>#{{
                                reservationDetails.reserveId
                              }}
                            </li>
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="fw-bold"> Request Date: </span
                              >{{
                                this.$filters.formatDate(
                                  reservationDetails.serviceRequestDate
                                )
                              }}
                            </li>
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="me-1 fw-bold">
                                Request Status: </span
                              ><span
                                :class="[
                                  reservationDetails.reservationStatus ==
                                  'Pending'
                                    ? 'badge bg-primary'
                                    : reservationDetails.reservationStatus ==
                                      'Processing'
                                    ? 'badge bg-info'
                                    : reservationDetails.reservationStatus ==
                                      'Working'
                                    ? 'badge bg-warning'
                                    : reservationDetails.reservationStatus ==
                                      'COMPLETED'
                                    ? 'badge bg-success'
                                    : 'badge bg-danger',
                                ]"
                              >
                                {{ reservationDetails.reservationStatus }}</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                  <div class="invoice-body">
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                          <table class="table custom-table m-0">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Service Name</th>
                                <th scope="col">Request Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in serviceList"
                                :key="index"
                              >
                                <th scope="row">{{ 1 }}</th>
                                <td>{{ data.serviceName }}</td>
                                <td>
                                  {{ data.status }}
                                </td>
                              </tr>
                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="custom-actions-btns mb-5">
              <!-- <a href="#" class="btn btn-primary">
                            <i class="icon-download"></i> Download
                          </a> -->
              <a
                href="javascript:void(0)"
                class="btn btn-primary"
                @click="generatePDF"
              >
                <i class="fa-solid fa-print me-0"></i>&nbsp;<strong>Print</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/request-details.js"></script>

<style scoped>
@import "../css/request-details.css";
</style>
