import { authHeader } from "../../../auth";

export default {
    name: "UpdateRoute",
    data() {
        return {
            id: this.$route.params.id,
            data: {},
            startPoint: '',
            endPoint: '',
            startLatitude: '',
            endLatitude: '',
            startLongitude: '',
            endLongitude: ''
        }
    },
    async created() {
        document.title = "Land Scape - Update Route";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    mounted() {
        if (this.$route.params.id != undefined) {
            this.fetchEditData(this.$route.params.id);
        }
    },
    methods: {
        async fetchEditData() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.route.routeAPI + '/' + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.data = response.data.data[0];
                    this.startPoint = this.data.startPoint;
                    this.endPoint = this.data.endPoint;
                    this.startLatitude = this.data.startLatitude;
                    this.startLongitude = this.data.startLongitude;
                    this.endLatitude = this.data.endLatitude;
                    this.endLongitude = this.data.endLongitude;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        setStartPoint(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.startPoint = location;
            this.startLatitude = locationData.geometry.location.lat();
            this.startLongitude = locationData.geometry.location.lng();
        },
        setEndPoint(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.endPoint = location;
            this.endLatitude = locationData.geometry.location.lat();
            this.endLongitude = locationData.geometry.location.lng();
        },
        updateRoute: async function () {
            if (!this.data.routeName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter route name!"
                });
            }
            else if (!this.startPoint) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter start point!"
                });
            }
            else if (!this.endPoint) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter end point!"
                });
            }
            else {
                let data = {
                    routeName: this.data.routeName,
                    startPoint: this.startPoint,
                    endPoint: this.endPoint,
                    startLatitude: this.startLatitude,
                    startLongitude: this.startLongitude,
                    endLatitude: this.endLatitude,
                    endLongitude: this.endLongitude
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.route.routeAPI + '/' + this.id,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully updated data!"
                        });
                        this.$router.push("/route");
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}