<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Payroll List
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add Payroll
                        </button>
                        <!-- <button
              type="button"
              class="btn btn-secondary ms-2"
              @click="exportUsers"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button> -->
                        <!-- <button
              type="button"
              class="btn btn-success ms-2"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <i class="bx bxs-plus-square"></i>Filter
            </button> -->
                    </div>
                    <!-- Add Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel"
                        aria-hidden="true">
                        <form>
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add Payroll</h5>
                                        <button type="button" id="closeButton" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">User<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <Multiselect v-model="user" id="inputVendor" :options="userList"
                                            :searchable="true" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Shift<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <Multiselect v-model="shift" id="inputVendor" :options="shiftList"
                                            :searchable="true" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Shift Hours:<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <input class="form-control mb-1" type="text" v-model="shiftHours" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="button" @click="addCustomer" class="btn btn-primary">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Modal -->
                    <!-- Edit Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel"
                        aria-hidden="true">
                        <form>
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Update Payroll</h5>
                                        <button type="button" id="closeButton" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">User<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <Multiselect v-model="singlePayroll.user" id="inputVendor" :options="userList"
                                            :searchable="true" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Shift<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <Multiselect v-model="singlePayroll.shift" id="inputVendor" :options="shiftList"
                                            :searchable="true" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Shift Hours:<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <input class="form-control mb-1" type="text" v-model="singlePayroll.shiftHours" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="button" @click="addCustomer" class="btn btn-primary">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Route List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Start Point</th>
                                    <th>End Point</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Test</td>
                                    <td>Test</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editCustomer(data)"
                                                data-bs-toggle="modal" data-bs-target="#editModal"
                                                class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteCustomer(data.id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Start Point</th>
                                    <th>End Point</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/payroll.js'></script>

<style scoped>
    @import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

    .edit-button {
        background-color: #17a00e;
    }

    .delete-button {
        background-color: #f41127;
    }
</style>