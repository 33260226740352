<template>
  <div class="page-wrapper">
    <div class="page-content" v-if="role == 2">
      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Today Total Customers</p>
                  <h4 class="my-1">{{ dataList.todayTotalCustomers }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-user-group"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Today Total Services</p>
                  <h4 class="my-1">{{ dataList.todayTotalServices }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-list-ol"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Today Total Service Request</p>
                  <h4 class="my-1">{{ dataList.todayTotalServiceRequest }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-list-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Today Total Crews</p>
                  <h4 class="my-1">{{ dataList.todayTotalCrews }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-users-gear"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Customers</p>
                  <h4 class="my-1">{{ dataList.totalCustomers }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-user-group"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Services</p>
                  <h4 class="my-1">{{ dataList.totalServices }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-warning text-warning ms-auto"
                >
                  <i class="fa-solid fa-list-ol"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Service Request</p>
                  <h4 class="my-1">{{ dataList.totalServiceRequest }}</h4>
                </div>
                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                  <i class="fa-solid fa-list-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Crews</p>
                  <h4 class="my-1">{{ dataList.totalCrews }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-users-gear"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mx-auto">
          <!-- <h6 class="mb-0 text-uppercase">Top Category</h6> -->
          <!-- <hr/> -->
          <div class="card">
            <div class="card-body">
              <div class="chart-container1">
                <canvas
                  id="categoryChart"
                  style="position: relative; height: 80vh; width: 80vw"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 mx-auto">
          <!-- <h6 class="mb-0 text-uppercase">Bar Chart</h6> -->
          <!-- <hr/> -->
          <div class="card">
            <div class="card-body">
              <div class="chart-container1">
                <!-- <canvas id="chart2"></canvas> -->
                <canvas
                  id="myChart"
                  style="position: relative; height: 80vh; width: 80vw"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md">
          <div class="card">
            <div class="card-body">
              <div class="chart-container1">
                <!-- <canvas id="chart2"></canvas> -->
                <canvas
                  id="lineChart"
                  style="position: relative; height: 80vh; width: 80vw"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content" v-if="role == 5">
      <div class="row">
        <div class="col-sm-4">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">
                    Today Total Assigned Service Request
                  </p>
                  <h4 class="my-1">
                    {{ dataList.todayTotalAssignedServiceRequest }}
                  </h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-user-group"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">
                    Today Total Working Service Request
                  </p>
                  <h4 class="my-1">
                    {{ dataList.todayTotalWorkingServiceRequest }}
                  </h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-list-ol"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">
                    Today Total Finished Service Request
                  </p>
                  <h4 class="my-1">
                    {{ dataList.todayTotalFinishedServiceRequest }}
                  </h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-list-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">
                    Total Assigned Service Request
                  </p>
                  <h4 class="my-1">
                    {{ dataList.totalAssignedServiceRequest }}
                  </h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="fa-solid fa-user-group"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">
                    Total Finished Service Request
                  </p>
                  <h4 class="my-1">
                    {{ dataList.totalFinishedServiceRequest }}
                  </h4>
                </div>
                <div
                  class="widgets-icons bg-light-warning text-warning ms-auto"
                >
                  <i class="fa-solid fa-list-ol"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total crew</p>
                  <h4 class="my-1">{{ dataList.totalCrew }}</h4>
                </div>
                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                  <i class="fa-solid fa-list-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="editModal"
        tabindex="-1"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <form @submit.prevent="updateCrew">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="addModalLabel">View Crew</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="row">
                <div class="col">
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleCrew.fullName"
                      disabled
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="modal-body">
                    <label class="form-label">Phone:</label>
                    <input
                      class="form-control mb-1"
                      type="tel"
                      v-model="singleCrew.phone"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="modal-body">
                    <label class="form-label"
                      >Supervisor<span style="color: red; font-size: 18px"
                        >*</span
                      >:</label
                    >
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleCrew.supervisorName"
                      disabled
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="modal-body">
                    <label class="form-label"
                      >Position:
                      <span style="color: red; font-size: 18px">*</span>:</label
                    >
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleCrew.position"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="row">
        <div class="col-xl-12 d">
          <div class="card radius-10 w-100">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <h5 class="mb-1">Crew List</h5>
                </div>
              </div>
              <div class="table-responsive">
                <table id="example" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in crewList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ data.fullName }}</td>
                      <td>{{ data.email }}</td>
                      <td>{{ data.phone }}</td>
                      <td>
                        <div class="d-flex order-actions">
                          <button
                            type="button"
                            class="btn btn-outline-success btn-sm m-1"
                            v-on:click="editCrew(data)"
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                          >
                            <i class="fa-solid fa-eye me-0"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Sl</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
</style>

<script src="../js/dashboard.js"></script>
