<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Assign Supervisor
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group"></div>
        </div>
      </div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Single Assign
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Bulk Assign
          </button>
        </li>
      </ul>
      <hr />
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="example" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Assign Supervisor</th>
                      <th>User Type</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ data.fullName }}</td>
                      <td>{{ data.email }}</td>
                      <td>{{ data.phone }}</td>
                      <td>
                        <Multiselect
                          v-model="data.supervisor"
                          id="inputVendor"
                          @select="
                            () => {
                              updateCrew(data.id, data.supervisor);
                            }
                          "
                          :options="supervisorList"
                          :searchable="true"
                        />
                      </td>
                      <td>{{ data.userType }}</td>
                      <td>{{ $filters.formatDateTime(data.createdAt) }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Sl</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Assign Supervisor</th>
                      <th>User Type</th>
                      <th>Created At</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div class="row">
            <div class="col-3 mb-3">
              <label
                for="basic-url"
                class="form-label"
                style="font-size: 17px; weight: 500"
                >Select Supervisor</label
              >
              <Multiselect
                v-model="bulkSupervisor"
                id="inputVendor"
                :options="supervisorList"
                :searchable="true"
              />
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="selectAll"
                          @change="
                            () => {
                              handleSelectAll();
                            }
                          "
                          id="flexCheckDefault"
                        />
                      </th>
                      <th>Sl</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>User Type</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataList" :key="index">
                      <td>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="bulkAssignChecklist[index]"
                          @change="
                            () => {
                              bulkHandle(
                                index,
                                bulkAssignChecklist[index],
                                data.id
                              );
                            }
                          "
                          id="flexCheckDefault"
                        />
                      </td>
                      <td>{{ index + 1 }}</td>
                      <td>{{ data.fullName }}</td>
                      <td>{{ data.email }}</td>
                      <td>{{ data.phone }}</td>
                      <td>{{ data.userType }}</td>
                      <td>{{ $filters.formatDateTime(data.createdAt) }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th>Sl</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>User Type</th>
                      <th>Created At</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <button
            class="btn btn-success mb-5"
            style="float: right"
            @click="handleBulkSubmit()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/assign-supervisor.js'></script>

<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>