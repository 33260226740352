import { authHeader } from "../../../auth";
import { countries } from "../../../../config/country";
export default {
    data() {
        return {
            isExistingUser: false,
            customerList: [],
            customer: "",
            selectedCustomer: "",
            serviceList: [],
            selectedService: [],
            countryList: [],
            countries: countries,
            routeList: [],
            route: "",
            reserveStatus: "",
            paymentStatus: "",
            paidAmount: 0,
            dueAmount: 0,
            totalAmount: 0,
            userType: 'customer',
            //customer info
            customerName: "",
            customerEmail: "",
            customerMobile: "",
            customerAddress: "",
            reserveDate: "",
            reserveTime: "",
            addMoreList: [{ serviceName: "" }],
            serviceRequest: [],
            discount:'',
            total: '',
            billingData: {},
            billingCountry: "",
            serviceDaysList:[
                { value: 'Monday', label: 'Monday' },
                { value: 'Tuesday', label: 'Tuesday' },
                { value: 'Wednesday', label: 'Wednesday' },
                { value: 'Thursday', label: 'Thursday' },
                { value: 'Friday', label: 'Friday' },
                { value: 'Saturday', label: 'Saturday' },
                { value: 'Sunday', label: 'Sunday' },
            ],
            serviceType:'',
            serviceTypeList: [{ value: "Reoccurring", label: "Reoccurring" }, { value: "One time Only", label: "One time Only" }],
            serviceDays:[],
            isServiceDays:false,
            serviceStartTime:'',
        }
    },
    async created() {
        document.title = "Land Scape - Request Reservation";
        await this.getDataList();
        await this.getCustomerList();
        await this.getRoutes();
        this.countryList = this.countries.map(el => {
            return {value:{ name: el.countryNameEn, code: el.countryCode }, label: el.countryNameEn}
        })
    },
    methods: {

        getServiceType () {
            this.isServiceDays = false;
            this.serviceDays = []
            if(this.serviceType == 'reoccurring'){
                this.isServiceDays = true;
            }
        },
        getDataList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.serviceList = response.data.data.map((el) => {
                        return { value: el, label: el.serviceName };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getRoutes: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.route.routeAPI,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.routeList = response.data.data.map((el) => {
                        return { value: el.id, label: el.routeName };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getCustomerList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getUsersListURL + "?userType=" + this.userType,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.customerList = response.data.data.map(el => {
                        return { value: el, label: el.fullName }
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        setCustomerData: async function (data) {
            let closeBtn = document.querySelector('.multiselect-clear-icon');
            if(closeBtn)
                closeBtn.style.visibility = 'hidden';
            this.customerName = data.fullName;
            this.customerMobile = data.phone;
            this.customerEmail = data.email;
            await this.getAddress(data.id);
            console.log(data)
        },
        getAddress: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.billingUserURL + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.billingData = response.data.data;
                    this.customer = {
                        id: response.data.data.userId,
                        name: response.data.data.userName
                    };
                    this.billingCountry = {value:{
                        name: response.data.data.country,
                        code: response.data.data.countryCode
                    }, label: response.data.data.country}
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        addMore: function () {
            this.addMoreList.push({ serviceName: "" });

        },
        addDiscount: function () {
            let discountCheck = ''
            if (this.discount.slice(-1) == '%') {
                discountCheck = this.discount.slice(0, -1);
            }
            else {
                discountCheck = this.discount;
                this.total = this.totalAmount;
            }
            if (isNaN(discountCheck)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter valid discount amount or percentage"
                });
                this.discount = '';
                return;
            }
            if (this.discount && !isNaN(discountCheck)) {
                if (this.discount.includes("%")) {
                    let converted_discount = parseFloat(this.discount) / 100.0;
                    this.total = this.totalAmount - this.totalAmount * converted_discount;
                }
                else {
                    try {
                        let converted_discount = parseFloat(this.discount);
                        this.total = this.totalAmount - converted_discount;
                    }
                    catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please enter discount amount or percentage"
                        });
                    }
                }
            }
            else {
                this.total = this.totalAmount;
            }

        },
        validate: function() {

            if((!this.customer.name)  ) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select a customer!"
                });
                return false;
            }

            if(!this.billingData.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter billing name!"
                });
                return false;
            }
            if(!this.billingData.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!"
                });
                return false;
            }
            if(!this.billingData.mobile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter contact no!"
                });
                return false;
            }
            if(!this.billingData.stateCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter state code!"
                });
                return false;
            }
            if(!this.billingData.postCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter postal code!"
                });
                return false;
            }
            if(!this.billingData.city) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter city!"
                });
                return false;
            }
            if(!this.billingCountry.value.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select country!"
                });
                return false;
            }
            if(!this.billingData.addressOne) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter address1!"
                });
                return false;
            }
             if (!this.serviceType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select service type."
                })
                return false;
            } 
             if ((this.serviceDays.length == 0) && (this.serviceType =='reoccurring') ) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select service day(s)."
                })
                return false;
            }
            return true;
        },

        updateBilling: async function () {
            let token = localStorage.getItem('token');
            let data = {
                name: this.billingData.name,
                email: this.billingData.email,
                mobile: this.billingData.mobile,
                city: this.billingData.city,
                stateCode: this.billingData.stateCode,
                postCode: this.billingData.email,
                country: this.billingCountry.value.name,
                countryCode: this.billingCountry.value.code,
                addressOne: this.billingData.addressOne,
                addressTwo: this.billingData.addressTwo,
                userId: this.customer.id,
                userName: this.customer.name
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.user.billingURL,
                data: data,
                headers: {
                    "Authorization": "Bearer " + token,
                }
            };
            await this.$axios(config).then((response) => {
                if(response.data.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                //this.serviceStatus = null;
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async removeFromList(index) {
            // let myForm = document.getElementById(index);
            // let child = myForm.getElementsByClassName('multiselect-clear');
            // let innerChild = child[0].querySelector('.multiselect-clear-icon');
            // await innerChild.click();
            //myForm.remove();
            this.selectedService.splice(index, 1);
            this.addMoreList.splice(index, 1);
            this.calculateTotal();
        },
        calculateTotal: function () {
            let closeBtn = document.getElementsByClassName('vs__clear');
            closeBtn.forEach(element => {
                element.style.visibility = 'hidden';
            });
            //closeBtn.style.visibility = 'hidden';
            this.totalAmount = 0
            for (let i = 0; this.selectedService.length > i; i++) {
                this.totalAmount = this.totalAmount + parseInt(this.selectedService[i].value.servicePrice);
            }
            console.log(this.totalAmount)
            this.total = this.totalAmount ;
            if (this.discount) {
                this.addDiscount();
            }
        },
        createReservation: async function () {
            if (!this.customerName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide customer name."
                })
            } else if (!this.customerEmail) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide customer email."
                })
            } else if (!this.customerMobile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please provide customer contact no."
                })
            }  else if (this.selectedService.length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select service."
                })
            } else if (!this.reserveDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select request date."
                })
            } else if (!this.route) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select route."
                })
            } else {
                console.log("this.serviceDays==",this.serviceDays.length)
                try {
                    if(this.validate()){
                        this.serviceRequest = this.selectedService.map(el => {
                            return { serviceId: el.value.id, serviceName: el.value.serviceName, servicePrice: el.value.servicePrice }
                        });
                        this.$swal.showLoading();
                        await this.updateBilling();
                        let data = {
                            fullName: this.customerName,
                            email: this.customerEmail,
                            phone: this.customerMobile,
                            routeName: this.route.label,
                            routeId: this.route.value,
                            address: JSON.stringify({
                                name: this.billingData.name,
                                mobile: this.billingData.mobile,
                                email: this.billingData.email,
                                city: this.billingData.city,
                                postCode: this.billingData.postCode,
                                stateCode: this.billingData.stateCode,
                                country: this.billingCountry.name,
                                countryCode: this.billingCountry.code,
                                addressOne: this.billingData.addressOne,
                                addressTwo: this.billingData.addressTwo,
                            }),
                            serviceRequest: this.serviceRequest,
                            serviceRequestDate: this.reserveDate,
                            paidAmount: this.paidAmount,
                            dueAmount: this.dueAmount,
                            totalAmount: this.total,
                            serviceType: this.serviceType,
                            serviceDays: JSON.stringify(this.serviceDays),
                            serviceStartTime: this.serviceStartTime,
                        }
                        let config = {
                            method: "POST",
                            url: this.$serverURL + this.$api.reservation.reservationURL,
                            data: data,
                            headers: authHeader()
                        };
                        await this.$axios(config).then((response) => {
                            this.$swal.hideLoading();
                            if (response.data.statusCode == 200) {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: response.data.message
                                })
                                this.$router.push('/reservation-list');
                            }
                        }).catch(error => {
                            this.$swal.hideLoading();
                            console.log(error);
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                    
                } catch (error) {
                    this.$swal.hideLoading();
                    console.log(error)
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again."
                    })
                }
            }
        }
    },
    watch: {
        // selectedService: function () {
        //     if (this.selectedService) {
        //         console.log(this.selectedService);
        //         this.totalAmount = this.selectedService?.servicePrice;
        //         this.dueAmount = this.totalAmount;
        //     } else {
        //         this.totalAmount = 0;
        //     }
        // },
        totalAmount: function () {
            this.dueAmount = this.total - this.paidAmount
        },
        paidAmount: function () {
            this.dueAmount = 0;
            this.dueAmount = this.total - this.paidAmount;
        }
    }
}