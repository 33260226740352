import { authHeader } from "../../../auth";
import Chart from 'chart.js/auto'
export default {
    name: "Dashboard",
    data() {
        return {
            dataList: [],
            role: localStorage.getItem('role'),
            topServices: {},
            weeklyServiceRequest: {},
            weeklyIncome: {},
            crewList: [],
            singleCrew: {},
            topServices:{},
            weeklyServiceRequest:{},
            weeklyIncome:{}

        }
    },
    async created() {
        document.title = "Land Scape - Dashboard";
        if (this.role == 2) {
            await this.getAdminDashboard();
            await this.getBarChart();
            await this.getPieChart();
            await this.getLineChart();
        }
        else if(this.role == 5) {
            await this.getSupervisorDashboard();
        }

        await this.loadJS();
    },
    methods: {
        async loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js",
                "/core/assets/plugins/chartjs/js/Chart.min.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getBarChart() {
            const ctx = document.getElementById('myChart').getContext('2d');
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.weeklyServiceRequest.labels,
                    datasets: [{
                        label: '# service request',
                        data: this.weeklyServiceRequest.data[0].data,
                        backgroundColor: [
                            '#0d6efd',
                        ],
                        // borderColor: [
                        //     'rgba(255, 99, 132, 1)',
                        //     'rgba(54, 162, 235, 1)',
                        //     'rgba(255, 206, 86, 1)',
                        //     'rgba(75, 192, 192, 1)',
                        //     'rgba(153, 102, 255, 1)',
                        //     'rgba(255, 159, 64, 1)'
                        // ],
                        //borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    barPercentage: 0.4,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Weekly Service Request',
                            padding: {
                                top: 10,
                                bottom: 30
                            }
                        }
                    }
                }
            });
        },
        async getPieChart() {
            new Chart(document.getElementById("categoryChart"), {
                type: 'doughnut',
                data: {
                    labels: this.topServices.labels,
                    datasets: [{
                        label: 'Top Services',
                        data: this.topServices.data,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(50, 168, 68)',
                            'rgb(50, 131, 168)'
                        ],
                        hoverOffset: 4
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    parsing: {
                        key: 'nested.value'
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Top Five Services',
                            padding: {
                                top: 10,
                                bottom: 30
                            }
                        }
                    }
                }
            });
        },
        async getLineChart() {
            new Chart(document.getElementById("lineChart"), {
                type: 'line',
                data: {
                    labels: this.weeklyIncome.labels,
                    datasets: [{
                        label: 'Weekly Income',
                        data: this.weeklyIncome.data[0].data,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    parsing: {
                        key: 'nested.value'
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Income Chart',
                            padding: {
                                top: 10,
                                bottom: 30
                            }
                        }
                    }
                }
            });
        },
        getAdminDashboard: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.dashboard.adminDashboard,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    this.topServices = response.data.data.topServices;
                    this.weeklyServiceRequest = response.data.data.weeklyServiceRequest;
                    this.weeklyIncome = response.data.data.weeklyIncome
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getSupervisorDashboard: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.dashboard.supervisorDashboard,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    this.crewList = response.data.data.crewList;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        editCrew: function(data) {
            this.singleCrew = data;
        }
    }
}
