import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Admin",
    data() {
        return {
            expenseName: "",
            expenseAmount: "",
            expenseDetails: "",
            singleExpense: {},
            dataList: [],
            sheets: [],
            expenseCategory: "",
            userType: 'admin',
            adminList: [],
            selectedCategory: "",
            selectedAdmin: "",
            expenseDate: "",
            updateCategory: {},
            updateAdmin: {},
            userList: [],
            expenseList: [],
            filter: {
                expenseName: "",
                category: "",
                userName: "",
                startDate: "",
                endDate: "",
            },
        }
    },
    async created() {
        document.title = "Land Scape - Expense";
        await this.getDataList();
        await this.getExpenseCategory();
        await this.getAdminList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getDataList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.expense.expenseURL + '?expenseName=' + this.filter.expenseName + '&userName=' + this.filter.userName + "&category=" + this.filter.category + '&startDate=' + this.filter.startDate + '&endDate=' + this.filter.endDate,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    if (this.expenseList.length == 0) {
                        this.expenseList = response.data.data.map(el => {
                            return { label: el.expenseName, value: el.expenseName }
                        });
                    }


                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        addExpense: async function () {
            if (!this.expenseName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter expense name!"
                });
            } else if (!this.expenseAmount) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter expense amount!"
                });
            }
            else if (Object.keys(this.selectedCategory).length === 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select expense category!"
                });
            }
            else if (Object.keys(this.selectedAdmin).length === 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select user!"
                });
            }
            else if (!this.expenseDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select expense date."
                })
            }
            else if (!this.expenseDetails) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter expense details!"
                });
            }
            else {
                let data = {
                    expenseName: this.expenseName,
                    totalAmount: this.expenseAmount,
                    expenseDetails: this.expenseDetails,
                    categoryId: this.selectedCategory.id,
                    categoryName: this.selectedCategory.name,
                    expenseBy: this.selectedAdmin.name,
                    expenseDate: this.expenseDate
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.expense.expenseURL,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully stored data!"
                        });
                    }
                    this.expenseName = '';
                    this.expenseAmount = '';
                    this.expenseDetails = '';
                    this.getDataList();
                    document.getElementById("closeButton").click();
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        editExpense: function (data) {
            this.singleExpense = data;
            this.updateCategory = {
                id: this.singleExpense.categoryId,
                name: this.singleExpense.categoryName
            },
                this.updateAdmin = {
                    name: this.singleExpense.expenseBy
                }
        },
        updateExpense: async function () {
            if (!this.singleExpense.expenseName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter expense name!"
                });
            } else if (!this.singleExpense.totalAmount) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter expense amount!"
                });
            } else if (!this.singleExpense.expenseDetails) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter expense details!"
                });
            } else {
                let data = {
                    expenseName: this.singleExpense.expenseName,
                    totalAmount: this.singleExpense.totalAmount,
                    expenseDetails: this.singleExpense.expenseDetails,
                    categoryId: this.updateCategory.id,
                    categoryName: this.updateCategory.name,
                    expenseBy: this.updateAdmin.name,
                    expenseDate: this.singleExpense.expenseDate
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.expense.expenseURL + "/" + this.singleExpense.id,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                    }
                    this.singleExpense = {}
                    this.getDataList();
                    document.getElementById("closeUpdateButton").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },

        deleteExpense: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.expense.expenseURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList();

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        exportExpense: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Expense Name": el.expenseName,
                    "Total Amount": el.totalAmount,
                    "Category Name": el.categoryName,
                    "Expense By": el.expenseBy,
                    "Expense Date": this.$filters.formatDate(el.expenseDate),
                    "Expense Details": el.expenseDetails,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'expense_report(' + date + ').xlsx');
            this.sheets = [];
        },
        getExpenseCategory: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.expenseCategory.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.expenseCategory = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getAdminList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.admin.adminURL + '?userType=' + this.userType,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.adminList = response.data.data;
                    this.userList = response.data.data.map(el => {
                        return { label: el.fullName, value: el.fullName }
                    })
                }
            }).catch(error => {
                console.log(error);
            });
        },
        validateFilter: async function () {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select end date!"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select start date!"
                        });
                        return;
                    }
                }
                if (this.filter.expenseName == null) {
                    this.filter.expenseName = "";
                }
                if (this.filter.userName == null) {
                    this.filter.userName = "";
                }
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getDataList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                serviceRequestId: "",
                customerName: "",
                paymentStatus: "",
                requestStatus: "",
                routeName: "",
                startDate: "",
                endDate: "",

            },
                await this.getReserveList();
        },
    }
}