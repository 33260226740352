import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Admin",
    data() {
        return {
            dataList: [],
            sheets: [],
            filter: {
                requestStatus: "",
            },
        }
    },
    async created() {
        document.title = "Land Scape - Expense";
        await this.getDataList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getDataList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.report.todayServiceRequestReportURL + "?status=" + this.filter.requestStatus,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        validateFilter: async function () {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please select end date!"
                        });
                        return;
                    }
                }
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getDataList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                requestStatus: "",

            },
                await this.getDataList();
        },
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    'Id': el.reserveId,
                    'Customer Name': el.userName,
                    "Route": el.routeName,
                    'Request Date': el.serviceRequestDate,
                    "Payment Status": el.paymentStatus,
                    "Request Status": el.reservationStatus,
                    "Paid Amount": el.paidAmount,
                    "Due Amount": el.dueAmount,
                    "Total Amount": el.totalAmount,

                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'today_service_request_report(' + date + ').xlsx')
        },
    }
}