<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <Home v-if="isLogin == false && watchLoginCheck"></Home>
  <router-view></router-view>
</template>

<script>
import Loading from "vue-loading-overlay";
import Home from "./components/home/template/Home.vue";
import { authHeader } from "./auth";

export default {
  name: "App",
  components: {
    Loading,
    Home,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      isLogin: false,
      watchLoginCheck: true,
      token: localStorage.getItem("token"),
    };
  },
  created() {
    if (this.$router.currentRoute.path == "/login") {
      if (this.token) {
        this.isLogin = false;
        this.$router.push("/");
      } else if (!this.token) {
        this.isLogin = true;
        this.watchLoginCheck = false;
      }
      this.isLogin = true;
    }
    else {
      this.isLogin = false;
    }
    this.onInactive(1800000, function () {
      this.$swal.fire({
        icon: "error",
        text: "You have been logged out for 30 minutes of inactivity!",
      });
      localStorage.clear();
      this.$router.push("/login");
    });
  },
  methods: {
    onInactive: function (ms, cb) {
      var wait = setTimeout(cb, ms);
      document.onmousemove =
        document.mousedown =
        document.mouseup =
        document.onkeydown =
        document.onkeyup =
        document.focus =
          function () {
            clearTimeout(wait);
            wait = setTimeout(cb, ms);
          };
    },
  },
  watch: {
    $route() {
      if (this.$route.path == "/login") {
        if (this.token) {
          this.isLogin = false;
          this.$router.push("/");
        } else if (!this.token) {
          this.isLogin = true;
        }
        this.isLogin = true;
      } else {
        this.isLogin = false;
        let config = {
          method: "POST",
          url: this.$serverURL + this.$api.auth.validateTokenURL,
          headers: authHeader(),
        };

        this.$axios(config)
          .then((response) => {
            if (response.status == 201) {
              if (response.data === false) {
                localStorage.clear();
                window.location.href = "/login";
              } else {
                this.watchLoginCheck = true;
                this.isLogin = false;
              }
            }
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      }
    },
  },
};
</script>