<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Supervisor List
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <i class="bx bx-filter text-white float-left"></i>Filter
            </button>
            <button
              type="button"
              class="btn btn-primary ms-2"
              data-bs-toggle="modal"
              data-bs-target="#addSupervisorModal"
            >
              <i class="bx bxs-plus-square"></i>Add Supervisor
            </button>
            <button
              type="button"
              class="btn btn-warning ms-2"
              @click="$router.push('/import-supervisor')"
            >
              <i class="bx bxs-plus-square"></i>Import
            </button>
            <button
              type="button"
              class="btn btn-secondary ms-2"
              @click="exportUsers"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button>
          </div>

          <!-- Add supervisor -->
          <div
            class="modal fade"
            id="addSupervisorModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form>
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Add Supervisor
                    </h5>
                    <button
                      type="button"
                      id="closeSpervisorButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="modal-body">
                        <label class="form-label"
                          >Name:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="fullName"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="modal-body">
                        <label class="form-label"
                          >Position:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="position"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="modal-body">
                        <label class="form-label"
                          >Email:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="email"
                          v-model="email"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="modal-body">
                        <label class="form-label"
                          >Phone:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control mb-1"
                          type="tel"
                          v-model="phone"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="modal-body">
                        <label class="form-label"
                          >Password:<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <div class="input-group" id="show_hide_password">
                          <input
                            :type="inputTypePassword"
                            v-model="password"
                            class="form-control border-end-0"
                            placeholder=""
                          />
                          <a
                            href="javascript:void(0);"
                            @click="changePasswordType()"
                            class="input-group-text bg-transparent"
                            ><i
                              :class="iconStatus ? 'bx bx-hide' : 'bx bx-show'"
                            ></i
                          ></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="modal-body">
                        <label class="form-label"
                          >Confirm Password:<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <div class="input-group" id="show_hide_password">
                          <input
                            :type="inputTypeConfirmPassword"
                            v-model="confirmPassword"
                            class="form-control border-end-0"
                            placeholder=""
                          />
                          <a
                            href="javascript:void(0);"
                            @click="changeConfirmPasswordType()"
                            class="input-group-text bg-transparent"
                            ><i
                              :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"
                            ></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      @click="supervisorValidation"
                      class="btn btn-primary"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Add supervisor -->

          <!-- Edit Modal -->
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateCrew">
              <div class="modal-dialog">
                <div class="modal-content" style="padding: 10px">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Update Supervisor
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body row">
                    <label class="form-label">Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleCrew.fullName"
                    />
                  </div>
                  <div class="modal-body row">
                    <label class="form-label">Phone:</label>
                    <input
                      class="form-control mb-1"
                      type="tel"
                      v-model="singleCrew.phone"
                    />
                  </div>
                  <div class="modal-body row">
                    <label class="form-label"
                      >Position:
                      <span style="color: red; font-size: 18px">*</span></label
                    >
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleCrew.position"
                    />
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Role Modal -->

          <!-- change password Modal -->
          <div
            class="modal fade"
            id="changePassword"
            tabindex="-1"
            aria-labelledby="changePasswordLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updatePassword">
              <div class="modal-dialog">
                <div class="modal-content" style="padding: 10px">
                  <div class="modal-header">
                    <h5 class="modal-title" id="changePasswordLabel">
                      Change Supervisor Password
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body row">
                    <label class="form-label">Password:
                      <span style="color: red; font-size: 18px">*</span>
                    </label>
                    <div class="input-group" id="show_hide_password">
                      <input
                        :type="inputTypePassword"
                        v-model="password"
                        class="form-control border-end-0"
                        placeholder=""
                      />
                      <a
                        href="javascript:void(0);"
                        @click="changePasswordType()"
                        class="input-group-text bg-transparent"
                      >
                        <i :class="iconStatus ? 'bx bx-hide' : 'bx bx-show'"></i>
                      </a>
                    </div>
                  </div>
                  <div class="modal-body row">
                    <label class="form-label">Confirm Password:
                      <span style="color: red; font-size: 18px">*</span>
                    </label>
                    <div class="input-group" id="show_hide_password">
                      <input
                        :type="inputTypeConfirmPassword"
                        v-model="confirmPassword"
                        class="form-control border-end-0"
                        placeholder=""
                      />
                      <a
                        href="javascript:void(0);"
                        @click="changeConfirmPasswordType()"
                        class="input-group-text bg-transparent"
                      >
                        <i :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"></i>
                      </a>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- change password Modal -->

          <!-- filter modal -->
          <div
            class="modal fade"
            id="filterModal"
            tabindex="-1"
            aria-labelledby="filterModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="filterClose"
                  ></button>
                </div>
                <div class="modal-body">
                  <form class="filter_form" @submit.prevent="validateFilter">
                    <div class="row mb-3">
                      <div class="col-6 col-sm-6 mb-1">
                        <label class="form-label">Email:</label>
                        <Multiselect
                          v-model="filter.email"
                          id="inputVendor"
                          :options="emailList"
                          :searchable="true"
                        />
                      </div>
                      <div class="col-6 col-sm-6 mb-1">
                        <label class="form-label">Phone:</label>
                        <Multiselect
                          v-model="filter.phone"
                          id="inputVendor"
                          :options="phoneList"
                          :searchable="true"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 col-sm-12">
                        <label class="form-label">Joining Date:</label>
                        <input
                          v-model="filter.joiningDate"
                          type="date"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="text-center">
                        <button
                          type="submit"
                          @click="clearFilter()"
                          class="
                            btn btn-sm btn-warning
                            rounded-pill
                            ps-3
                            pe-3
                            m-1
                          "
                          data-bs-dismiss="modal"
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- filter modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Supervisor List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>User Type</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in supervisorList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.fullName }}</td>
                  <td>{{ data.email }}</td>
                  <td>{{ data.phone }}</td>
                  <td>{{ data.userType }}</td>
                  <td>{{ $filters.formatDateTime(data.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <button
                        type="button"
                        class="btn btn-outline-success btn-sm m-1"
                        v-on:click="editCrew(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                      >
                        <i class="fa-solid fa-pen-to-square me-0"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-sm m-1"
                        v-on:click="deleteCrew(data.id)"
                      >
                        <i class="fa-solid fa-trash-can me-0"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-warning btn-sm m-1"
                        v-on:click="editCrew(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#changePassword"
                      >
                        <i class="fa-solid fa-key me-0"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>User Type</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/supervisor.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>