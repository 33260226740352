<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="container">
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="invoice-container" id="doc">
                  <div class="invoice-header">
                    <!-- Row start -->
                    <!-- <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div class="custom-actions-btns mb-5">
                            <a href="#" class="btn btn-primary">
                              <i class="icon-download"></i> Download
                            </a>
                            <a href="#" class="btn btn-secondary">
                              <i class="icon-printer"></i> Print
                            </a>
                          </div>
                        </div>
                      </div> -->
                    <!-- Row end -->
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <a href="index.html" class="invoice-logo">
                          Invoice Details
                        </a>
                      </div>
                    </div>
                    <!-- Row end -->
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Customer Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="fw-bold"> Name: </span
                              >{{
                                userInfo.fullName
                                  ? userInfo?.fullName
                                  : "Not available"
                              }}
                            </li>
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="fw-bold"> Contact: </span
                              >{{
                                userInfo?.phone
                                  ? userInfo?.phone
                                  : "Not available"
                              }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Billing Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <div v-if="billingData.name">
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span class="fw-bold"> Name: </span>
                                {{
                                  billingData?.name
                                    ? billingData?.name
                                    : ""
                                }}
                              </div>
                              <div v-else>
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span> Name:</span>
                                Not available
                              </div>
                            </li>
                            <li class="text-muted">
                              <div v-if="billingData.mobile">
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span class="fw-bold"> Contact No: </span>
                                {{
                                  billingData?.mobile
                                    ? billingData?.mobile
                                    : ""
                                }}
                              </div>
                              <div v-else>
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span> Contact No:</span>
                                Not available
                              </div>
                            </li>
                            <li class="text-muted">
                              <div v-if="billingData.email">
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span class="fw-bold"> Email: </span>
                                {{
                                  billingData?.email
                                    ? billingData?.email
                                    : ""
                                }}
                              </div>
                              <div v-else>
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span> Email:</span>
                                Not available
                              </div>
                            </li>
                            <li class="text-muted">
                              <div v-if="billingData">
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span class="fw-bold"> Address: </span>
                                {{
                                  billingData
                                    ? billingData.addressOne + ", " + billingData.city + ", " + billingData.country
                                    : ""
                                }}
                              </div>
                              <div v-else>
                                <i
                                  class="fas fa-circle"
                                  style="color: #84b0ca"
                                ></i>
                                <span> Address: </span>
                                Not available
                              </div>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted"><strong>Invoice</strong></p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="fw-bold"> ID: </span>#{{
                                reservationDetails.reserveId
                              }}
                            </li>
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="fw-bold"> Request Date: </span
                              >{{
                                this.$filters.formatDate(
                                  reservationDetails.serviceRequestDate
                                )
                              }}
                            </li>
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="me-1 fw-bold"> Payment Status: </span
                              ><span
                                :class="[
                                  reservationDetails.paymentStatus == 'Pending'
                                    ? 'badge bg-warning'
                                    : reservationDetails.paymentStatus == 'Paid'
                                    ? 'badge bg-success'
                                    : 'badge bg-danger',
                                  'text-black fw-bold',
                                ]"
                              >
                                {{ reservationDetails.paymentStatus }}</span
                              >
                            </li>
                            <li class="text-muted">
                              <i
                                class="fas fa-circle"
                                style="color: #84b0ca"
                              ></i>
                              <span class="me-1 fw-bold">
                                Reservation Status: </span
                              ><span
                                :class="[
                                  reservationDetails.reservationStatus ==
                                  'Pending'
                                    ? 'badge bg-primary'
                                    : reservationDetails.reservationStatus ==
                                      'Processing'
                                    ? 'badge bg-info'
                                    : reservationDetails.reservationStatus ==
                                      'Working'
                                    ? 'badge bg-warning'
                                    : reservationDetails.reservationStatus ==
                                      'COMPLETED'
                                    ? 'badge bg-success'
                                    : 'badge bg-danger',
                                ]"
                              >
                                {{ reservationDetails.reservationStatus }}</span
                              >
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca" ></i>
                              <span class="me-1 fw-bold"> Service Type: </span><span> {{ reservationDetails.serviceType ? reservationDetails.serviceType.charAt(0).toUpperCase() + reservationDetails.serviceType.slice(1) : '' }}</span>
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca" ></i>
                              <span class="me-1 fw-bold"> Service Start Time: </span><span> {{ reservationDetails.serviceStartTime }}</span>
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca" ></i>
                              <span class="me-1 fw-bold"> Service day(s): </span><span> {{ reservationDetails.serviceDays ? JSON.parse(reservationDetails.serviceDays) : '' }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                  <div class="invoice-body">
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                          <table class="table custom-table m-0">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Service Name</th>
                                <th scope="col">Service Price</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in serviceList"
                                :key="index"
                              >
                                <th scope="row">{{ 1 }}</th>
                                <td>{{ data.serviceName }}</td>
                                <td>
                                  {{ data.servicePrice }}
                                </td>
                                <td>
                                  {{ data.status }}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">&nbsp;</td>
                                <td colspan="1">
                                  <p>Due Amount<br /></p>
                                  <p>Paid Amount<br /></p>
                                  <h5 class="text-success">
                                    <strong>Grand Total</strong>
                                  </h5>
                                </td>
                                <td>
                                  <p>
                                    $&nbsp;{{ reservationDetails.dueAmount
                                    }}<br />
                                  </p>
                                  <p>
                                    $&nbsp;{{ reservationDetails.paidAmount
                                    }}<br />
                                  </p>
                                  <h5 class="text-success">
                                    <strong
                                      >$&nbsp;{{
                                        reservationDetails.totalAmount
                                      }}</strong
                                    >
                                  </h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="custom-actions-btns mb-5">
              <!-- <a href="#" class="btn btn-primary">
                            <i class="icon-download"></i> Download
                          </a> -->
              <a
                href="javascript:void(0)"
                class="btn btn-primary"
                @click="generatePDF"
              >
                <i class="fa-solid fa-print me-0"></i>&nbsp;<strong
                  >Print</strong
                >
              </a>
            </div>
          </div>
        </div>
        <!-- filter model -->
        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          aria-labelledby="filterModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="filterClose"
                ></button>
              </div>
              <div class="modal-body">
                <form class="filter_form" @submit.prevent="validateFilter()">
                  <div class="row mb-3">
                    <div class="col-12 col-sm-12">
                      <label class="form-label">Created At:</label>
                      <input
                        v-model="filter.createdAt"
                        type="date"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="text-center">
                      <button
                        class="
                          btn btn-sm btn-warning
                          rounded-pill
                          ps-3
                          pe-3
                          m-1
                        "
                        data-bs-dismiss="modal"
                        @click="clearFilter"
                      >
                        Clear
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="invoice-body">
          <!-- Row start -->
          <div class="row gutters">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h6 class="mb-0 text-uppercase">Service Request Log</h6>
              <hr />
              <div class="card">
                <div class="row gutters" style="padding: 15px">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="custom-actions-btns mb-2">
                      <!-- <a href="#" class="btn btn-primary">
                            <i class="icon-download"></i> Download
                          </a> -->
                      <button
                        type="button"
                        class="btn btn-warning text-dark"
                        style="float: right"
                        data-bs-toggle="modal"
                        data-bs-target="#filterModal"
                      >
                        <i class="bx bx-filter text-dark float-left"></i>Filter
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      id="example"
                      class="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Action By</th>
                          <th scope="col">Date</th>
                          <th scope="col">Route</th>
                          <th scope="col">Request Status</th>
                          <th scope="col">Payment Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in reserveLogList"
                          :key="index"
                        >
                          <th scope="row">{{ index + 1 }}</th>
                          <td>{{ data.createdBy }}</td>
                          <td>
                            {{ this.$filters.formatDateTime(data.createdAt) }}
                          </td>
                          <td>{{ data.routeName }}</td>
                          <td>{{ data.reservationStatus }}</td>
                          <td>
                            {{ data.paymentStatus }}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Action By</th>
                          <th scope="col">Date</th>
                          <th scope="col">Route</th>
                          <th scope="col">Request Status</th>
                          <th scope="col">Payment Status</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Row end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/reserve-details.js"></script>

<style scoped>
@import "../css/reserve-details.css";
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
</style>
