<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="row">
        <div class="col-6">
          <h6 class="mb-0 text-uppercase">
            Service Request List {{ confirmedService }}
          </h6>
        </div>
        <div class="col-6 float-right">
          <a
            role="button"
            class="btn btn-warning btn-sm m-1 mb-md-0"
            style="float: right"
            @click="exportData()"
          >
            <i class="fa-solid fa-file-export"></i>
            Export
          </a>
          <a
            role="button"
            data-bs-toggle="modal"
            data-bs-target="#filterModal"
            id="filterTable"
            class="btn btn-success btn-sm m-1 mb-md-0"
            style="float: right"
          >
            <i class="bx bx-filter text-white float-left"></i>Filter
          </a>
          <button
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            class="btn btn-primary btn-sm m-1"
            style="float: right"
          >
            <i class="fa-solid fa-list-check"></i>
            Bulk Payment Update
          </button>
        </div>
      </div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Request List
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Bulk Payment Status Update
          </button>
        </li>
      </ul>
      <hr />
      <!-- modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Bulk Payment Status Update
              </h5>
              <button
                id="bulkBtn"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="bulkUpdate()">
              <div class="modal-body">
                <div class="mb-3">
                  <label for="recipient-name" class="col-form-label"
                    >Select Requests:</label
                  >
                  <Multiselect
                    v-model="selectedBulkService"
                    mode="tags"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="true"
                    :options="bulkServiceList"
                  />
                </div>
                <div class="mb-3">
                  <label for="message-text" class="col-form-label"
                    >Select Payment Status:</label
                  >
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="bulkPaymentStatus"
                  >
                    <option style="display: none" selected value="">
                      Select Payment status
                    </option>
                    <option value="Pending">PENDING</option>
                    <option value="Paid">PAID</option>
                  </select>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- modal -->

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="example" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Service Request Id</th>
                      <th>Customer Name</th>
                      <th>Route</th>
                      <th>Request Date</th>
                      <th>Payment Status</th>
                      <th>Service Request Status</th>
                      <th>Service Type</th>
                      <th>Start Time</th>
                      <th>Total Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <!-- <td>{{bulkStatusUpdate}}</td> -->
                      <td>{{ data.reserveId }}</td>
                      <td>{{ data.userName }}</td>
                      <td>{{ data.routeName }}</td>
                      <td>{{ this.$filters.formatDateTime(data.serviceRequestDate) }}</td>
                      <td>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="data.paymentStatus"
                          :onchange="
                            () => {
                              updatePaymentStatus(data, data.paymentStatus);
                            }
                          "
                        >
                          <option style="display: none" selected value="">
                            {{ data.paymentStatus }}
                          </option>
                          <option value="Pending">PENDING</option>
                          <option value="Paid">PAID</option>
                        </select>
                      </td>
                      <td>{{ data.reservationStatus }} &nbsp; ({{ dateTimeFormat(data.updatedAt) }})</td>
                      <td>{{ data.serviceType ? data.serviceType.charAt(0).toUpperCase() + data.serviceType.slice(1) : '' }}</td>
                      <td>{{ data.serviceStartTime }}</td>
                      <td>{{ data.totalAmount }}</td>
                      <td>
                        <div class="d-flex order-actions">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-sm m-1"
                            v-on:click="
                              $router.push('/reserve-details/' + data.id)
                            "
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                          >
                            <i class="fa-solid fa-eye me-0"></i>
                          </button>

                          <button
                            type="button"
                            class="btn btn-outline-danger btn-sm m-1"
                            v-on:click="deleteReservation(data.id)"
                          >
                            <i class="fa-solid fa-trash-can me-0"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Sl</th>
                      <th>Service Request Id</th>
                      <th>Customer Name</th>
                      <th>Route</th>
                      <th>Request Date</th>
                      <th>Payment Status</th>
                      <th>Service Request Status</th>
                      <th>Service Type</th>
                      <th>Start Time</th>
                      <th>Total Amount</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div class="row">
            <div class="col-3 mb-3">
              <label
                for="basic-url"
                class="form-label"
                style="font-size: 17px; weight: 500"
                >Select Payment Status</label
              >
              <select
                class="form-select"
                aria-label="Default select example"
                v-model="bulkPaymentStatusOncheck"
              >
                <option style="display: none" selected value="">
                  Select payment status
                </option>
                <option value="Pending">PENDING</option>
                <option value="Paid">PAID</option>
              </select>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="selectAll"
                          @change="
                            () => {
                              handleSelectAll();
                            }
                          "
                          id="flexCheckDefault"
                        />
                      </th>
                      <th>Sl</th>
                      <th>Service Request Id</th>
                      <th>Customer Name</th>
                      <th>Route</th>
                      <th>Request Date</th>
                      <th>Payment Status</th>
                      <th>Service Request Status</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataList" :key="index">
                      <td>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="bulkAssignChecklist[index]"
                          @change="
                            () => {
                              bulkHandle(
                                index,
                                bulkAssignChecklist[index],
                                data.id
                              );
                            }
                          "
                          id="flexCheckDefault"
                        />
                      </td>
                      <td>{{ index + 1 }}</td>
                      <!-- <td>{{bulkStatusUpdate}}</td> -->
                      <td>{{ data.reserveId }}</td>
                      <td>{{ data.userName }}</td>
                      <td>{{ data.routeName }}</td>
                      <td>{{ data.serviceRequestDate }}</td>
                      <td>{{ data.paymentStatus }}</td>
                      <td>{{ data.reservationStatus }}</td>
                      <td>{{ data.totalAmount }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th>Sl</th>
                      <th>Service Request Id</th>
                      <th>Customer Name</th>
                      <th>Route</th>
                      <th>Request Date</th>
                      <th>Payment Status</th>
                      <th>Service Request Status</th>
                      <th>Total Amount</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <button
            class="btn btn-success mb-5"
            style="float: right"
            @click="bulkUpdate()"
          >
            Confirm
          </button>
        </div>
      </div>

      <div
        class="modal fade"
        id="filterModal"
        tabindex="-1"
        aria-labelledby="filterModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="filterModalLabel">Filter</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="filterClose"
              ></button>
            </div>
            <div class="modal-body">
              <form class="filter_form" @submit.prevent="validateFilter">
                <div class="row mb-3">
                  <div class="col-6 col-sm-6 mb-1">
                    <label class="form-label">Customer Name:</label>
                    <Multiselect
                      v-model="filter.customerName"
                      id="inputVendor"
                      :options="customerList"
                      :searchable="true"
                    />
                  </div>
                  <div class="col-6 col-sm-6 mb-1">
                    <label class="form-label">Routes:</label>
                    <Multiselect
                      v-model="filter.routeName"
                      id="inputVendor"
                      :options="routeList"
                      :searchable="true"
                    />
                  </div>
                  <div class="col-6 col-sm-6 mb-1">
                    <label class="form-label">Service Request Id:</label>
                    <Multiselect
                      v-model="filter.serviceRequestId"
                      id="inputVendor"
                      :options="serviceRequesIdtList"
                      :searchable="true"
                    />
                  </div>
                  <div class="col-6 col-sm-6 mb-1">
                    <label class="form-label">Payment Status:</label>
                    <select v-model="filter.paymentStatus" class="form-select">
                      <option value="">All</option>
                      <option value="Pending">Pending</option>
                      <option value="Paid">Paid</option>
                    </select>
                  </div>
                  <div class="col-6 col-sm-6 mb-1">
                    <label class="form-label">Request Status:</label>
                    <select v-model="filter.requestStatus" class="form-select">
                      <option value="">All</option>
                      <option value="Pending">Pending</option>
                      <option value="Processing">Processing</option>
                      <option value="Working">Working</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>

                  <div class="col-6 col-sm-6 mb-1">
                    <label class="form-label">Service Request Date:</label>
                    <input
                      v-model="filter.serviceRequestDate"
                      type="date"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Start Date:</label>
                    <input
                      v-model="filter.startDate"
                      type="date"
                      class="form-control"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">End Date:</label>
                    <input
                      v-model="filter.endDate"
                      type="date"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <div class="text-center">
                    <button
                      type="submit"
                      @click="clearFilter()"
                      class="btn btn-sm btn-warning rounded-pill ps-3 pe-3 m-1"
                      data-bs-dismiss="modal"
                    >
                      Clear
                    </button>
                    <button
                      type="submit"
                      class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/reservation-list.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>
