<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Route Report
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <!-- <button
                type="button"
                class="btn btn-warning text-white"
                data-bs-toggle="modal"
                data-bs-target="#filterModal"
              >
                <i class="bx bx-filter text-white float-left"></i>Filter
              </button> -->
            <button
              type="button"
              class="btn btn-secondary ms-2"
              @click="exportRoutes"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button>
          </div>
          <div
              class="modal fade"
              id="filterModal"
              tabindex="-1"
              aria-labelledby="filterModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="filterClose"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <form class="filter_form" @submit.prevent="validateFilter">
                      <div class="row mb-3">
                        <div class="col-6 col-sm-6">
                          <label class="form-label">Working Date:</label>
                          <input
                            v-model="filter.workingDate"
                            type="date"
                            class="form-control"
                          />
                        </div>
                        <div class="col-6 col-sm-6">
                          <label class="form-label">Email:</label>
                          <input
                            v-model="filter.email"
                            type="email"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="mb-3">
                        <div class="text-center">
                          <button
                            type="submit"
                            @click="clearFilter()"
                            class="
                              btn btn-sm btn-warning
                              rounded-pill
                              ps-3
                              pe-3
                              m-1
                            "
                            data-bs-dismiss="modal"
                          >
                            Clear
                          </button>
                          <button
                            type="submit"
                            class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Route Report List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Route Name</th>
                  <th>Total Service Request</th>
                  <th>Total Supervisor Assigned</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1}}</td>
                  <td>{{data.routeName}}</td>
                  <td>{{data.totalService}}</td>
                  <td>{{data.totalSupervisor}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Route Name</th>
                  <th>Total Service Request</th>
                  <th>Total Supervisor Assigned</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/route-report.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>