import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            name: localStorage.getItem('fullName'),
            email: localStorage.getItem('email'),
            role: localStorage.getItem('role')
        }
    },
    created() {
        document.title = "Landscape - Home";
    },
    methods: {
        logout: function() {
            localStorage.clear("token");
            localStorage.clear("id");
            localStorage.clear("firstName");
            localStorage.clear("lastName");
            localStorage.clear("email");
            this.$router.push("/login");
        },
        pageSideURL: function (event, url) {
            event.preventDefault();
            this.$router.push(url)
        }
    }
}