import ChangePassword from "../template/ChangePassword";
import { authHeader } from "../../../auth";
import { countries } from '../../../../config/country';

export default {
    name: "Profile",
    components: {
      ChangePassword
    },
    data(){
        return{
            userDetails: {},
            id: '',
            email: '',
            isEdit : false,
            countryList: countries,
            country: ""
        }
    },
    async mounted() {
        document.title = "Landscape - Profile";
        this.id = localStorage.getItem("id");
        this.email = localStorage.getItem("email");
        await this.getUserInfo();
    },
    methods: {
        getUserInfo: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.singleUser + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.userDetails = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function() {
            let data = {
                fullName: this.userDetails.fullName,
                phone: this.userDetails.phone
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.user.updateProfile,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    localStorage.setItem("fullName", this.userDetails.fullName);
                    localStorage.setItem("email", this.userDetails.email);
                    localStorage.setItem("phone", this.userDetails.phone);
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated admin info!"
                    });
                    this.$router.push("/dashboard");
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
            this.isEdit = false;
        }
    }
}
