import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Customer",
    data() {
        return {
            dataList: [],
            sheets: [],
            filter: {
                workingDate: '',
                email: ''
            }
        }
    },
    async created() {
        document.title = "Land Scape - Crew";
        await this.getData();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.report.attendanceReportURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Date": el.date,
                    "Start Time": el.startTime,
                    "End Time": el.endTime,
                    "Total Hour": el.totalHour,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'user_attendance_report(' + date + ').xlsx')
            this.sheets = [];
        },
        validateFilter: async function () {
            try {
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getCrewList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                joiningDate: ''
            }
            await this.getSupervisorList();
        },
    }
}