<template>
    <div class="page-wrapper">
      <div class="page-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Customer Billing Information List
              </li>
            </ol>
          </nav>
          <div class="ms-auto">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary ms-2"
                @click="$router.push('/add-billing-information')"
              >
                <i class="bx bxs-plus-square"></i>Add Billing Information
              </button>
              <button
                type="button"
                class="btn btn-secondary ms-2"
                @click="exportUsers"
              >
                <i class="bx bxs-plus-square"></i>Export
              </button>
            </div>
            <div
              class="modal fade"
              id="filterModal"
              tabindex="-1"
              aria-labelledby="filterModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="filterClose"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <form class="filter_form" @submit.prevent="validateFilter">
                      <div class="row mb-3">
                        <div class="col-6 col-sm-6 mb-1">
                          <label class="form-label">Email:</label>
                          <Multiselect
                            v-model="filter.email"
                            id="inputVendor"
                            :options="emailList"
                            :searchable="true"
                          />
                        </div>
                        <div class="col-6 col-sm-6 mb-1">
                          <label class="form-label">Phone:</label>
                          <Multiselect
                            v-model="filter.phone"
                            id="inputVendor"
                            :options="phoneList"
                            :searchable="true"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-sm-12">
                          <label class="form-label">Joining Date:</label>
                          <input
                            v-model="filter.joiningDate"
                            type="date"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="mb-3">
                        <div class="text-center">
                          <button
                            type="submit"
                            @click="clearFilter()"
                            class="
                              btn btn-sm btn-warning
                              rounded-pill
                              ps-3
                              pe-3
                              m-1
                            "
                            data-bs-dismiss="modal"
                          >
                            Clear
                          </button>
                          <button
                            type="submit"
                            class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- filter modal -->
          </div>
        </div>
        <h6 class="mb-0 text-uppercase">Customer Billing Address List</h6>
        <hr />
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table id="example" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Sl</th>
                    <th>Customer Name</th>
                    <th>Billing Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>City</th>
                    <th>Country</th>
                    <th>Address</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataList" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.userName }}</td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.email }}</td>
                    <td>{{ data.mobile }}</td>
                    <td>{{ data.city }}</td>
                    <td>{{ data.country }}</td>
                    <td>{{ data.addressOne }}</td>
                    <td>{{ $filters.formatDateTime(data.createdAt) }}</td>
                    <td>
                      <div class="d-flex order-actions">
                        <button
                          type="button"
                          class="btn btn-outline-success btn-sm m-1"
                          v-on:click="$router.push('/update-billing-information/' + data.id)"
                        >
                          <i class="fa-solid fa-pen-to-square me-0"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-danger btn-sm m-1"
                          v-on:click="deleteData(data.id)"
                        >
                          <i class="fa-solid fa-trash-can me-0"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Sl</th>
                    <th>Customer Name</th>
                    <th>Billing Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>City</th>
                    <th>Country</th>
                    <th>Address</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script src='../js/billing-information.js'></script>
  
  <style scoped>
  @import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
  
  .edit-button {
    background-color: #17a00e;
  }
  
  .delete-button {
    background-color: #f41127;
  }
  </style>