import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "Service",
    data() {
        return {
            serviceName: "",
            servicePrice: "",
            serviceStatus: "",
            serviceDetails: "",
            dataList: [],
            sheets: []
        }
    },
    async created() {
        document.title = "Land Scape - Service";
        await this.getDataList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getDataList: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        deleteService: async function(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.service.requestURL + '/' + id,
                        headers: authHeader()
                    };
                    await this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                text: "Data deleted successfully."
                            });
                        }
                        this.getDataList();
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                }
            })
        },
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Name": el.serviceName,
                    "Price": el.servicePrice,
                    "Status": el.status,
                    "Features": el.serviceFeature,
                    "Details": el.serviceDetails, 
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'service_report(' + date + ').xlsx');
            this.sheets = [];
        }
    }
}
