<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Route List
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" @click="$router.push('/add-route')">
                            <i class="bx bxs-plus-square"></i>Add Route
                        </button>
                        <button type="button" class="btn btn-secondary ms-2" @click="exportRoutes">
                            <i class="bx bxs-plus-square"></i>Export
                        </button>
                    </div>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Route List</h6>
            <hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Route Name</th>
                                    <th>Start Point</th>
                                    <th>End Point</th>
                                    <th>Start Latitude</th>
                                    <th>Start Longitude</th>
                                    <th>End Latitude</th>
                                    <th>End Longitude</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1}}</td>
                                    <td>{{ data.routeName }}</td>
                                    <td>{{ data.startPoint }}</td>
                                    <td>{{ data.endPoint }}</td>
                                    <td>{{ data.startLatitude }}</td>
                                    <td>{{ data.startLongitude }}</td>
                                    <td>{{ data.endLatitude }}</td>
                                    <td>{{ data.endLongitude }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <button type="button" v-on:click="$router.push({ name: 'UpdateRoute', params: { id: data.id }})" class="btn btn-outline-success btn-sm m-1">
                                                <i class="fa-solid fa-pen-to-square me-0"></i>
                                            </button>
                                            <button type="button" v-on:click="deleteRoute(data.id)" class="btn btn-outline-danger btn-sm m-1">
                                                <i class="fa-solid fa-trash-can me-0"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Route Name</th>
                                    <th>Start Point</th>
                                    <th>End Point</th>
                                    <th>Start Latitude</th>
                                    <th>Start Longitude</th>
                                    <th>End Latitude</th>
                                    <th>End Longitude</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/route.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>