<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/dashboard"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Assigned Service Request Report
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <!-- <a
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
              id="filterTable"
              class="btn btn-success m-1 mb-md-0"
              style="float: right"
            >
              <i class="bx bx-filter text-white float-left"></i>Filter
            </a> -->
            <button
              type="button"
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <i class="bx bx-filter text-white float-left"></i>Filter
            </button>
            <button
              type="button"
              class="btn btn-warning ms-2"
              @click="exportData"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button>
          </div>
        </div>
        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          aria-labelledby="filterModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="filterModalLabel">Filter</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="filterClose"
                ></button>
              </div>
              <div class="modal-body">
                <form class="filter_form" @submit.prevent="validateFilter">
                  <div class="row mb-3">
                    <div class="col-12 col-sm-12 mb-3">
                      <label class="form-label">Service Request Status</label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="filter.status"
                      >
                        <option style="display: none" selected value="">
                          Select request status
                        </option>
                        <option value="">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Processing">Processing</option>
                        <option value="Working">Working</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                    <div class="col-12 col-sm-12">
                      <label class="form-label">Created Date:</label>
                      <input
                        v-model="filter.date"
                        type="date"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="text-center">
                      <button
                        type="submit"
                        @click="clearFilter()"
                        class="
                          btn btn-sm btn-warning
                          rounded-pill
                          ps-3
                          pe-3
                          m-1
                        "
                        data-bs-dismiss="modal"
                      >
                        Clear
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-success rounded-pill ps-3 pe-3"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h6 class="mb-0 text-uppercase">
            Assigned Service Request Report List
          </h6>
        </div>
        <div class="col-6 float-right"></div>
      </div>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Service Request Id</th>
                  <th>Assignee Name</th>
                  <th>Route Name</th>
                  <th>Service Request Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.serviceRequestId }}</td>
                  <td>{{ data.assigneeName }}</td>
                  <td>{{ data.routeName }}</td>
                  <td>{{ data.status }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Service Request Id</th>
                  <th>Customer Name</th>
                  <th>Route Name</th>
                  <th>Service Request Status</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/assigned-request-report.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>