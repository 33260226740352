import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "Route",
    data() {
        return {
            dataList: [],
            sheets: []
        }
    },
    async created() {
        document.title = "Land Scape - Routes";
        this.getDataList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getDataList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.route.routeAPI,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        deleteRoute: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.route.singleRoute + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList();
                    } catch (error) {
                        console.log(error);
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong. Please try agian later."
                        });
                    }
                }
            })
        },
        exportRoutes: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Route Name": el.routeName,
                    "Strat Point": el.startPoint,
                    "End Point": el.endPoint,
                    "Start Latitude": el.startLatitude,
                    "Start Longitude": el.startLongitude,
                    "End Latitude": el.endLatitude,
                    "End Longitude": el.endLongitude,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'route_report(' + date + ').xlsx');
            this.sheets = [];
        }
    }
}