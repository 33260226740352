<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Policy Settings</li>
                    </ol>
                </nav>
                <div class="ms-auto"></div>
            </div>
            <h6 class="mb-0 text-uppercase">Terms and Conditions</h6>
            <hr/>
            <div>
                <form @submit.prevent="update()">
                    <div class="card">
                        <div class="card-header"><strong>Terms and Conditions Settings</strong></div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group m-3">
                                        <label>Title:</label>
                                        <input type="text" v-model="title" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group m-3">
                                        <label>Sub Title:</label>
                                        <input type="text" v-model="subTitle" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group m-3">
                                        <label>Button Text:</label>
                                        <input type="text" v-model="buttonText" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group m-3">
                                        <label>Button Link:</label>
                                        <input type="text" v-model="buttonLink" class="form-control"/>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group m-3">
                                        <label class="form-label">Header Icon:</label>
                                        <input class="form-control" type="file" @change="onIconChange" ref="iconFile">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group m-3">
                                        <img v-if="previewIcon" :src="previewIcon" height="100" width="150"/>
                                        <img v-else-if="icon" :src="$imageURL + 'policy/' + icon" height="100" width="150"/>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group m-3">
                                        <label class="form-label">Background Image:</label>
                                        <input class="form-control" type="file" @change="onImageChange" ref="imageFile">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group m-3">
                                        <img v-if="previewImage" :src="previewImage" height="100" width="150"/>
                                        <img v-else-if="backgroundImage" :src="$imageURL + 'policy/' + backgroundImage" height="100" width="150"/>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group m-3">
                                        <label>Description:</label>
                                        <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script src='../js/terms-and-conditions.js'></script>
