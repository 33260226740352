import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "ReserveList",
    components: {
        Loading
    },
    data() {
        return {
            dataList: [],
            filter: {
                paymentStatus: "",
                startDate: "",
                endDate: "",
                status: ""
            },
            sheets: [],
            userList: [],
            assignee: [],
            route: '',
            userAssign: '',
            request: [],
            routeList: [],
            requestList: [],
            bulkAssign: [],
        }
    },
    async mounted() {
        await this.getReserveList();
        await this.getUserList();
        this.loadJS();
    },
    methods: {
        loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        getReserveList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.assignService.requestURL + '?status=Processing',
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                    this.assignee = response.data.data.map(el => {
                        return {value:{id: el.assigneeId, fullName: el.assigneeName}, label: el.assigneeName}
                    })
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        getUserList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.crew.supervisorURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.userList = response.data.data.map((el) => {
                        return { value: {id: el.id, fullName: el.fullName}, label: el.fullName };
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        getRouteList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.assignService.routeList,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.routeList = response.data.data.map((el) => {
                        return { value: el, label: el.routeName };
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        getServiceRequest: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.assignService.routeList + '/' + this.route.value.routeId,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.requestList = response.data.data.map((el) => {
                        return { value: el, label: el.reserveId };
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        assignUser: async function(user, row) {
            let data = {
                serviceRequestId: row.serviceRequestId,
                assigneeId: user.value.id,
                assigneeName: user.value.fullName,
                routeId: row.routeId,
                routeName: row.routeName,
                checkList: row.checkList,
                requestId: row.id,
                status: 'Processing'
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.assignService.updateURL + row.id,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.isLoading = false;
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.getReserveList();
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        bulkAssignUser: async function() {
            console.log("Request: ", this.requestList);
            if(this.requestList.length > 0){
                for(let i = 0; i < this.requestList.length; i++){
                    let data = {
                        serviceRequestId: this.requestList[i].value.reserveId,
                        assigneeId: this.userAssign.value.id,
                        assigneeName: this.userAssign.value.fullName,
                        routeId: this.requestList[i].value.routeId,
                        routeName: this.requestList[i].value.routeName,
                        requestId: this.requestList[i].value.id,
                        status: 'Processing'
                    };
                    this.bulkAssign.push(data);
                }
                console.log(this.bulkAssign);
            // let config = {
            //     method: "POST",
            //     url: this.$serverURL + this.$api.assignService.requestURL,
            //     data: data,
            //     headers: authHeader()
            // };
            // await this.$axios(config).then((response) => {
            //     if (response.status == 201) {
            //         this.isLoading = false;
            //         this.$swal.fire({
            //             toast: true,
            //             position: 'top-end',
            //             text: response.data.message,
            //             icon: "success",
            //             showConfirmButton: false,
            //             timer: 3000,
            //             animation: false,
            //             timerProgressBar: true,
            //             didOpen: (toast) => {
            //                 toast.addEventListener('mouseenter', this.$swal.stopTimer)
            //                 toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            //             }
            //         });
            //     }
            //     this.getReserveList();
            // }).catch(error => {
            //     console.log(error);
            //     this.$swal.fire({
            //         icon: "error",
            //         text: error.response.data.message
            //     });
            // });
            }
            
        }
        // deleteEntity: async function (id) {
        //     this.isLoading = true;
        //     this.$swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonColor: "red",
        //         cancelButtonColor: "green",
        //         confirmButtonText: "Delete"
        //     }).then(async (result) => {
        //         if (result.isConfirmed) {
        //             let config = {
        //                 method: "DELETE",
        //                 url: this.$gatewayURL + this.$api.reserve.requestURL + "/" + id,
        //                 headers: authHeader()
        //             }
        //             await this.$axios(config).then((response) => {
        //                 if (response.data.deleted == true) {
        //                     this.getReserveList();
        //                     this.$swal.fire({
        //                         icon: "success",
        //                         text: "Reserve data deleted successfully."
        //                     });
        //                 }
        //                 this.isLoading = false;
        //             }).catch(error => {
        //                 this.isLoading = false;
        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Something went wrong. Please try again!"
        //                 });
        //             });
        //         }
        //     })
        // },
        // validateFilter: async function () {
        //     try {
        //         if (this.filter.startDate) {
        //             if (!this.filter.endDate) {
        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Please select end date!"
        //                 });
        //                 return;
        //             }
        //         }
        //         if (this.filter.endDate) {
        //             if (!this.filter.startDate) {
        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Please select start date!"
        //                 });
        //                 return;
        //             }
        //         }
        //         window.$('#example').DataTable().destroy();
        //         await this.getReserveList();
        //         window.$('#example').DataTable();
        //     } catch (error) {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong, please try agian later." + " " + error
        //         });
        //     }
        // },
        // clearFilter: async function () {
        //     this.filter = {
        //         paymentStatus: "",
        //         status: "",
        //         startDate: "",
        //         endDate: ""
        //     };
        //     await this.getReserveList();
        // },
        // updateStatus: async function (param) {
        //     try {
        //         let token = localStorage.getItem('token');
        //         let data = {
        //             status: param.status
        //         }
        //         let config = {
        //             method: "PATCH",
        //             url: this.$gatewayURL + this.$api.reserve.requestURL + '/' + param.id,
        //             data: data,
        //             headers: {
        //                 "Authorization": "Bearer " + token
        //             }
        //         };
        //         await this.$axios(config).then((response) => {
        //             if (response.data.statusCode == 200) {
        //                 this.getReserveList();
        //                 this.$swal.fire({
        //                     toast: true,
        //                     position: 'top-end',
        //                     text: "Status updated successfully.",
        //                     icon: "success",
        //                     showConfirmButton: false,
        //                     timer: 3000,
        //                     animation: false,
        //                     timerProgressBar: true,
        //                     didOpen: (toast) => {
        //                         toast.addEventListener('mouseenter', this.$swal.stopTimer)
        //                         toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        //                     }
        //                 });
        //             }
        //         }).catch(error => {
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: error.response.data.message
        //             });
        //         });
        //     } catch (error) {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong, please try agian later."
        //         });
        //     }
        // },
        // updatePaymentStatus: async function (param) {
        //     try {
        //         let token = localStorage.getItem('token');
        //         let data = {
        //             paymentStatus: param.paymentStatus
        //         }
        //         let config = {
        //             method: "PATCH",
        //             url: this.$gatewayURL + this.$api.reserve.requestURL + '/' + param.id,
        //             data: data,
        //             headers: {
        //                 "Authorization": "Bearer " + token
        //             }
        //         };
        //         await this.$axios(config).then((response) => {
        //             if (response.data.statusCode == 200) {
        //                 this.getReserveList();
        //                 this.$swal.fire({
        //                     toast: true,
        //                     position: 'top-end',
        //                     text: "Payment status updated successfully.",
        //                     icon: "success",
        //                     showConfirmButton: false,
        //                     timer: 3000,
        //                     animation: false,
        //                     timerProgressBar: true,
        //                     didOpen: (toast) => {
        //                         toast.addEventListener('mouseenter', this.$swal.stopTimer)
        //                         toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        //                     }
        //                 });
        //             }
        //         }).catch(error => {
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: error.response.data.message
        //             });
        //         });
        //     } catch (error) {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong, please try agian later."
        //         });
        //     }
        // },
        // exportData: function () {
        //     this.dataList.map((el) => {
        //         this.sheets.push({
        //             'Id': el.reserveId,
        //             'Customer Name': el.customerName,
        //             "Contact No": el.contactNo,
        //             "Shipping Address": el.shippingAddress,
        //             "Equipment Id": el.equipmentId,
        //             "Equipment Name": el.equipmentName,
        //             "Equipment Location": el.location,
        //             "Start Date":el.startDate,
        //             "End Date":el.endDate,
        //             "Rent Price": el.rentPrice,
        //             "Two Way Delivery Price": el.twoWayDeliveryPrice,
        //             "Total Rent Value": el.totalRentValue,
        //             "Created At": el.createdAt,
        //             "Status": el.status

        //         });
        //     })
        //     const data = XLSX.utils.json_to_sheet(this.sheets)
        //     const wb = XLSX.utils.book_new()
        //     const date = new Date().toLocaleString();
        //     XLSX.utils.book_append_sheet(wb, data, 'data')
        //     XLSX.writeFile(wb, 'reserve_report(' + date + ').xlsx')
        // },
    }
}
