export default function crewGuard(to, from, next) {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    if(token) {
        if(role == 4){
            next();
        }else{
            next('/unauthorized');
        }
    } 
    else {
        next('/login');
    }
}