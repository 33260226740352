import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "ReserveList",
    components: {
        Loading
    },
    data() {
        return {
            dataList: [],
            filter: {
                paymentStatus: "",
                startDate: "",
                endDate: "",
                status: ""
            },
            sheets: [],
            userList: [],
            assignee: [],
            selectedUser: [],
            selectedRow: {},
            idx: 0
        }
    },
    async mounted() {
        await this.getReserveList();
        await this.getUserList();
        this.loadJS();
    },
    methods: {
        loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        getReserveList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.assignService.assignedList,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        getUserList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.crewList,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.userList = response.data.data.map((el) => {
                        return { value: el, label: el.fullName };
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error)
            });
        },
        assignData: function(data){
            this.selectedRow = data;
        },
        selectUser: function(user){
            this.selectedUser = [];
            for(let item in user) {
                let data = {
                    serviceRequestId: this.selectedRow.serviceRequestId,
                    assigneeId: user[item].value.id,
                    assigneeName: user[item].value.fullName,
                    serviceId: this.selectedRow.serviceId,
                    serviceName: this.selectedRow.serviceName,
                    checkList: this.selectedRow.checkList,
                    routeId: this.selectedRow.routeId,
                    routeName: this.selectedRow.routeName,
                    requestDetailsId: this.selectedRow.requestDetailsId,
                    status: 'In-Progress'
                }
                this.selectedUser.push(data);
            }
            console.log("Selected: ", this.selectedUser);
        },
        removeUser: function(user) {
            this.selectedUser = [];
            for(let item in user) {
                let data = {
                    serviceRequestId: this.selectedRow.serviceRequestId,
                    assigneeId: user[item].value.id,
                    assigneeName: user[item].value.fullName,
                    serviceId: this.selectedRow.serviceId,
                    serviceName: this.selectedRow.serviceName,
                    checkList: this.selectedRow.checkList,
                    routeId: this.selectedRow.routeId,
                    routeName: this.selectedRow.routeName,
                    requestDetailsId: this.selectedRow.requestDetailsId,
                    status: 'In-Progress'
                }
                this.selectedUser.push(data);
            }
            console.log("Selected: ", this.selectedUser);
        },
        updateService: async function(id) {
            let data = {
                status: 'Working'
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.assignService.requestURL + '/'+ id,
                data: data,
                headers: authHeader()
            };
            this.$swal.showLoading()
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.status == 200) {
                    this.isLoading = false;
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.getReserveList();
            }).catch(error => {
                this.$swal.hideLoading();
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}
