import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'
export default {
    name: "Customer",
    data() {
        return {
            fullName: '',
            email: '',
            phone: '',
            userType: 'customer',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            singleCustomer: {},
            dataList: [],
            sheets: [],
            createdDate: '',
            emailList: [],
            phoneList: [],
            filter: {
                email: "",
                phone: "",
                joiningDate: "",
            }
        }
    },
    async created() {
        document.title = "Land Scape - Customer";
        await this.getCustomerList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getCustomerList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.billingURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        deleteData: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.user.billingURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteDataToBackend(config);
                        await this.getCustomerList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        exportUsers: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Customer Name": el.userName,
                    "Billing Name": el.name,
                    "Phone": el.mobile,
                    "Email": el.email,
                    "Address": el.addressOne,
                    "Country": el.country,
                    "City": el.city,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'customer_billing_report(' + date + ').xlsx');
            this.sheets = [];
        },
        // filterCustomer: async function() {
        //     document.getElementById("closeFilter").click();
        //     if (this.createdDate){
        //         window.$('#example').DataTable().destroy();
        //     let config = {
        //         method: "GET",
        //         url: this.$serverURL + this.$api.user.getUsersListURL + "?userType=" + this.userType + "&createdDate=" + this.createdDate,
        //         headers: authHeader()
        //     };
        //     await this.$axios(config).then((response) => {
        //         if(response.status == 200) {
        //             this.dataList = response.data.data;
        //             this.createdDate = '';
        //         }
        //         this.isLoading = false;
        //     }).catch(error => {
        //         console.log(error);
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong. Please try again!"
        //         });
        //     });
        //     window.$('#example').DataTable();
        //     }
        //     else {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please provide joining date!"
        //         });
        //     }
        // }
        validateFilter: async function () {
            try {
                if (this.filter.email == null) {
                    this.filter.email = '';
                }
                if (this.filter.phone == null) {
                    this.filter.phone = '';
                }
                document.getElementById('filterClose').click();
                window.$('#example').DataTable().destroy();
                await this.getCustomerList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + " " + error
                });
            }
        },
        clearFilter: async function () {
            this.filter = {
                email: "",
                phone: "",
                joiningDate: "",
            }
            await this.getCustomerList();
        },
    }
}