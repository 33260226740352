import { authHeader } from "../../../auth";

export default {
    name: "AddCustomer",
    data() {
        return {
            title: '',
            fullName: '',
            email: '',
            phone: '',
            userType: 'customer',
            password: '',
            confirmPassword: '',
            address: '',
            secondAddress: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
        }
    },
    async created() {
        document.title = "Land Scape - Add Customer";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        validate: function() {
            if (!this.title) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.fullName) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter first name!"
                });
                return false;
            }
            if (!this.email) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!"
                });
                return false;
            }
            if (!this.phone) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter phone number!"
                });
                return false;
            }
            if (!this.address) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter address!"
                });
                return false;
            }
            if (!this.password) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter password!"
                });
                return false;
            }
            if (!this.confirmPassword) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter confirm password!"
                });
                return false;
            }
            if (this.password && this.confirmPassword && this.password != this.confirmPassword) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Password didn't match!"
                });
                return false;
            }

            return true;
        },
        addCustomer: async function () {
            this.isLoading = true;
            if(this.validate()) {
                const data = {
                    title: this.title,
                    fullName: this.fullName,
                    email: this.email,
                    phone: this.phone,
                    address: this.address,
                    secondAddress: this.secondAddress,
                    password: this.password,
                    //userType: this.userType
                };
                const config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.customer.register,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                    }
                    this.title = '',
                    this.fullName = '';
                    this.email = '';
                    this.phone = '';
                    this.address = '',
                    this.secondAddress = '',
                    this.userType = 'customer';
                    this.password = '';
                    this.confirmPassword = '';
                    this.inputTypePassword = "password";
                    this.inputTypeConfirmPassword = "password";
                    this.$router.push("/customer-list");
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        changePasswordType: function () {
            if (this.inputTypePassword == "password") {
                this.inputTypePassword = "text";
                this.iconStatus = false;
            }
            else if (this.inputTypePassword == "text") {
                this.inputTypePassword = "password";
                this.iconStatus = true;
            }
        },
        changeConfirmPasswordType: function () {
            if (this.inputTypeConfirmPassword == "password") {
                this.inputTypeConfirmPassword = "text";
                this.iconFlag = false;
            }
            else if (this.inputTypeConfirmPassword == "text") {
                this.inputTypeConfirmPassword = "password";
                this.iconFlag = true;
            }
        },
        setAddress(locationData) {
            let strData = locationData.formatted_address;
            let nameSplit = strData.split(',');

            if (nameSplit[0].trim() != locationData.name) {
                strData = locationData.name + ', ' + strData;
            }
            this.address = strData;
        },
        setSecondAddress(locationData) {
            let strData = locationData.formatted_address;
            let nameSplit = strData.split(',');

            if (nameSplit[0].trim() != locationData.name) {
                strData = locationData.name + ', ' + strData;
            }
            this.secondAddress = strData;
        }
    }
}